.sk-course-structure {
  &__header {
    margin-bottom: 36px;
  }
  &__content {
    margin-bottom: 36px;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
  }
}
