
@import '.../../../../../utils/styles/variables';


.sk-course-creation {
  flex-flow: column wrap;
  height: 100%;
  &__header {
    justify-content: center;
    text-align: center;
    padding-top: 48px;
    margin-bottom: 13px;
    grid-column-gap: .4em;
  }
  &__inner {
    align-self: stretch;
    display: flex;
    background-size: 100% 100%;
    padding: 46px 0 73px;
    margin-bottom: 49px;
    @media screen and (max-width: $breakpoint-sm) {
      padding-top: 0px;
      padding-bottom: 40px;
    }
  }
  &__main {
    width: 100%;
    grid-column-gap: .4em;
  }
  &__card {
    padding: 60px 68px 72px;
    @media screen and (max-width: $breakpoint-sm) {
      padding: 30px 30px 46px;
      
    }
  }
}
