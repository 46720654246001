@import '../../../utils/styles/variables';

.sk-personal-info {
  display: flex;
  height: 100%;
  flex-direction: column;
  margin-bottom: 103px;
  &__inner {
    flex: 1;
    padding: 0 73px 67px 73px;
  }

  @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    > .sk-content-header {
      margin-bottom: 30px;
    }
  }
  
  @media screen and (max-width: $breakpoint-sm) {
    margin-bottom: 79px;
    &__inner {
      padding: 0 43px 50px;
    }
  }
}
