.sk-admin-plan-page-form {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  > div {
    max-width: calc(50% - 14px);
  }
  > div:nth-child(even) {
    margin-left: auto;
    // margin-right: 28px;
  }
}
