@import 'utils/styles/_variables.scss';

.sk-manage-admin-account-sidebar {
  padding: 18px 10px 39px 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  &_footer {
    display: flex;
    @media screen and (min-width: $breakpoint-md) {
      padding-bottom: 77px;
    }
  }
  &_btn {
    display: flex;
    align-items: center;
  }
}
.sk-sidebar-block {
  display: flex;
  flex-direction: column;
  &_header {
    display: flex;
    align-items: center;
    margin-bottom: 14px;
  }
  &_icon {
    margin-right: 10px;
  }
}
