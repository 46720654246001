.sk-lec-details-component {
  grid-template-rows: 1fr;
  &__inner {
    padding: 18px 18px 0 0px;
  }
  &__inner {
    &_withBorder {
      padding-right: 18px;
      // border-right: 2px solid var(--color-grey2);
    }
  }
  &__sidebar {
    padding: 18px 0px 43px 43px;
  }
  &__subTitle {
    color: var(--color-black);
    font-size: var(--text-fontSize-small);
    letter-spacing: 0.2px;
    font-weight: 700;
  }
}

.sk-lec-details-content {
  &__desc {
    margin-bottom: 18px;
  }
  &__materials {
  }
}

.sk-lec-materials__indent {
  margin-top: 12px;
  display: block;
}
