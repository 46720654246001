@import 'utils/styles/text.scss';

.sk-aboutCourse {
  padding-top: 34px;
  padding-bottom: 50px;
  border-bottom: 1px solid var(--purple-light);

  &-title {
    @extend %h2;
    margin: 0 0 11px;
    color: var(--dark-text);
  }

  &-button {
    margin-top: 20px;
    color: var(--color-primary-1);
    font-weight: bolder;
    font-size: var(--text-fontSize-body);
  }
  &__description {
    line-height: 1.5625;
    font-weight: 300;
  }
}
