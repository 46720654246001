@import '../../../utils/styles/variables';

.sk-authenticator {
  display: flex;
  justify-content: center;
  padding: 90px 0;
  &_container {
    max-width: 730px;
    box-shadow: var(--box-shadow-paper-xs);
  }
  &_inner {
    padding: 31px 95px 82px;
    @media screen and (max-width: $breakpoint-sm) {
     padding: 20px 43px 50px;
    }

    @media screen and (max-width: $breakpoint-xs) {
     padding: 20px 27px 50px;
    }
  }
  @media screen and (min-width: $breakpoint-sm) {
    align-items: center;
    height: 100%;
  }
}

.sk-auth-content {
  &_inner {
    margin-bottom: 30px;
  }

  &_footer {
    &__top {
      margin-bottom: 20px;
    }
  }

  &_terms {
    display: flex;
    margin-bottom: 27px;
    width: 100%;
    line-height: 24px;
  }
  &_text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &_link {
    text-decoration: none;
    position: relative;
    color: var(--color-primary-11);
    letter-spacing: 0.5px;
    &__weak {
      color: var(--color-grey1);
      &::after {
        border-bottom: 1px solid var(--color-grey1);
      }
    }
  }
}
