.sk-course-general-info-form {
  &__trigger {
    position: absolute;
    left: -27px;
    top: 0px;
  }
  &__row {
    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
   
  }
}
