@import "../../utils/styles/variables";
@import 'utils/styles/_variables.scss';
@import 'utils/styles/functions.scss';
@import 'utils/styles/color.scss';

.sk-page-header {
  align-items: center;
  height: 77px;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: $z-index-header;
  .landing-shadows {
    z-index: -1;
    width: 100%;
    position: absolute;
    top: 0px;
    height: 100px;
    background: linear-gradient(0deg, transparent 0%, var(--landing-header) 100%);
    background-image: -webkit-linear-gradient(top, var(--landing-header) 0%, #120D8700 100%);
  }
  &:not(.landing) {
    background-image: var(--purple-gradient);
  }
  #header-background {
    width: 100vw;
    height: 100%;
    position: absolute;
    z-index: -1;
    top: 0;
    right: 0;
    left: 0;
    transition: opacity .2s;
    opacity: 1;
  }
  &.landing.scroll &__inner {
    background: linear-gradient(336.67deg, var(--purple-gradient-from) -8.8%, var(--purple-gradient-to) 101.98%);
  }
  &.landing.unscroll &__inner {
    background: transparent
  }
  &__inner {
    height: 100%;
    align-items: center;
    &.mobile-header {
      @media screen and (max-width: 1024px) {
        grid-template-columns: 5px 120px 120px auto auto 110px;
        max-width: 100vw;
        & div {
          grid-column: auto;
        }
      }
    }
  }
  &_search-panel {
    margin-left: 28px;
    max-width: 406px;
    width: 100%;
    @media screen and (max-width: $breakpoint-md){
      margin-left: 0;
    }
    @media screen and (max-width: 880px){
      display: none;
    }
  }
  &_left-block{
    display: flex;
    align-items: center;
    @media screen and (max-width: $breakpoint-xs) {
      justify-content: space-between;
      width: 100%;
      padding-right: 10px;
    }
    @media screen and (max-width: 350px){
      // width: calc(100% - 5px);
      padding-right: 5px;
    }
  }
  &_logo {
    display: flex;
    align-items: center;
    width: 157px;
    height: 37px;
    margin-right: 10px;
    svg{
      .sk-logo-text{
        transform: translateY(-3px);
      }
    }
    @media screen and (max-width: $breakpoint-xs) {
      height: 30px;
    }
  }
  &_courses{
    width: auto;
    margin-left: calculateWidth(80);
    @media screen and (max-width: $breakpoint-md){
      margin: 0 10px;
    }
    @media screen and (max-width: $breakpoint-xs){
      margin: 0 5px;
    }
  }
  &_menu-panel {
    display: flex;
    align-self: stretch;
    align-items: center;
  }
}

.sk-menu-panel {
  &_action-block {
    z-index: 100;
    display: flex;
    align-self: stretch;
    align-items: center;
    justify-content: center;
    flex: 1;
    margin-right: 12px;
    &_item {
      display: flex;
      align-self: stretch;
      padding: 0 10px;
      @media screen and (min-width: $breakpoint-lg) {
        margin-right: 26px;
      }
      @media screen and (max-width: $breakpoint-xs){
        padding: 0 10px;
      }
      @media screen and (max-width: 350px){
        padding: 0 5px;
      }
    }
    @media screen and (min-width: $breakpoint-lg) {
      margin-right: 16px;
    }
    @media screen and (max-width: 480px) {
      margin-right: 0;
    }
  }
  &_toggle {
    display: flex;
    align-self: stretch;
    margin-left: 10px;
    @media screen and (max-width: 350px){
      margin-left: 5px;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    justify-content: space-around;
  }
}

.lg-visible {
  display: none;
  @media screen and (min-width: $breakpoint-lg) {
    display: flex;
  }
}

.lg-hide {
  display: flex;
  @media screen and (min-width: $breakpoint-lg) {
    display: none;
  }
}

.md-visible {
  display: none;
  @media screen and (min-width: 1025px) {
    display: flex;
  }
}

.md-hide {
  display: flex;
  @media screen and (min-width: 1025px) {
    display: none;
  }
}

.sm-visible {
  display: none;
  @media screen and (min-width: 1025px) {
    display: flex;
  }
}
