@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-topping-edit-actions-buttons {
  display: flex;
  width: 880px;
  justify-content: flex-end;
  padding: 49px 0 0;
  max-width: 880px;
  width: 100%;
  margin: 0 auto;
  > button {
    margin-right: 30px;
    &:last-child {
      margin-right: 0;
    }
    > .sk-round-icon__md {
      height: 43px;
      width: 43px;
    }
  }

  @media (max-width: $breakpoint-md) {
    width: 100%;

  }

  @media (max-width: $breakpoint-xs) {
    justify-content: center;
  }
}
