.sk-loader {
  &__wrapper {
    display: flex;
    height: 100%;
    &_center {
      justify-content: center;
      align-items: center;
    }
    &_fullScreen {
      min-height: 100vh;
    }
    &_overlay {
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: lighten(black, 90%);
      opacity: 0.4;
      transition: all 300ms ease-in;
      box-shadow: 0 0 25px 10px lighten(black, 90%);
      &_shrinked {
        width: 95%;
        height: 10%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }

  &__spinner {
    margin: auto;
    width: 40px;
    height: 40px;
    position: relative;
    text-align: center;
    -webkit-animation: sk-rotate 2s infinite linear;
    animation: sk-rotate 2s infinite linear;
    &_small {
      width: 20px;
      height: 20px;
    }
  }

  &__dot1,
  &__dot2 {
    width: 24px;
    height: 24px;
    display: inline-block;
    position: absolute;
    top: 0;
    background-color: var(--color-primary-1);
    border-radius: 12px;

    -webkit-animation: sk-bounce 2s infinite ease-in-out;
    animation: sk-bounce 2s infinite ease-in-out;
  }
  
  &__dot2 {
    top: auto;
    bottom: 0;
    -webkit-animation-delay: -1s;
    animation-delay: -1s;
  }
}

@-webkit-keyframes sk-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes sk-rotate {
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
  }
}

@-webkit-keyframes sk-bounce {
  0%,
  100% {
    -webkit-transform: scale(0);
  }
  50% {
    -webkit-transform: scale(1);
  }
}

@keyframes sk-bounce {
  0%,
  100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
