@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

%details-text-styles {
  @extend %p3;
  line-height: 18px;
  letter-spacing: 0.223333px;
}

.sk-checkout-subscription-item {
  display: grid;
  // justify-content: space-between;
  padding: 40px 26px 38px 40px;
  grid-template-areas: 'picture title price'
                       'picture details price';
  grid-template-columns: 64px 4fr 1fr;


  > img {
    grid-area: picture;
    width: 64px;
    height: 50px;
  }

  &__title {
    grid-area: title;
    margin: 0 0 16px 20px;
  }
  &__title.paragraph-sk {
    @extend %details-text-styles;
    color: #610FB6;
    font-weight: 500;
  }
  &__details {
    grid-area: details;
    margin-left: 20px;
    .paragraph-sk {
      @extend %details-text-styles;
    }
    &__billing-period {
      display: flex;
      align-items: center;
      margin-bottom: 8px;
    }
    &__max-courses {
      display: flex;
      align-items: center;
      // grid-area: 'max-courses';
    }
  }

  // @media screen and (max-width: 700px) {
  // }
}

.sk-checkout-subscription-item-price {
  grid-area: price;

  .paragraph-sk {
    @extend %p1;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.293125px;
    color: #20292F;
    text-align: right;
  }

  &__price-with-discount {}
  &__original-price {}
  &__price-crossed {}
}
