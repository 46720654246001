@import '../../../utils/styles/variables';

.sk-nearest-webinar-block {
  margin: 10px 0;
  &__note {
    margin-top: 16px;
    font-style: italic;
  }
  &__asterix {
    color: var(--color-secondary-1);
  }
  &__card {
    padding: 20px 65px;
    border-radius: 12px;
    min-height: 98px;
  }
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__info {
    flex: 0 1 80%;
    margin-right: 16px;
  }

  @media screen and (max-width: $breakpoint-md) {
    &__inner {
      flex-flow: column wrap;
      justify-items: center;
      align-items: center;
    }
    &__info {
      flex: 0 1 60%;
      margin: 0 0 16px 0;
      width: 100%;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__card {
      width: 100%;
      padding: 20px 20px;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    &__card {
      width: 100%;
      padding: 20px 10px;
    }
  }
}
