%flex {
  display: flex;
}

%flex-hor-center {
  @extend %flex;
  flex-flow: row wrap;
  justify-content: center;
}

.sk-auth-modal {
  display: flex;
  flex-direction: column;
  &_header {
    @extend %flex-hor-center;
    margin-bottom: 12px;
  }

  &_heading {
    line-height: 26px;
  }

  &_content {
    @extend %flex-hor-center;
    margin-bottom: 32px;
  }

  &_footer {
    @extend %flex-hor-center;
  }
}
