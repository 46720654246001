@import '../../../../../utils/styles/variables';

.mui-override {
  &.sk-hw-table-row {
    &__panel {
      background: var(--color-grey7);
      border-radius: 0;
      box-shadow: none;
      margin-bottom: 0;
      &:before {
        background: var(--color-white);
      }
      &_expanded {
        margin-top: 0;
      }
    }

    &__summary {
      padding: 0;
      height: 70px;
      &-content {
        margin: 0;
        > :last-child {
          padding-right: 0;
        }
      }
    }

    &__details {
      background: var(--color-white);
      padding: 0;
      box-shadow: none;
      border-radius: 6px;
    }
    &__btn {
      right: 8.5%;
      padding: 0;
      @media screen and (max-width: $breakpoint-lg) {
        right: 4.25%;
      }
    }
  }
}
