@import '../../../utils/styles/variables';

.sk-courses-list {
  // &._one-row {
  //   grid-template-rows: 650px;
  // }

    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    &::after {
      content: "";
      max-width: 300px;
      width: calc(33% - 20px);
      @media screen and (max-width: 1023px){
        width: calc(50% - 30px);
      }
      @media screen and (max-width: $breakpoint-sm){
        width: 100%;
      }
    }


    @media screen and (max-width: 1023px){
      justify-content: space-around;
    }
    @media screen and (max-width: 767px){

      justify-content: space-around;
    }
    @media screen and (max-width: $breakpoint-sm){
      flex-direction: column;
    }
    .sk-course-card{ 
      max-width: 300px;
      flex-basis: calc(33% - 20px);
      @media screen and (max-width: 1366px){
        flex-basis: calc(33% - 20px);
      }
      @media screen and (max-width: 1023px){
        flex-basis: calc(50% - 30px);
      }
      @media screen and (max-width: $breakpoint-sm){
        width: 100%;
        flex-basis: auto;
      }
    }

  &__pagination {
    display: flex;
    justify-content: center;
  }
}
