@import '../../utils/styles/variables.scss';

.sk-landing-page {
  display: flex;
  &__container {
    flex: 1;
    width: 100%;
  }
}

.sk-lp-category-item__heading{
  @media screen and (max-width: $breakpoint-md){
    text-align: center;
  }
}
