@import 'utils/styles/_variables.scss';
@import 'utils/styles/text.scss';

.sk-expert-cabinet-fee {
  .sk-content-header__breadcrumbs {
    .sk-link {
      @extend %p2;
      font-weight: 500;
    }
  }

  .mui-override.sk-tabs {
    height: 0;
    box-shadow: none;
    margin: 0 0 30px;
    width: auto;
    display: flex;
    justify-content: flex-start;
  }

  .mui-override.sk-tabs-tab {
    height: 0;
    width: auto;
    &:first-child {
      margin-right: 18px;
    }
  }

  .sk-subscription-collapse-wrapper-inner {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;

    @media screen and (max-width: $breakpoint-lg) {
      flex-direction: column;
      align-items: center;
    }
    @media screen and (max-width: $breakpoint-sm) {
      align-items: flex-start;
    }
  }

  .sk-commission-collapse-wrapper-inner {
    display: flex;
    flex-direction: column;
    align-items: center;
    > .paragraph-sk {
      @extend %h2;
      &_small {
        @extend %h3;
        margin-top: 20px;
      }
    }
    > .button-sk {
      margin-top: 35px;
    }
  }
}
