.comments-icon{
  g{
    transition: opacity .3s;
  }
  .filled{
    opacity: 0;
  }
  .unfilled{
    opacity: 1;
  }
  &:hover{
    .filled{
      opacity: 1;
    }
    .unfilled{
      opacity: 0;
    }
  }
}
