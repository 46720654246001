@charset "UTF-8";
.mui-override.sk-сoursesTable__cell {
  padding: 2px 10px;
  border-bottom: none; }
  .mui-override.sk-сoursesTable__cell__elements {
    display: flex;
    align-items: baseline; }
    .mui-override.sk-сoursesTable__cell__elements__arrow {
      visibility: hidden;
      padding-left: 5px;
      display: flex; }
    .mui-override.sk-сoursesTable__cell__elements > .sk-admin-filter-status, .mui-override.sk-сoursesTable__cell__elements > .sk-admin-filter-status {
      padding-left: 5px; }
    .mui-override.sk-сoursesTable__cell__elements:hover .mui-override.sk-сoursesTable__cell__elements__arrow {
      visibility: initial; }
    .mui-override.sk-сoursesTable__cell__elements:hover p {
      cursor: pointer; }
  .mui-override.sk-сoursesTable__cell:first-child {
    border-bottom: none;
    padding: 2px 15px 0 45px; }
  .mui-override.sk-сoursesTable__cell:last-child {
    padding: 2px 45px 0 10px;
    border-bottom: none; }
  .mui-override.sk-сoursesTable__cell_loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%); }

.mui-override.show {
  visibility: initial; }

.mui-override.rotate {
  transform: rotate(180deg);
  padding-left: 0;
  padding-right: 5px; }
