@import 'utils/styles/text.scss';
.mui-override.sk-experts-cabinet-confirm-delete {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__text {
    text-align: center;
    @extend %h3;

  }
  &__buttons {
    &__button:last-child {
      margin-left: 10px;
    }
  }
}
