@import 'utils/styles/text.scss';

.mui-override.sk-admin-plans-table-row {
  &__cell {
    margin: 0;
    padding: 0;
    border: none;
    height: 80px;
    > .paragraph-sk {
      @extend %p2;
      color: var(--dark-text);
    }
    &:first-of-type {
      padding-left: 60px;
    }
    &:first-of-type {
      padding-right: 60px;
    }
  }

  &:nth-child(odd) {
    background: var(--table-row);
  }
}
