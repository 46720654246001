@import '../../../utils/styles/variables';

.sk-sidebar-toggle-btn {
  &_expanded {
    @media screen and (max-width: $breakpoint-sm) {
      position: fixed;
      z-index: 1300;
      top: 19px;
      left: 258px;
      transform: translateX(50%)
    }
    
  }
}