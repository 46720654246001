%flex-parent {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.sk-course-edit {
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  &__main {
    z-index: 11;
    @extend %flex-parent;
    margin-bottom: 40px;
  }
  &__content {
    @extend %flex-parent;
    width: 100%;
  }

  &__inner {
    flex: 1;
    margin-bottom: 55px;
  }

  &__item {
    &:not(:last-of-type) {
      margin-bottom: 40px;
    }
  }


}
.sk-course-details-form-footer {
  display: flex;
  justify-content: flex-end;
}