@import 'utils/styles/text.scss';

.sk-expert-payment-details {
  display: flex;
  flex-direction: column;
  align-items: center;
  > .paragraph-sk {
    @extend %h3;
    margin-bottom: 15px;
  }
  > .sk-link {
    @extend %p2;
    color: var(--purple-dark);
    text-decoration: underline;
    margin-top: 26px;
    cursor: pointer;
  }
}
