@import 'utils/styles/_variables.scss';
.sk-summary-action-block {
  button {
    //display: block;
    &:last-child > svg {
      margin-left: 34px;
      @media screen and (max-width: $breakpoint-sm) {
        margin-left: 0;
      }
    }
  }
}
