@import 'utils/styles/_variables.scss';

.sk-expert-cabinet-toppings {
  display: flex;
  flex-direction: column;

  &__heading {
    display: flex;
    justify-content: space-between;
    margin: 25px 0;

    > .sk-content-header {
      height: auto;
    }

    &__filters {
      display: flex;
      > div:first-child {
        margin-right: 40px;
      }
      .mui-override.sk-dropdown{
        z-index: 50;
      }
    }
    .sk-expert-filter-type{
      width: auto;
    }
  }


  > .sk-link {
    width: 100%;
  }
  @media screen and (max-width: $breakpoint-sm) {
    &__heading {
      flex-direction: column;

      &__filters {
        margin-top: 25px;
        justify-content: flex-end;
      }
      .sk-expert-filter-type{
        width: auto;
      }
    }
  }
}
