.upload-menu-item {
  width: 255px;
  min-height: 48px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__info {
    display: flex;
    align-items: center;
    &__title {
      width: 170px;
      white-space: pre-line;
      line-height: 18px;
      padding-left: 10px;
    }
  }

}
