@import 'utils/styles/_variables.scss';

.sk-checkout {
  width: 561px;
  position: relative;

  &__total {
    display: flex;
    flex-wrap: wrap;
    // justify-content: space-between;
    // align-items: center;
    justify-content: flex-end;
    padding: 30px 0 40px;
  }

  @media screen and (min-width: $breakpoint-lg) {
    // top: 41px;
    right: 23px;
  }
  @media screen and (max-width: $breakpoint-lg) {
    margin: 0 auto;
    width: 668px;
  }
  @media screen and (max-width: $breakpoint-xs) {
    &__total {
      flex-direction: column-reverse;
    }
  }
}
