.sk-no-courses-found {
  margin-bottom: 36px;
  display: flex;
  justify-content: center;
  &__card {
    max-width: 839px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 44px 36px;
  }
  &__heading {
    font-size: 24px;
    text-align: center;
    margin-bottom: 24px;
  }
  &__icon {
    display: flex;
    justify-content: center;
  }
}