.sk-admin-plans-sale {
  padding: 32px 58px 60px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div:first-child, > div:nth-child(2) {
    max-width: calc(50% - 8px);
    margin-bottom: 16px;
  }
  > div:first-child {
    margin-right: 16px;
  }
}
