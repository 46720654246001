@import 'variables';

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
  box-shadow: 0 30px 67px 0 var(--color-box-shadow-primary);

  table.rbc-agenda-table {
    width: 100%;
    border: none;
    border-spacing: 0;
    border-collapse: collapse;

    tbody > tr > td {
      padding: 5px 10px;
      vertical-align: top;
    }

    .rbc-header {
      background: var(--color-primary-1);

      &:first-child {
        border-top-left-radius: 8.8px;
      }
      &:last-child {
        border-top-right-radius: 8.8px;
      }
    }

    .rbc-agenda-time-cell {
      padding-left: 15px;
      padding-right: 15px;
      text-transform: lowercase;
    }

    tbody > tr > td + td {
      border-left: 1px solid var(--rbc-cell-border);
    }

    .rbc-rtl & {
      tbody > tr > td + td {
        border-left-width: 0;
        border-right: 1px solid var(--rbc-cell-border);
      }
    }

    tbody > tr + tr {
      border-top: 1px solid var(--rbc-cell-border);
    }

    thead > tr > th {
      padding: 3px 5px;
      text-align: left;
      border-bottom: 1px solid var(--rbc-cell-border);

      .rbc-rtl & {
        text-align: right;
      }
    }
  }
}

.rbc-agenda-time-cell {
  text-transform: lowercase;

  .rbc-continues-after:after {
    content: ' »'
  }
  .rbc-continues-prior:before {
    content: '« '
  }
}

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap;
}



.rbc-agenda-event-cell {
  width: 100%
}
