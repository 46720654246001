@import 'utils/styles/color.scss';
@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-student-toppings{
  width: 100%;
  &_top{
    display: flex;
    justify-content: space-between;
  }
  &_list{
    margin-top: 15px;
  }
  &_filter{
    display: flex;
    .mui-override.sk-dropdown{
      z-index: 50;
    }
    .mui-override.sk-dropdown-toggler {
      .sk-filters-icon{
        g#active{
          opacity: 0;
        }
      }
      &.open{
        .sk-filters-icon{
          g#default{
            opacity: 0;
          }
          g#active{
            opacity: 1;
          }
        }
      }
    }
  }
  .sk-student-toppings_filter {
    align-items: center;
  }
  @media screen and (max-width: $breakpoint-sm) {
    .sk-student-toppings_top {
      flex-direction: column;
    }
    .sk-student-toppings_filter{
      justify-content: flex-end;
      .sk-expert-filter-type{
        width: auto;
      }
    }
  }
}
