@import './variables';
@import './text';

.sk-dropdown-toggler {
  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
  &__menu-icon {
    margin-right: 18px;
  }
  &__title {
    color: var(--color-new-grey);
    // font-size: var(--text-fontSize-small);
    @extend %p1;

    @media screen and (max-width: 1030px) {
      font-size: var(--text-fontSize-xxsmall);
    }
  }
}

.sk-background-spot {
  &__primary {
    background-image: url('/assets/images/spot2.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 95% 98%;
  }
  &__secondary {
    background-image: url('/assets/images/spot.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 95% 98%;
  }
  &__terniary {
    background-image: url('/assets/images/spot3.svg');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 95% 98%;
  }
}

.sk-form {
  &__inner {
    display: flex;
    flex-direction: column;
  }
  &__row {
    position: relative;
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    & > div {
      flex: 1 1 100%;
      &:first-of-type {
        margin-bottom: 15px;
        &:only-child {
          margin-bottom: 0px;
        }
      }
    }

    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
    @media screen and (min-width: $breakpoint-sm) {
      &:not(:last-of-type) {
        margin-bottom: 21px;
      }
      & > div {
        flex: 0 0 47.2%;
        margin-bottom: 0;
        &:first-of-type {
          margin-bottom: 8px;
          &:only-child {
            flex: 1;
          }
        }
      }
    }
  }
  &_footer {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
  }
}

.sk-main-layout-wrapper {
  max-width: 1110px;
  width: 80.7%;
  margin: 0 auto;

  @media screen and (max-width: $breakpoint-md) {
    width: 85.75%;
  }

  @media screen and (max-width: $breakpoint-sm) {
    width: 93.75%;
  }

  &_wide {
    width: 100%;
    max-width: 100%;
    @media screen and (max-width: $breakpoint-sm) {
      max-width: 100vw;
      width: 100%;
    }
  }
}

.sk-subtitle {
  color: var(--color-black);
  font-size: var(--text-fontSize-body);
  letter-spacing: 0.2px;
  font-weight: 700;
}
