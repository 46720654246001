.sk-lec-video-modal {
  &__header {
    margin-bottom: 35px;
  }
  &__inner {
    margin-bottom: 36px;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
  &__card {
    &.sk-modal-content {
      padding: 49px 30px 43px 30px;
    }
  }
  &__time {
    margin-bottom: 31px;
  }
}

.sk-lec-video-modal-time {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  &__icon {
    margin-right: 9px;
  }
  &__duration {
    display: flex;
  }
}
