@import 'utils/styles/text.scss';

.sk-course-preview {
  margin-top: 50px;
  padding-bottom: 50px;

  &__title {
    @extend %h2;
    color: var(--dark-text);
  }

  &__video {
    position: relative;
  }
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    background: var(--color-secondary-1);
    cursor: pointer;
    &:hover {
      background: var(--color-white);
    }
  }

  @media (max-width: 1024px) {
    width: 96.5%;
  }
}
