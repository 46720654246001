.sk-webinar-grid {
  height: 100%;
  padding-top: 32px;
  background-size: 90% 90%;
  &__content {
    height: 100%;
  }
  &__main {
    flex: 1;
  }
  &__block {
    padding: 0 100px;

    @media screen and (max-width: 768px) {
      padding: 0 10px;
    }
  }
}
