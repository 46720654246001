.sk-coursesList {
  height: 100%;
  &__contentHeader {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &__left {
      display: flex;
    }
    &__right {
      display: flex;
      &__search {
        position: relative;
        right: -18px;
        .MuiFormControl-root{
          width: 103%;
        }
      }
      &__icon {
        align-self: center;
        position: relative;
        right: -5px;
        bottom: 4px;
      }
    }
  }

  &__table {
    position: relative;
    width: 100%;
    background: var(--color-white);
    box-shadow: var(--box-shadow-paper-lg);
    border-radius: 8.8px;
  }

  &__tableHead {
    padding-top: 30px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__contentHeader {
    display: flex;
    width: 120px;
  }

  &__panelName {
    padding-top: 35px;
    white-space: nowrap;
  }
}
