.mui-override.sk-webroom-chat-list {
  
  &__item {
    padding: 8px 0px 8px;
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
    &-text {
      padding: 0 12px;
    }
    &__avatar {
      flex: 0 0 37px;
      height: 37px;
    }
  }
}

.sk-webroom-chat-item-sec {
  display: flex;
  justify-content: space-between;
  line-height: 1.5;
  color: var(--color-grey3);
  &__name {
    margin-right: 5px;
    flex: 1 1 auto;
    line-height: inherit;
    color: inherit;
  }
  &__time {
    flex: 1 0 57px;
    line-height: inherit;
    color: inherit;
  }
}


.sk-webroom-chat-item-prim  {
 font-weight: 300;
 word-break: break-all;
}