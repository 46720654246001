@import 'utils/styles/text.scss';
.sk-course-action-panel {
  display: flex;
  flex-direction: column;
  &__error {
    padding-top: 5px;
    text-align: center;
    @extend %p3;
    color: red;
  }
  &__buttons {
    display: flex;
    justify-content: space-between;
    &__item {
      &:not(:first-of-type) {
        margin-left: 30px;
      }
      &:last-child {
        margin-left: 20px;
      }
      &:not(:last-child) {
        div button {
          margin-left: 10px;
        }
      }
    }
  }
}
