@import "../node_modules/normalize.css/normalize.css";
@import "../src/utils/styles/variables.css";
@import "../src/utils/styles/grid.scss";
@import "../src/utils/styles/globalStyles";
@import "../src/utils/styles/color.scss";
@import "../src/utils/styles/text.scss";
@import "../src/utils/styles/functions.scss";

*,
*::after,
*::before {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  min-height: 100vh;
  min-width: 320px;
  max-width: 100vw;
  font-family: "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--text-fontSize-body);
  div {
    &:focus {
      outline: none;
    }
  }
}


@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-BoldItalic.ttf") format("truetype");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-MediumItalic.ttf") format("truetype");
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Italic.ttf") format("truetype");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: url("/assets/fonts/Roboto-LightItalic.ttf") format("truetype");
  font-weight: lighter;
  font-style: italic;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("/assets/fonts/RobotoSlab-Black.ttf") format("truetype");
  font-weight: black;
  font-style: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("/assets/fonts/RobotoSlab-Bold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("/assets/fonts/RobotoSlab-Light.ttf") format("truetype");
  font-weight: lighter;
  font-style: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("/assets/fonts/RobotoSlab-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "RobotoSlab";
  src: url("/assets/fonts/RobotoSlab-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace, sans-serif;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  appearance: none;
  margin: 0;
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
  -moz-appearance: textfield;
}


input[type='date'] {
  &::-webkit-clear-button {
    display: none;
  }

  &::-webkit-inner-spin-button {
    display: none;
  }

  &::-webkit-calendar-picker-indicator {
    color: var(--color-white);
    opacity: 0;
    cursor: pointer;
  }

  &::-webkit-calendar-picker-indicator:after {
    content: '';
    display: block;
    width: 40px;
    height: 40px;
    position: absolute;
    top: 50%;
    right: 0;
    margin-top: -16px;
  }

  &::-webkit-calendar-picker-indicator:hover {
    background: none;
  }
}
input[type='time'] {
  &::-webkit-clear-button {
    display: none;
  }
}

button {
  background: transparent;
  border: none;
  width: auto;
  padding: 0;
  margin: 0;
  cursor: pointer;
  &:focus {
    outline: none;
  }
}


ul {
  list-style: none;
  padding: 0;
  margin: 0;
}
