.sk-lec-article-modal {
  &__inner {
    margin-bottom: 36px;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
  &__time {
    max-width: 200px;
  }
  &__item {
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
}
