@import 'utils/styles/text.scss';
.sk-deactivate-waning-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 20px;
  &__head {
    @extend %h3;
    text-align: center;
  }
  &__text {
    @extend %p1;
    text-align: center;
  }
  &__image {
    width: 61px;
    height: 61px;
  }
}
