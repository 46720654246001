@import "utils/styles/_variables.scss";

.sk-landing-page__comments_carousel {
  &__slide {
    padding: 60px 30px 20px;
    @media screen and (max-width: $breakpoint-xs) {
      padding-right: 30px;
    }
  }
  .nav-controll {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    position: relative;
    z-index: 2;
    top: -20px;
    margin-bottom: 30px;

    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 42px;
      // margin-top: -52px;
      top: 0;
    }
    .carousel_dot {
      display: block;
      width: 13px;
      height: 13px;
      border-radius: 50%;
      transition: all 0.3s;
      box-shadow: 0px 20px 40px rgba(67, 0, 104, 0.1) !important;
      border: 1px solid #30d5c8 !important;
      cursor: pointer;
      &:not(:first-child),
      &:not(:last-child) {
        margin: 0;
      }
      &:nth-child(2) {
        margin: 0 10px;
      }
      &:hover,
      &.active {
        background: linear-gradient(90deg, #30d5c8 0%, #1ebbae 100%);
        box-shadow: 0px 20px 40px rgba(67, 0, 104, 0.1);
        transform: scale(1.5);
      }
    }
  }
}
