@import 'utils/styles/text.scss';
@import 'utils/styles/color.scss';

.sk-topping-details_attachment{
  width: 100%;
  .heading-sk{
    margin-bottom: 11px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: var(--dark-text);
  }
  .mui-override.sk-card{
    padding: 24px 14px;
    display: flex;
    justify-content: space-between;
  }
  &_top{
    display: flex;
    .paragraph-sk_basic{
      width: 60%;
    }
  }
  &_text{
    margin-left: 16px;
  }
  .sk-duration-component__duration{
    display: inline-block;
    margin-top: 9px;
    color: var(--medium-stroke);
    text-align: left;
  }
}
