.sk-user-block {
  display: flex;
  align-items: center;
  background: #E8E7FF;
  box-shadow: 0px 27px 60px rgba(91, 80, 126, 0.1);
  border-radius: 5px;
  padding: 16px 25px;
  &__item {
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}
