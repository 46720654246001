.sk-editor-video-block {
  position: relative;
  padding-bottom: 56.25%; /*16:9*/
  padding-top: 30px;
  height: 0;
  overflow: hidden;
}

.sk-editor-video-block iframe,
.sk-editor-video-block object,
.sk-editor-video-block embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
