.resume-svg {
  overflow: visible;
  & > .outlined {
    display: initial;
  }
  & > .filled {
    display: none;
  }
  &:hover {
    & > .outlined {
      display: none;
    }
    & > .filled {
      display: initial;
    }
  }
}
