.sk-radio-button-label {
  &__price {
    .paragraph-sk_caption {
      color: var(--base-text);
    }
    transform: translate(0px, 3px);
  }
  > svg {
    display: block;
  }
  .price-value {
    font-size: 14px;
    padding-bottom: 16px;
    margin: 0;
    sup {
      top: -0.35em;
      display: inline-block;
      margin-right: 2px;
      font-size: 11px;
    }
  }
}
