@import 'utils/styles/_variables.scss';

.sk-lec-study-materials {
  &__item {
    display: flex;
    align-items: center;
    flex-direction: row;
    outline: none;
    &__desktop {
      display: block;
    }
    &__mobile {
      display: none;
    }
    @media screen and (max-width: $breakpoint-sm) {
      display: block;
      &__desktop {
        display: none;
      }
      &__mobile {
        display: block;
      }
    }
    .sk-lec-material-entity {
      max-width: 640px;
      width: 100%;
      margin: 10px 30px 10px 0;
      padding: 15px 22px;
      background: #FFFFFF;
      box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.15);
      border-radius: 5px;
      @media screen and (max-width: $breakpoint-sm) {
        max-width: 100%;
        margin-right: 0;
      }
    }
  }
}
