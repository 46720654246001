@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.mui-override.sk-expert-analytics-duration {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 14px 0;
  padding: 15px calc(20px + 5%) 40px;
  margin: 30px 0;
  &__text {
    position: relative;
    top: 10px;
    @extend %p2;
    font-weight: bold;
  }
  &__selectors-group {
    display: flex;
    &__selector {
      width: auto;
      display: flex;
      align-items: center;
      .mui-override.sk-materialized-input_picker {
        width: 140px;
      }
      > .sk-expert-analytics-duration__text {
        margin-right: 15px;
      }
      &:last-child {
        margin-left: 30px;
      }
    }
  }
  &__button {
    position: relative;
    top: 12px;
  }

  @media screen and (max-width: $breakpoint-md) {
    flex-direction: column;
    padding: 20px 20px;
    &__selectors-group {
      &__selector {
        .mui-override.sk-materialized-input_picker {
          width: 140px;
        }
      }
    }
    &__button {
      top: 20px;
      margin-bottom: 30px;
    }
  }

  @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    &__selectors-group {
      &__selector {
        .mui-override.sk-materialized-input_picker {
          width: 250px;
        }
      }
    }
  }

  @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-md) {
    &__selectors-group {
      justify-content: center;
      &__selector {
        &:last-child {
          margin-left: 50px;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__button {
      top: 40px;
      margin-bottom: 60px;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    &__selectors-group {
      flex-direction: column;
      &__selector {
        width: 212px;
        .mui-override.sk-materialized-input_picker {
          margin-left: auto;
        }
        &:last-child {
          margin-left: 0;
        }
      }
    }
  }
}
