.sk-purchased-course-materials {
  &__completion {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    box-shadow: var(--box-shadow-paper-lg);
    border-radius: 8.8px;
    background: var(--color-white);
    margin-top: 20px;
    padding: 25px 50px;
    margin-bottom: 50px;
  }
  
  &__afterwords {
    padding-right: 50px;
  }

  &__wrapper {
    max-width: 1110px;
    width: 80.7%;
    margin: 0 auto;
  }

  &__lectures {
    margin-top: 20px;
    box-shadow: var(--box-shadow-paper-lg);
    border-bottom-right-radius: 8.8px;
    border-bottom-left-radius: 8.8px;
  }
  &__modal {
    min-width: 50%;
  }


  @media screen and (max-width: 768px) {
    &__completion {
      flex-direction: column;
    }
    &__afterwords {
      display: flex;
      width: 100%;
      padding-right: 0;
      padding-bottom: 20px;
    }
  }
}
