.sk-course-hero {
    position: relative;
    width: 100%;
    height: 422px;
    display: flex;
    justify-content: center;
    margin-bottom: -90px;
    &__wrapper {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
    &__header {
        flex: 0 0 auto;
        margin-bottom: 26px;
    }
    &__inner {
        display: flex;
        position: relative;
        justify-content: center;
        flex: 2;
    }
    &__image {
        position: absolute;
        width: 100%;
        top: 0;
        left: 0;
        height: 422px;
    }
    &__title {
        flex: 1;
        display: flex;
        justify-content: center;
        align-items: flex-start;
    }
    &__overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: var(--color-overlay);
    }
}