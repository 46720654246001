/* headers */
%h1 {
  font-family: RobotoSlab, Ubuntu, Helvetica, sans-serif;
  font-size: 60px;
  line-height: 70px;
  font-weight: bold;
  font-style: normal;
  color: var(--dark-text);
}

%h2 {
  font-family: RobotoSlab, Ubuntu, Helvetica, sans-serif;
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  font-style: normal;
  color: var(--dark-text);
}

%h3 {
  font-family: Roboto, Ubuntu, Helvetica, sans-serif;
  font-size: 20px;
  line-height: 24px;
  font-weight: 500;
  font-style: normal;
  color: var(--dark-text);
}

/* paragraphs */
%p1 {
  font-family: Roboto, Ubuntu, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: var(--base-text);
  font-weight: normal;
  font-style: normal;
}

%p1-medium {
  @extend %p1;
  font-weight: 500;
}
%p1-bold {
  @extend %p1;
  font-weight: bold;
}

%p2 {
  font-family: Roboto, Ubuntu, Helvetica, sans-serif;
  font-size: 14px;
  line-height: 22px;
  font-weight: normal;
  font-style: normal;
  color: var(--base-text);
}

%p2-medium {
  @extend %p1;
  font-weight: 500;
}

%p2-bold {
  @extend %p2;
  font-weight: bold;
}

%p3 {
  font-family: Roboto, Ubuntu, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: var(--base-text);
}

%p3-light {
  @extend %p3;
  font-weight: lighter;
}

%p3-medium {
  @extend %p3;
  font-weight: 500;
}

%p4 {
  font-family: Roboto, Ubuntu, Helvetica, sans-serif;
  font-size: 10px;
  font-weight: normal;
  font-style: normal;
  color: var(--base-text);
}
