@import '../../../../utils/styles/variables';

.sk-course-details {
  &__content {
    padding: 36px 0 54px;
    @media screen and (max-width: $breakpoint-md) {
      padding-left: 8px;
      padding-right: 8px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      padding-left: 18px;
      padding-right: 18px;
    }
  }
}
