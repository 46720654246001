.sk-lec-article-uploader {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 25px;
  padding: 33px 0px 40px;
  border: dashed 1px var(--color-grey3);
  border-radius: 8px;
  outline: none;
  &.disabled {
    opacity: 0.4;
    pointer-events: none;
  }
  &__header {
    margin-bottom: 12px;
    text-align: center;
  }
  &__actions-with-file {
    & > button:nth-child(2) {
      margin-left: 15px;
    }
    display: flex;
    align-items: center;
  }
  &__select-file-button {
    min-width: 140px !important;
  }
  &__selected-file-name {
    margin-top: 15px;
    cursor: pointer;
    text-align: center;

    &:hover {
      text-decoration: underline;
      text-decoration-color: #663acc;

      p {
        color: #663acc;
      }
    }
  }

  &__file-preview {
  }
  &__file-preview__document {
  }
  &__file-preview__page {
  }
}
