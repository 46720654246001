@import '../../../../utils/styles/variables';

.sk-upload-menu {

  &__wrapper {
    width: 100%;
    max-width: 300px;
    @media screen and (max-width: $breakpoint-sm) {
      max-width: 100%;
    }
  }
  &__footer {
    display: flex;
    height: 62px;
    justify-content: space-between;
    align-items: center;
    background-color: var(--color-grey4);
    padding: 0 7px 0 16px;
  }
  &__empty {
    padding: 16px;
  }
  &__total-text {
    margin-right: 6px;
  }
}
