@import 'utils/styles/_variables.scss';

%text-styles {
  color    : var(--color-black);
  font-size: var(--text-fontSize-body);
}

.sk-lec-summary-component {
  &__title {
    display     : inline;
    margin-right: 6px;
    white-space : nowrap;
    color       : #6D7C90;
    @extend %text-styles;
  }

  &__caution {
    @extend %text-styles;
    font-weight : 300;
    margin-right: 12px;
  }

  position: relative;
  width   : 100%;
  clear   : both;
  display : flex;

  justify-content: space-between;
  align-items    : center;

  &__controls {
    display: flex;
  }

  &__name {
    display            : flex;
    align-items        : center;
    flex-direction     : row;
    justify-content    : flex-start;
    -webkit-user-select: text;
    width              : calc(100% - 80px);

    .sk-subtitle {
      margin-bottom     : 0;
      width             : calc(100% - 120px);
      overflow          : hidden;
      text-overflow     : ellipsis;
      // display           : -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
  }

  &__header {
    display            : flex;
    float              : left;
    align-items        : center;
    -webkit-user-select: text;
    width              : 100%;

    // width: calc(100% - 161px);
  }

  &__duration {
    display    : flex;
    width      : 80px;
    align-items: unset;
  }

  &__action-block {
    width: 68px;
    float: left;
  }

  &__editable {
    margin-right: 5px;
    word-break  : break-word;
    outline     : none;
  }

  .sk-duration-component__duration {
    margin-left: 15px;
  }

  .sk-lec-item__burger {
    position: absolute;
    right   : -64px;
  }

  @media screen and (max-width: 767px) {
    display: none;
  }
}

.sk-lec-summary-component-mobile {

  &__editable {
    margin-right: 5px;
    word-break  : break-word;
    outline     : none;
    border      : none;
  }

  .sk-lec-item__burger {
    position: absolute;
    bottom: 23px;
    right: 23px;
  }

  @media screen and (min-width: 768px) {
    display: none;
  }

  display: flex;
  flex-direction: row;
  min-width: 100%;

  &__left {
    width: calc(100% - 128px);
    max-width: calc(100% - 128px);
    margin-right: 32px;
  }

  &__top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 7px;
  }

  &__small {
    .sk-duration-component__duration {
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }

  &__right {
    width: 96px;
    min-width: 96px;
    .sk-doc-preview {
      margin-right: 15px;
    }
  }
}
