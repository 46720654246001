.sk-pc-material-bg {
  padding-bottom: 5px;
  padding-top: 5px;
  padding-left: 60px;
  padding-right: 40px;
  &.__resume {
    background: var(--color-box-shadow-primary);
  }


  @media screen and (max-width: 480px){
    padding-left: 20px;
    padding-right: 10px;
  }
}

.sk-pc-material {
  display: flex;
  text-decoration: none;
  justify-content: space-between;
  line-height: 28px;

  &__button {
    flex-grow: 1;

    button {
      margin-left: 60px;
      @media screen and (max-width: 767px){
        display: none;
      }
    }
  }

  &__button__mobile {
    display: none;
    width: 100%;
    @media screen and (max-width: 767px){
      display: flex;
      justify-content: flex-end;
      button {
        margin-right: 20px;
      }
    }
  }

  &__duration {
    min-width: 85px;
    text-align: end;
    & > span {
      position: relative;
      right: 20px;
      font-size: var(--text-fontSize-small);
      font-weight: 600;
    }
  }

  svg {
    margin-right: 15px;
  }

  & > a > div > svg, & > div > svg {
    position: relative;
    top: 4px;
  }

  .paragraph-sk {
    margin: 0;
  }

  &__disabled {
    color: #979797;
    svg {
      opacity: 0.4;
    }
  }
}
