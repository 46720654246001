@import 'utils/styles/_variables.scss';

.sk-homework-uploader {
  width: 100%;
  max-width: 640px;
  &__header {
    margin-bottom: 28px;
    &.withHomework {
      margin-bottom: 16px;
    }
  }
  &__content {
    width: 100%;
    margin: 10px 0;
    padding: 15px 22px;
    background: #FFFFFF;
    box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.15);
    border-radius: 5px;
  }
  &__sidenote {
    display: flex;
    justify-content: flex-start;
    position: relative;
    top: 19px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    font-style: italic;
    &__asterix {
      color: var(--color-secondary-1);
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
    &__content {
      right: initial;
      padding: 0;
    }
  }

}

.sk-uploaded-hw {
  position: relative;
  display: flex;
  flex-direction: column;

  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: 10px;

    &__icon {
      margin-right: 8px;
    }
    &__title {
      display: flex;
      align-items: center;
      margin-right: 15px;
    }
  }
  &__question {
    font-size: 12px;
    padding-left: 50px;
    margin: 0;
  }
}

.inactive {
  color: #BCBCBC;
}
