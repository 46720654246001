@import '../../../utils/styles/variables';

.sk-courseReview {
  display: flex;
  background: url('/assets/images/spot.svg') no-repeat;
  background-size: 100% 100%;

  &-block {
    width: 100%;
  }

  &-overflow {
    height: 100%;
    width: 200%;
    display: flex;
  }

  &-card {
    width: 32%;
    min-width: 365px;
    background: var(--color-white);
    border-radius: 8.8px;
    overflow: hidden;
    box-shadow: var(--box-shadow-paper-xs);

    @media screen and (max-width: $breakpoint-md) {
      width: 100%;
    }

    &:first-of-type {
      margin-left: calc(10px + 3.5%);
    }
    &:last-of-type {
      margin-right: calc(10px + 3.5%);
    }
    &:not(:last-of-type) {
      margin-right: 1.8%;
    }

    &-block {
      margin: 20px 20px 0 20px;
      display: flex;
    }
  }

  &-avatar {
    margin-right: 10px;

    div {
      width: 70px;
      height: 70px;
    }
  }

  &-info {
    width: 100%;
  }

  &-name {
    font-size: var(--text-fontSize-h5);
    margin-top: 10px;
    margin-bottom: 10px;
  }

  &-rating {
    width: 100%;
    display: flex;
  }

  &-stars {
    margin-right: 10px;
    width: 100%;

    svg {
      margin-right: 5px;
    }
  }

  &-time {
    width: 55%;
    font-size: var(--text-fontSize-caption);
    color: var(--color-grey3);
  }

  &-desc {
    max-width: 100%;
    padding: 20px;
    overflow: hidden;
    height: 184px;
  }

  &-title {
    font-size: 32px;
    color: var(--color-dim-grey);
    text-align: center;
    margin-bottom: 60px;
    width: 100%;

    &-h3 {
      text-align: center;
      margin-top: 50px;
    }
  }

  &-swipe {
    width: 100vw;

    &-row {
      display: flex;
      height: 310px;
      justify-content: center;
      padding: 16px 0;
    }
  }

  &-buttonRow {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &-button {
    display: flex;
    margin-left: 20px;
  }

  &-container-swipe {
    @media screen and (max-width: $breakpoint-xs) {
      margin: auto;
    }
  }
}
