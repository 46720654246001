@import '../../../../utils/styles/variables';


.sk-company-form {
  margin-bottom: 30px;
  &_header {
    position: relative;
    display: flex;
    height: 138px;
    width: 100%;
    @media screen and (max-width: $breakpoint-sm) {
      height: 99px;
    }
  }
  &_uploader {
    position: absolute;
    top: -40px;
    left: 50%;
    transform: translateX(-50%);
    width: 140px;
    height: 140px;
    display: flex;
  }
  &_avatar {
    width: 100%;
    height: 100%;
  }
  &_inner {
    margin-bottom: 42px;
  }
}
