.sk-web-room-video {
  position: relative;
  height: 100%;
  display: flex;
 
  &__container {
    height: 100%;
    display: flex;
    flex: 1;
    & > div, & > div > div, & > div > div > div {
      height: 100%;
      display: flex;
      flex: 1;
    }
  }

  &__controls {
    position: absolute;
    z-index: 100;
    bottom: 25px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    height: 50px;
  }
  &__control-btn {
    &:not(:last-of-type) {
      margin-right: 16px;
    }
  }
}
.sk-pip-widget {
  position: absolute;
  right: 30px;
  top: 20px;
  max-width: 320px;
  max-height: 240px;
  &__hidden {
    opacity: 0;
  }
}