@import '../../../../utils/styles/variables.scss';

.sk-ac-bank-details-form {
  display: block;
  &__payment-system > div {
    display: flex;
    flex-direction: row;
    & > label {
      margin-right: 40px !important;
      color: yellow;
      &:last-child {
        margin-right: 0px !important;
      }
    }
  }
  &__inputs-block {
    display: flex;
    align-items: flex-end;
  }
  &__street {
    width: 70% !important;
  }
  &__postcode {
    width: 30% !important;
    margin-left: 10px !important;
  }

  &__half-width {
    width: 50% !important;
    &:nth-child(2) {
      margin-left: 10px;
    }
  }
  &__tooltip {
    font-size: 12px;
    width: 226px;
    margin-right: -30px !important;
  }
}

@media screen and (max-width: $breakpoint-md) {
  .sk-ac-bank-details-form {
    &__inputs-block {
      display: flex;
      &-responsive {
        flex-direction: column;
      }
    }
    &__street {
      width: 100% !important;
    }
    &__postcode {
      width: 100% !important;
      margin-left: 0px !important;
    }
    &__half-width {
      &-responsive {
        width: 100% !important;
        &:nth-child(2) {
          margin-left: 0px;
        }
      }
    }
  }
  &__tooltip {
    font-size: 12px;
  }
}

@media screen and (max-width: $breakpoint-sm) {
}
