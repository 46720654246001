.sk-uploaded-image {
  position: relative;
  width: 300px;
  height: 222px;
  margin-top: 25px;
  border-radius: 5px;
  box-shadow: var(--box-shadow-paper-lg);

  &__actions-button {
    & > :first-child {
      margin-right: 9px;
    }
    position: absolute;
    display: flex;
    bottom: 20px;
    right: 20px;
  }
}
