@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.grid.sk-checkout-layout {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 0 0;

  @media screen and (min-width: $breakpoint-lg) {
    justify-content: center;
  }
}
.sk-checkout-layout {
  &__header {
    width: 100%;
    @extend %h2;
    text-align: center;
  }
  &__no-breadcrumbs {
    padding: 55px 0px 25px;
  }

  > button {
      margin: 0 auto;
  }
}
