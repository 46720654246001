@import 'utils/styles/text.scss';

.mui-override.sk-course-details-error-container {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__error {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 400px;
    &__text {
      text-align: center;
      padding-top: 40px;
      @extend %h2;
    }
    &__button {
      width: 200px;
    }
  }
}
