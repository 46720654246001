@import 'utils/styles/_variables.scss';
@import 'utils/styles/text.scss';

.error-message {
  &-production {
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &__text {
      width: 40%;
      position: absolute;
      left: 5%;
      top: 20%;
      &__oops {
        @extend %h1;
      }
      &__title {
        @extend %h3;
      }
      &__description {
        @extend %h3;
      }

    }
    &__background-image {
      width: 65%;
      position: absolute;
      right: 0%;
      bottom: 10%;
      z-index: 2;
      display: flex;
      justify-content: flex-end;
    }
    &__icons {
      &__line {
        position: absolute;
        right: 15%;
        bottom: 15%;
      }
      &__exclamation {
        position: absolute;
        right: 20%;
        bottom: 40%;
      }
      &__round {
        position: absolute;
        right: 30%;
        top: 35%;
      }
      &__cross {
        position: absolute;
        right: 50%;
        top: 27%;
      }
      &__question {
        position: absolute;
        right: 60%;
        bottom: 30%;
      }
      &__line2 {
        position: absolute;
        left: 35%;
        bottom: 15%;
        transform: rotate(45deg);
      }
      &__round2 {
        position: absolute;
        left: 25%;
        bottom: 10%;
      }
    }
  }
}
