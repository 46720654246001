.sk-confirmation-modal {
  padding: 10px;

  &_header {
    margin-bottom: 21px;
    text-align: center;
  }
  &_heading {
    line-height: 1.46;
    margin-bottom: 11px;
    text-align: center;
  }
  &_subheading {
    line-height: 1.5;
  }
  &_content {
    padding: 0 20px;
    display: flex;
    justify-content: center;
  }
}
