.mui-override.sk-avatar-upload-user {
  background-color: transparent;
  width: 100%;
  height: 100%;
  &__empty {
    background-color: var(--color-secondary-1);
  }
  img {
    width: inherit;
    height: inherit;
    opacity: 0.5;
    object-fit: cover;
    &.circle {
      border-radius: 50%;
    }
  }
}
