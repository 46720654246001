@import '../../utils/styles/variables';
@import 'utils/styles/_variables.scss';

.mui-override.sk-sidebar {
  position: relative;
  z-index: $z-index-sidebar;
  left: -258px;
  opacity: 0;
  width: 0;
  transition: left 120ms linear, opacity 120ms linear, width 60ms;
  // background: var(--color-grey8);
  &_open {
    width: 258px;
    left: 0;
    opacity: 1;
  }
  overflow: visible;
  &::-webkit-scrollbar {
    width: 0;
  }
  &_paper {
    position: relative;
    width: 100%;
    overflow: auto;
    background: var(--color-grey8);

  }
  &_paperAnchorDockedLeft {
    border-right: 0px;
  }
  @media screen and (min-width: $breakpoint-sm) {
    overflow: hidden;
    position: relative;
    z-index: 1;
    &_fixed {
      overflow: auto;
      background: var(--color-grey8);
      position: fixed;
      flex-grow: 1;
      margin-top: 77px;
      width: 258px;
      z-index: -1;
      height: 100vh;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    position: fixed;
  }


  &_complexContent {
    @media screen and (max-width: 1023px) {
      position: fixed;
    }
    @media screen and (max-width: $breakpoint-sm) {
      padding-top: 77px;
    }
  }
}
