.sk-web-room-chat {
  background: var(--color-white);
  height: 100%;
  display: flex;
  flex: 0 0 270px;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px 20px 14px;
  &__header {
    flex: 0 0 auto;
  }
  &__list {
    flex: 1;
  }
  &__input {
    flex: 0 0 auto;
  }
  &__scrollbar {
    & > div {
      display: flex;
      flex-direction: column-reverse;
    }
  }
}