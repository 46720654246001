@import '../../../../utils/styles/variables';

.sk-exp-hw-review-uploader {
  &__main {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    &__title {
      display: flex;
      margin-right: 8px;
      font-size: 12px;
    }
    &__btn {
      width: 114px;
      &-text {
        font-size:  var(--text-fontSize-caption);
      }
    }
  }
  &__processing {
    font-size: 10px;
    padding-top: 5px;
    margin: 0;
  }
}

.inactive {
  color: #BCBCBC;
}
