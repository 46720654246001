@import 'utils/styles/text.scss';

.sk-course-variant-price {
  &__checkbox {
    display: flex;
    align-items: center;
    margin-bottom: 5px;
    > label {
      position: relative;
      left: -3px;
    }
    > .paragraph-sk {
      @extend %p1-bold;
      color: #000000;
    }
  }
}
