@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-topping-image-and-type {
  display: flex;
  flex-direction: column;
  > .sk-uploaded-image {
    position: absolute;
    margin: 0;
    align-self: center;
    border-radius: 9px;

    > img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .sk-topping-type-selector{
    &__radio-button-group{
      justify-content: space-between !important;
      flex-wrap: wrap;
      width: 65%;
    }
    &__radio-button{
      margin: 0 0 17px 0 !important;
    }
  }

  @media (min-width: $breakpoint-md) {
    width: 50%;
    height: 100%;
    margin-left: 22px;
    position: relative;

    > .sk-uploaded-image {
      top: -72px;
      width: 100%;
      height: 180px;
      left: 0;
    }
    > fieldset {
      margin-top: 144px;
      width: 100% !important;
    }
  }

  @media (max-width: $breakpoint-md) {
    > .sk-uploaded-image {
      top: -63px;
      width: 409px;
      height: 209px;
    }
    .sk-topping-type-selector{
      &__radio-button-group{
        width: 100%;
      }
    }
  }
  @media (max-width: $breakpoint-sm) {
    > .sk-uploaded-image {
      top: -66px;
      width: 264px;
      height: 132px;
    }
    .sk-topping-type-selector{
      &__radio-button-group{
        width: 75%;
      }
    }
  }
}

.sk-topping-empty-image-background {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: #EBEBEB;
  > div:first-child {
    margin-bottom: 25px;
  }
  > .paragraph-sk {
    @extend %p2;
  }
  @media (max-width: $breakpoint-sm) {
    > .paragraph-sk {
      font-size: 10px;
    }
  }

  &__error {
    border: 1px solid var(--error-color);
    > .paragraph-sk {
      color: var(--error-color);
    }
  }
}
