@import 'utils/styles/text.scss';

.sk-user-view-expert-details-analytics {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 40px;
  > .paragraph-sk {
    @extend %p1;
    color: var(--dark-text);
  }

  &__table {
    margin-top: 15px;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
}

.sk-user-view-expert-details-analytics-item {
  display: flex;
  flex-direction: column;

  > .paragraph-sk {
    @extend %p3;
    color: var(--dark-text);
    font-weight: 500;

    &_small {
      margin-top: 10px;
      @extend %p1;
      color: var(--base-text);
    }
  }
}
