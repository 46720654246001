.sk-doc-preview {
  cursor: pointer;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    background: var(--color-secondary-1);
    cursor: pointer;
    &:hover {
      background: var(--color-white);
    }
  }
  &__view {
    width: 100%;
    padding-top: 24px;
  }
}
