.sk-admin-filter-status {
  .mui-override.sk-dropdown-toggler {
    .sk-filters-icon{
      g#active{
        opacity: 0;
      }
    }
    &.open{
      .sk-filters-icon{
        g#default{
          opacity: 0;
        }
        g#active{
          opacity: 1;
        }
      }
    }
  }
}
