@import 'utils/styles/text.scss';

.sk-experts-cabinet-cancel-commission {
  display: flex;
  flex-direction: column;
  align-items: center;
  > .paragraph-sk {
    @extend %h3;
    text-align: center;
    &_small {
      margin: 40px 0;
      @extend %p2;
    }
  }
  &__actions {
    display: flex;
    justify-content: center;
    > button:first-child {
      margin-right: 30px;
    }
  }
}
