.sk-web-ses {
  &-creator {
    height: 100%;
    display: flex;
    flex-direction: column;
    &__resovled {
      flex: 1;
    }
  
  }
  &-not-created {
    flex: 1;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    &__card {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 18px 36px;
      flex: 0 0 800px;
      min-height: 200px;
    }
  }
}



