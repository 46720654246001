.sk-order-checkout-discount {
  display: flex;
  align-items: baseline;

  &__open {
    width: 100%;
    > button {
      margin-right: 0;
      margin-left: 50px;
      position: relative;
      bottom: 5px;
    }
  }
}
