.mui-override.sk-adminCoursesListTable{

  &__tableBodyCell {
    padding: 2px 10px;

    &._last {
      // padding: 2px 10px 30px 10px;
      border-bottom: none;
    }
  }

  &__tableBodyCellLeft {
    padding: 2px 10px;

    &:first-child {
      padding-left: 45px;
    }

    &._last {
      // padding: 2px 10px 30px 10px;
      border-bottom: none;

      &:first-child {
        padding-left: 45px;
      }
    }
  }

  &__tableBodyCellRight {
    padding: 2px 45px 0 15px;

    &._last {
      padding: 2px 45px 30px 15px;
      border-bottom: none;
    }
  }
}

.sk-adminCoursesListTable__link {
  color: var(--color-primary-1);
  font-size: var(--text-fontSize-body);
}
