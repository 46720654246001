@import '../../utils/styles/color';
@import '../../utils/styles/text';
@import '../../utils/styles/variables';
@import 'utils/styles/_variables.scss';

.sk-course-card {
  width: 288px;
  height: 424px;
  max-width: 340px;
  position: relative;
  margin-bottom: 50px;
  padding-bottom: 10px;
  align-self: center;
  justify-self: center;
  box-sizing: initial;
  transition: top .2s ease 0s;
  top: 0;
  &__background {
    height: 90%;
    top: 40px;
    background-color: var(--color-white);
    border: 1px solid #F1F6FC;
    transition: box-shadow .2s;
    &.mui-override {
      position: absolute;
      box-shadow: 0px 20px 45px rgba(103, 151, 255, 0.2);
      transition: box-shadow .3s;
    }
  }
  &__content {
    top: 0;
    position: absolute;
    width: 90%;
    margin-left: 5%;
    margin-right: 5%;
  }
  &__photo {
    width: 100%;
    height: 140px;
    object-fit: contain;
    border-radius: 10px;
    box-shadow: 0px 11px 30px rgba(103, 151, 255, 0.2);
    position: relative;
    p {
      border-radius: 10px;
      position: absolute;
      margin: 0;
      left: 8px;
      top: 8px;
      background: rgba(255, 255, 255, 0.81);
      padding: 2px 8px;
      font-size: 10px;
      color: #4F4F4F;
      font-weight: 500;
    }
    img {
      border-radius: 10px;
    }
  }

  &__category-subcategory {
    width: 300px;
    height: 16px;
    color: var(--text-color-basic);
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    margin-top: 16px;
    display: block;
  }
  &__name {
    display: inline-block;
    width: 100%;
    overflow: hidden;
    height: 50px;
    margin-top: 7.8px;
    @extend %h3;
  }
  &__rating {
    margin-top: 9px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__stars {
      display: inline-block;
      vertical-align: middle;

      .sk-star-rating_item svg {
        width: 16.9px;
        height: 16.9px;
      }
    }
    &__value {
      margin-left: 10px;
      display: flex;
      align-items: center;
      span{
        @extend %p2;
        &.number{
          font-weight: bold;
        }
      }
    }
  }
  &__description {
    margin-top: 12px;
    display: block;
    width: 300px;
    height: 54px;
    overflow: hidden;
    color: var(--text-color-basic);
  }
  &__creator {
    margin-top: 15px;
  }
  &__separator {
    border-bottom: 1.2px dashed var(--color-grey9);
    margin-top: 14px;
  }
  &__icons-details {
    margin-top: 17px;
    display: grid;
    grid-template-columns: 200px 100px;
    grid-row-gap: 9px;
  }
  &__price-border {
    margin-top: 10px;
    height: 68px;
  }
  &:hover{
    top: -20px;
  }
  &:hover &__background.mui-override{
    box-shadow: 0px 20px 45px rgba(103, 151, 255, 0.4);
  }

  @media screen and (max-width: $breakpoint-lg) {
    margin-bottom: 40px;
    &__background {
      height: 88%;
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    &__content {
      margin: 0;
      width: 100%;
      padding: 0 24px;
      left: 50%;
      transform: translateX(-50%);
    }
    &:hover{
      top: 0px;
    }
  }
  @media (max-width: $breakpoint-xs) {
    height: 460px;
    margin-bottom: 20px;
    &__background {
      height: 82%;
      &.mui-override {
        box-shadow: 0px 20px 30px rgba(103, 151, 255, 0.2);
      }
    }
    &__photo {
      width: 100%;
    }
    &__category-subcategory {
      width: auto;
    }
    &__name {
      width: auto;
    }
    &__description {
      width: auto;
    }
  }
}
