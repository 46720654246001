@import 'utils/styles/text.scss';
.mui-override.sk-expert-analytics-active-students {
  width: calc(100% - 40px);
  margin: 0 20px;

  &__upper-header {
    height: 30px;
    & > th {
      border: none;
      position: relative;
      padding: 0;
      & > p {
        position: relative;
        top: 12px;
        font-size: 12px;
      }
    }
  }
  &__number_students {
    @extend %p3;
    font-size: 12px !important;
  }

  &__cell {
    padding: 0 20px !important;
    &:first-child {
      padding-left: 5% !important;
    }
    &:last-child {
      padding-right: 5% !important;
    }
  }
}
