.sk-sub-webinar-room {
  height: 100%;
  display: flex;
  flex-direction: column;
  &__header {
    margin-bottom: 29px;
  }
  &__main {
    height: 484px;
    width: 100%;
    margin-bottom: 30px;
  }
}