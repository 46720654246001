@import '../../utils/styles/variables';
@import '../../utils/styles/functions';

.sk-courseDetails {
  position: relative;
  top: -77px;
  @media screen and (max-width: $breakpoint-md) {
    max-width: $breakpoint-md;
  }

  @media screen and (max-width: $breakpoint-sm) {
    max-width: $breakpoint-sm;
  }

  @media screen and (max-width: $breakpoint-xs) {
    max-width: $breakpoint-xs;
  }

  &-block {
    margin: 120px auto 20px;
    display: flex;
    padding-left: calculateWidth(126);
    max-width: 100%;
    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column-reverse;
    }

    @media screen and (max-width: $breakpoint-md) {
      flex-direction: column-reverse;
      max-width: 700px;
    }
    @media screen and (max-width: $breakpoint-sm){
      padding-left: 30px;
    }
    @media screen and (max-width: $breakpoint-xs){
      padding-left: 12px;
    }

    &__left {
      width: 50%;

      @media screen and (max-width: $breakpoint-md) {
        width: 100%;
      }
    }

    &__right {
      width: 50%;
      margin: auto;

      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        margin-bottom: -130px;
        display: flex;
        justify-content: center;
        margin-left: -12px;
      }

      @media screen and (max-width: $breakpoint-md) {
        width: 100%;
        margin-bottom: 0;
      }
    }
  }
}
