@import '../../../../utils/styles/variables.scss';
@import '../../../../utils/styles/text.scss';
@import '../../../../utils/styles/functions.scss';

.sk-hero-banner_input-search{
  width: 100%;
  display: flex;
  align-items: flex-end;
  &__field {
    width: 60%;
    border: 0;
    @extend %p1;
    line-height: 22px;
    border-bottom: 1px solid var(--color-white);
    color: var(--color-white);
    background: transparent;
    padding-bottom: 5px;
    &::placeholder{
      font-size: inherit;
      color: inherit;
    }
    &:focus{
      outline: none;
    }
  }
  button{
    padding: 17px 47px !important;
    height: auto !important;
    font-weight: bold;
    margin-left: calculateWidth(39);
  }
  @media screen and (max-width: $breakpoint-xs){
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    &__field{
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }
  }
}
