@import "../../utils/styles/color";

.sk-price-border {
  position: relative;
  display: grid;
  grid-template-columns: 1fr;
  border-top: 1px solid var(--table-row);

  &:before {
    position: absolute;
    z-index: 1;
    border-radius: 8px;
    content: "";
    width: 100%;
    height: 100%;
  }

  &__choice {
    box-shadow: none !important;
    position: relative;
    z-index: 3;

    &__radio-button-group {
      margin-top: 10px;
      margin-bottom: 15px;

      &.mui-override {
        flex-direction: row;
      }
    }

    &__radio-button {
      &.mui-override {
        margin-left: 23px;
      }
      > .sk-radio-label {
        top: -10px;
      }
      .sk-radio-button-label {
        display: flex;
        flex-direction: column;
        // align-items: flex-end;
        svg {
          width: 23px;
          height: 24px;
        }
        p {
          font-weight: 500;
          letter-spacing: 0.173704px;
          color: var(--base-text);

          @media screen and (max-width: 1046px) {
            & span {
              font-size: var(--text-fontSize-body);
            }
          }
        }
      }
    }
  }

  &__tooltip {
    position: absolute;
    z-index: 3;
    margin-top: 5px;
    right: -20px;
    top: -14px;
  }

  > .modal-tick {
    position: absolute;
    bottom: -30px;
    left: 5px;
    transform: rotate(180deg);
    z-index: 2;
  }
}

.modal-tick-center {
  > .modal-tick {
    left: 32%;
  }
}
.modal-tick-right {
  > .modal-tick {
    left: 64%;
  }
}
