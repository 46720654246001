@import "../../../../utils/styles/variables";
@import "../../../../utils/styles/text";

.sk-landing-page-cta-text {
  position: relative;
  z-index: 1;
  padding-top: 12px;
  &__header {
    margin-bottom: 30px;
  }
  &__heading {
    @extend %h1;
    color: var(--color-white);
    font-family: 'RobotoSlab';
    font-weight: bold;
    @media (max-width: 1190px) {
      line-height: 45px;
      font-size: 40px;
    }
  }
  &__desc {

    @extend %p1;
    line-height: 1.75;
    color: var(--color-white);
    letter-spacing: 0.3px;
  }
  &__desc-block {
    margin-bottom: 80px;
    max-width: 590px;
    padding-right: 4vw;
    @media screen and (max-width: $breakpoint-lg){
      margin-bottom: 45px;
    }
  }
  &__action-block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    bottom: 20px;
    position: relative;

    @media (max-width: $breakpoint-sm) {
      flex-direction: column;
      max-width: none;
      &__header {
        margin-bottom: 20px;
      }
    }
  }
}
