.sk-topping-card_action-block {
  transform: translateY(-30px);
  display: flex;
  justify-content: space-between;
  align-items: center;
  &.open{
    opacity: 0;
    position: absolute;
    z-index: -1;
  }

  &__buttons {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    button{
      height: 100%;
      margin-left: 15px;
    }
  }

  &__toggler {
    width: 34px;
    height: 34px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border: 1px solid #F1F6FC;
    box-sizing: border-box;
    box-shadow: 0px 20px 45px rgba(103, 151, 255, 0.2);
    border-radius: 50%;
    &.open{
      background: #610FB6;
      box-shadow: 0px 4px 20px rgba(97, 15, 182, 0.2);
      transform: rotate(180deg);
      svg{
        path{
          fill: #fff;
        }
      }
    }
    svg{
      width: 14px;
      path{
        fill: #610FB6;
      }
    }
  }
}
