.sk-avatar-user {
  &_wrapper {
    width: 47px;
    height: 47px;
  }
  &_round {
    border-radius: 80%;
  }
}

.mui-override.sk-avatar-user {
  background-color: transparent;
  width: 100%;
  height: 100%;
  border: 2px solid #FFFFFF;
  &_empty {
    width: 100%;
    height: 100%;
    border-radius: 50%;
    background-color: var(--color-primary-4) !important;
  }
  img {
    width: inherit;
    height: inherit;
  }
}
