.sk-icon-with-description {
  display: grid;
  grid-template-columns: 15px 1fr;
  align-items: center;
  @media screen and (max-width: 480px) {
    width: 49%;
    display: flex;
  }
  &__description {
    height: 19px;
    font-size: var(--text-fontSize-small);
    color: var(--color-dim-grey);
    margin-left: 10px;
    @media screen and (max-width: 480px) {
      white-space: nowrap;
    }
  }
}

// .sk-icon-with-description:nth-child(2n) {
//   @media screen and (max-width: 480px) {
//     justify-content: flex-end;
//   }
// }
