@import 'utils/styles/_variables.scss';
@import 'utils/styles/functions.scss';

%flex-vertically-centered {
  display: flex;
  align-items: center;
}

%icon {
  button > svg, label > svg {
    margin-right: calculateWidth(68);
  }
}

.sk-file-uploader {
  display: flex;
  flex-direction: column;
  padding: 35px 0 0px;
  &__header {
    letter-spacing: 0.4px;
    color: var(--color-grey1);
    margin-bottom: 20px;
  }
  &__content {
    display: flex;
  }
  &__block {
    position: relative;
    @extend %flex-vertically-centered;
    @media (max-width: $breakpoint-md) {
      align-items: flex-start;
    }
    @media (max-width: $breakpoint-sm) {
      display: flex;
      flex-direction: column;
    }
  }
  &__btn {
    position: relative;
    margin-right: 10px;
    @media (max-width: $breakpoint-sm) {
      width: 100% !important;
      margin-bottom: 15px;
      > button {
        width: 100% !important;
      }
    }
  }
  &__reg {
    flex: 0 0 auto;
  }
  &__error {
    position: absolute;
    bottom: -16px;
    left: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    &__data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      padding: 12px 25px;
      &__desc {
        display: flex;
        align-items: flex-start;
        flex: 0 0 60%;
      }
      &__icon {
        margin-right: 9px;
        @extend %icon;
      }
      &__info {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex: 0 0 40%;
        &__controller {
          @extend %icon;
        }
        &__duration {
          display: flex;
        }
      }
    }
    &__question {
      font-size: 12px;
      margin: 0;
    }
  }
  .sk-upload-controller {
    > button > svg {
      margin-left: 0px !important;
    }
  }
}

.sk-lec-materials {
  &__sidenote {
    line-height: 1.5;
    letter-spacing: 0.3px;
    font-style: italic;
  }
  &__asterix {
    color: var(--color-secondary-1);
  }
}

.sk-uploaded-preview {
  @extend %flex-vertically-centered;
  justify-content: flex-start;
  &__view {
    margin-right: 11px;
  }
  &__title {
    margin-right: 25px;
    span {
      line-height: 1;
    }
  }
  &__loader {
    @extend %flex-vertically-centered;
  }
  &.disabled {
    opacity: 0.5;
  }
  &__controller {
    margin-right: 24px;
  }
}

.inactive {
  color: #BCBCBC;
}

.any-margin {
  margin: 0px;
}
