@import '../../../../utils/styles/variables';

.sk-web-no-events {
  position: relative;
  padding: 25px 35px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  >svg{
    margin-top: 25px;
  }
}

.sk-stud-near-webinar {
  padding: 20px 50px;
  width: 65%;

  &-title {
    display: flex;
    align-items: flex-start;
    &__header {
      display: flex;
      flex-direction: column;
      align-items: baseline;
      padding-top: 1px;
    }
    &__title {
      line-height: 1.35;
      margin-right: 5px;
      margin-bottom: 5px;
      color: var(--color-new-grey);
    }
    &__value{
      color: var(--dark-text);
      
    }
    &__icon {
      margin-right: 10px;
    }
  }

  &-clock {
    margin-top: 25px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media screen and (max-width: $breakpoint-sm) {
      flex-flow: column wrap;
      justify-items: center;
      align-items: flex-start;
    }
    &__actions{
        margin-top: 30px;
        display: flex;
        justify-content: center;
        @media screen and (max-width: $breakpoint-sm) {
        margin-top: 0;
      }
    }
    &__calendar {
      display: inline-flex;
      @media screen and (max-width: $breakpoint-sm) {
        flex-flow: column wrap;
      justify-items: center;
      align-items: flex-start;
        margin-bottom: 16px;
      }
    }
  }
}

.sk-web-panel-date {
  display: inline-flex;
 
  &:not(:last-of-type) {
    margin-right: 24px;
  }
  &__time {
    margin-right: 16px;
  }
  &__date {
    display: inline-flex;
    align-items: center;
    letter-spacing: 0.09px;
    line-height: 1.35;
  }
  &__text {
    display: inline-flex;
    align-items: center;
    color: var(--color-new-grey);
    
  }
  &__icon {
    display: inline-flex;
    margin-right: 10px;
  }
  &, &:not(:last-of-type) {
    @media screen and (max-width: $breakpoint-sm) {
      margin: 0 0 16px 0;
    }
  }
}
