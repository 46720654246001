.sk-mobile-sidebar-menu {
  &-item {
    position: relative;
    display: flex;
    flex: 1;
    &:not(:last-of-type) {
      margin-bottom: 26px;
    }

    &_name {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_btn {
      position: relative;
      flex: 1;
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &_icon {
      margin-right: 15px;
    }
  }
  &_expanded {
    padding-left: 16px;
    width: 100%;
  }
  &_footer {
    padding: 30px 24px;
  }
}

.mui-override.sk-mobile-sidebar {
  &-list {
    padding: 10px 0 10px 24px;
  }
}
