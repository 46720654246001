@import '../../utils/styles/variables';
@import 'utils/styles/_variables.scss';
@import 'utils/styles/functions.scss';
@import 'utils/styles/text.scss';

.sk-footer {
  z-index: $z-index-footer;
  max-width: 100vw;
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  position: relative;
  padding-bottom: 50px;
  padding-top: 50px;
  overflow: hidden;
  background-image: linear-gradient(-86.55deg, var(--purple-gradient-from) -14.75%, var(--purple-gradient-to) 97.53%);
  // Footer overflow other elements. For instance, button in quiz editing
  // @media screen and (max-width: $breakpoint-md) {
  //   padding-top: 185px;
  //   margin-top: -80px;
  // }

  &.landing{
    background-image: none;
    background-position: center 10%;
    background-repeat: no-repeat;
    background-size: cover;
    padding-top: calc(30px + 10vw);
    padding-bottom: 50px;
    @media screen and (max-width: 1367px) {
      padding-top: 30px;
      margin-top: 0px;
      background-image: linear-gradient(-86.55deg, var(--purple-gradient-from) -14.75%, var(--purple-gradient-to) 97.53%) !important;
    }
    @media screen and (max-width: $breakpoint-sm){
    padding-top: 30px !important;
      margin-top: 0px;
      background-image: linear-gradient(-86.55deg, var(--purple-gradient-from) -14.75%, var(--purple-gradient-to) 97.53%) !important;
    }
  }

  &_top {
    width: 100%;
    height: 95px;
    align-items: center;
    border-bottom: 1px solid rgba(215, 182, 255, 0.3);
    flex-wrap: wrap;
    @media screen and (max-width: 888px){
      height: auto;
      padding-bottom: 40px !important;
    }
    &__left,
    &__right{
      display: flex;
      align-items: center;
    }

    &__left{
      @media screen and (max-width: 888px){
        justify-content: space-between;
        width: 100%;
      }
      &_logo{
        margin-right: calculateWidth(111);
        @media screen and (max-width: 1300px){
          margin-right: calculateWidth(25);
        }
        @media screen and (max-width: $breakpoint-md){
          margin-right: 10px;
        }
        @media screen and (max-width: 888px){
          margin-right: 20px;
        }
        .sk-logo-text{
          transform: translateY(-3px);
        }
      }
      &_copy{
        @media screen and (max-width: $breakpoint-md){
          margin: 0 10px;
        }
        @media screen and (max-width: $breakpoint-xs){
          margin: 0;
        }
      }
    }

    &__right{
      @media screen and (max-width: 888px){
        justify-content: space-between;
        align-items: center;
        width: 100%;
        flex-wrap: wrap;
        .sk-footer-top-grid__selectors{
          display: flex;
          .simple-selector > div{
            padding-top: 0;
          }
        }
      }
      @media screen and (max-width: $breakpoint-xs){
        .sk-footer-top-grid__selectors{
          margin: 0 auto;
        }
      }
      &_socials{
        margin-right: calculateWidth(91);
        @media screen and (max-width: 1300px){
          margin-right: 0;
        }
        @media screen and (max-width: 888px){
          width: 50%;
          margin: 15px -10px 0 0 !important;
          .sk-social-block{
            width: 100%;
            justify-content: space-between;
          }
        }
        @media screen and (max-width: $breakpoint-xs){
          width: 100%;
          .sk-social-block{
            width: 100%;
            align-items: center;
          }
        }
      }
    }

    &_env-settings {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    @media screen and (max-width:888px) {
      height: auto;
      &_env-settings {
        flex-direction: column;
        padding-top: 25px;
      }
    }
  }
  &_bottom {
    width: 100%;
    padding-top: 42px;
  }

  @media screen and (max-width: 900px) {
    &_bottom {
      padding-top: 30px;
    }
  }
  &_logo {
    width: 157px;
  }
  &-caption-text {
    font-size: var(--text-fontSize-caption);
    color: var(--color-primary-9);
    @media screen and (max-width: 900px) {
      text-align: right;
    }
  }
}

.sk-footer-top-grid {
  $this: &;
  &.grid {
    @media screen and (min-width: 900px) and (max-width: $breakpoint-md) {
      grid-template-columns: 10px 104px 164px auto 10px;
      & div {
        grid-column: auto;
      }
    }
    @media screen and (max-width: 900px) {
      padding-bottom: 40px;
      grid-template-rows: 77px 83px;
      grid-template-columns: 5px 3fr auto 3fr 5px;
      grid-column-gap: 10px;
      grid-template-areas:
        'gap logo . copy .'
        'gap socials socials socials socials'
        'gap selectors selectors selectors .';
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    #{$this}__logo {
      grid-area: logo;
    }
    #{$this}__copy {
      grid-area: copy;
      text-align: right;
    }
    #{$this}__socials {
      grid-area: socials;
    }
    #{$this}__selectors {
      grid-area: selectors;
      flex-direction: row;
    }
    #{$this}__gap {
      grid-area: gap;
    }
  }
  @media screen and (max-width: $breakpoint-sm){
    #{$this}__selectors {
      width: 60%;
      justify-content: space-between;
    }
  }
}


.sk-footer-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
  // svg {
  //   position: relative !important;
  //   top: -2px !important;
  //   // position: static !important;
  //   // transform: none !important;
  // }
  &_select ~ svg path,
  &_select ~ svg rect,
  &_select ~ svg circle {
    stroke: #D7B6FF;
  }
  &_select{
    &_title{
      background: transparent !important;
      width: 100% !important;
      padding-right: 0 !important;
    }
  }
  &_select[aria-pressed="true"]{
    & ~ svg{
      transform: rotate(180deg) !important;
      path,
      rect,
      circle{
        stroke: #ffffff;
      }
    }
    p{
       color: #ffffff !important;
     }
  }
  &_paper{
    background: transparent;
    ul{
      background: var(--purple-medium);
      padding: 0;
      box-sizing: border-box;
      .sk-footer-selector_item{
        padding: 0 6px !important;
      }
    }
  }
  &_item{
    background-color: transparent;
    @extend %p2;
    line-height: 20px;
    &:hover,
    &.selected{
      background-color: var(--purple-dark) !important;
      color: var(--color-white) !important;
    }
  }
}
