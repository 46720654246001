.sk-summary-duration-block {
  display: flex;
  width: 100px;
  &__icon {
    margin-right: 9px;
  }
  &__time {
    font-size: var(--text-fontSize-body);
    .paragraph-sk {
      font-size: var(--text-fontSize-body);
    }
  }
}
