@import 'utils/styles/_variables.scss';

.sk-expert-cabinet-courses {
  display: flex;
  flex-direction: column;

  &__heading {
    margin: 25px 0;

    > .sk-content-header {
      height: auto;
    }

    &__filters {
      display: flex;
      justify-content: space-between;
      .mui-override.sk-dropdown{
        z-index: 50;
      }

      &_search{
        max-width: 295px;
        width: 100%;
        position: relative;
        .mui-override.sk-materialized-input_left{
          width: calc(100% - 20px);
        }
        svg{
          position: absolute;
          right: 0;
          top: 30%;
        }
      }
    }
  }

  > .sk-link {
    width: 100%;
  }
  @media screen and (max-width: $breakpoint-sm) {
    &__heading {
      flex-direction: column;

      &__filters {
        margin-top: 25px;
        &_search{
          width: 60%;
          .mui-override.sk-materialized-input{
            margin-top: 0;
          }
          .mui-override.sk-materialized-input_left{
            margin-top: 0;
            padding-bottom: 10px;
          }
        }
      }
    }
  }
}
