@import 'utils/styles/text.scss';

.mui-override.sk-admin-plans-table {
  &__cell {
    border: none;
    padding: 0;
    margin: 0;
    height: 80px;
    &__elements {
      padding: 0;
      margin: 0;
      @extend %p3;
      font-weight: 500;
      color: var(--dark-text);
    }
    &_loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }

    &:first-of-type {
      padding-left: 60px;
    }
    &:first-of-type {
      padding-right: 60px;
    }
  }
}
