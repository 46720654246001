.sk-answerless-step {
  flex: 1 1 auto;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header { 
    margin-bottom: 26px;
  }
  &__btn {
    display: flex;
    justify-content: center;
  }
  &__text {
    display: inline-block;
    text-align: center;
  }
}