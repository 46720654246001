@import "../../../utils/styles/variables";
@import "../../../utils/styles/text";
@import "../../../utils/styles/color";

.sk-footer-nav-grid {
  width: 100%;
  &__menu {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  &__item {
    padding: 0 10px;
    width: 20%;
  }
  @media screen and (max-width: $breakpoint-md) {
    &__menu {
      flex-wrap: wrap;
    }
    &__item {
      width: 30%;
      margin-bottom: 48px;
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    &__item {
      width: 50%;
    }
  }
}

.sk-footer-nav-menu {
  &_title {
    @extend %p2;
    color: #fff;
    display: inherit;
    height: 44px;
  }
  &_link {
    @extend %p2;
    color: var(--purple-medium);
    cursor: pointer;
    width: 95%;
    text-decoration: none;
    line-height: 20px;
    margin-bottom: 14px;
    &:hover {
      color: #fff;
    }
    &_alt {
      color: var(--cyan-solid);
      font-weight: bold;
      font-size: 16px;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    &_title {
      min-height: 24px;
      height: auto;
      margin-bottom: 12px;
    }
    &_link {
      margin-bottom: 10px;
    }
  }
}
