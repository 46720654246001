@import 'utils/styles/_variables.scss';

.error-message {
  &-development {
    white-space: pre-wrap;
    padding: 20px;
    background: cornsilk;
    color: mediumorchid;
  }
}
