@import 'utils/styles/text.scss';

.sk-checkout-empty-list {
  height: 150px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  &__content {
    @extend %h3;
    color: var(--dark-stroke);
  }
}
