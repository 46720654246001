.sk-discount-type {
  background: #F5F6FC;
  border-radius: 10px;
  padding: 12px 23px 0;
  margin: 20px 0;
  display: flex;
  justify-content: space-between;

  &__input {
    width: 47.5%;
    div:first-child {
      top: -15px;
    }
  }
}
