.sk-step1 {
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
  }
  &__icon {
    margin-right: 10px;
  }
  &__action {
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: none;
  }
}
