@import 'utils/styles/_variables.scss';
@import 'utils/styles/text.scss';

.sk-quiz-score {
  display: flex;
  justify-content: space-between;

  & > p:first-child {
    @extend %p1;
    font-weight: 600;
  }
  & > p:last-child {
    @extend %h3;
  }
}
