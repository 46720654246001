@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-card-stripe {
  max-width: 668px !important;
  height: 349px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 36px 40px 34px;
  .sk-form-row__images{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 46px;
    img{
      display: inline-block;
      height: auto;
      &.visa_logo{
        width: 64px;
      }
      &.mastercard_logo{
        width: 107px;
      }
      &.discover_card_logo{
        width: 100px;
      }
      &.american_express_logo{
        width: 63px;
      }
    }
  }
  .sk-checkout-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    &__card-info {
      display: flex;
      justify-content: space-between;
    }
    &__button {
      align-self: center;
      width: 150px;
      height: 43px;
      border-radius: 25px;
      margin-top: 30px;
      text-align: center;
      @extend %p1;
      color: #FFFFFF;
    }
  }

  @media screen and (max-width: 650px) {
    height: 300px;
    .sk-form-row__images{
      margin-bottom: 10px;
      flex-wrap: wrap;
      img{
        margin-bottom: 5px;
        &.discover_card_logo{
          margin-left: 0;
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    padding: 33px 19px 40px;
  }
}
