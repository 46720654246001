.mui-override.sk-expert-analytics-students {
  width: calc(100% - 40px);
  margin: 30px 20px 0;

  &__cell {
    padding: 0 20px !important;
    &:first-child {
      padding-left: 5% !important;
    }
    &:last-child {
      padding-right: 5% !important;
    }
  }
}
