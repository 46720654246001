@import 'utils/styles/_variables.scss';

.sk-quiz {
  position: relative;
  margin-bottom: 133px;
  & > .sk-add-entity-btn {
    position: absolute;
    right: 68px;
    bottom: -50px;
  }

  &__save-btn {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0);
    bottom: -120px;
  }

  @media screen and (max-width: $breakpoint-md) {
    & > .sk-add-entity-btn {
      right: 40px;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    & > .sk-add-entity-btn {
      right: 30px;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    & > .sk-add-entity-btn {
      right: 16px;
    }
  }
}
