.sk-lec-mats-content {
  margin-bottom: 100px;
  overflow-y: scroll; // to fix scroll in safari
  &__main {
    margin-bottom: 42px;
  }
  &__footer {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
