@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-subscription-select-max-course {
  display: flex;
  margin-bottom: 45px;
  text-align: left;
  align-items: center;
  justify-content: space-between;
  height: 36px;

  > .paragraph-sk {
    @extend %p2;
  }

  &__counter {
    display: flex;
    > .sk-round-icon {
      &:first-of-type {
        margin-right: 45px;
      }
      &:last-of-type {
        margin-left: 45px;
      }
    }

    > .paragraph-sk {
      @extend %h3;
      align-self: center;
    }
  }

  @media screen and (min-width: $breakpoint-sm) {
    &__counter {
      &__labels {
        &:first-of-type {
          margin-left: auto;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: flex-start;
    &__counter {
      right: 0;
    }
    > .paragraph-sk {
      margin-bottom: 15px;
    }
  }
}

// .mui-override.sk-slider {
//   // margin-left: 40px;
//   top: 25px;
//   left: -8px;
//   max-width: 252px;
//   &__mark-label {
//     @extend %p2;
//     color: #610FB6;
//     margin-left: 4px;
//     margin-top: -50px;
//     &__active {
//       color: #30d5c8;
//     }
//   }
//   &__rail {
//     background: #E8E7FF;
//   }
//   &__track {
//     background: linear-gradient(88.59deg, #30d5c8 -5.88%, #55d5ca 105.42%, #3ad1c5 105.42%);
//   }
//   &__thumb {
//     width: 19px;
//     height: 19px;
//     border-radius: 9.5px;
//     margin-top: -9.5px;
//     background: linear-gradient(88.59deg, #30d5c8 -5.88%, #55d5ca 105.42%, #3ad1c5 105.42%);
//   }
//   &__mark {
//     background: #D7B6FF;
//     height: 8px;
//     width: 8px;
//     border-radius: 4px;
//     margin-top: -3px;
//     &__active {
//       background: linear-gradient(88.59deg, #30d5c8 -5.88%, #55d5ca 105.42%, #3ad1c5 105.42%);
//     }
//   }
// }
