.sk-change-success {
  &_header {
    padding-top: 10px;
    margin-bottom: 35px;
    text-align: center;
  }
  &_heading {
    line-height: 1.46;
    margin-bottom: 14px;
    text-align: center;
  }
  &_subheading {
    line-height: 1.5;
  }
  &_footer {
    display: flex;
    justify-content: center;
  }
  &_icon {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
}

.sk-pw-change-card {
  padding: 45px 65px;
  margin: auto;
  max-width: 400px;
}