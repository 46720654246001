@import 'utils/styles/text.scss';

.sk-checkout-menu-item {
  position: relative;
  display: flex;
  justify-content: space-between;
  border-radius: 4px;
  align-items: flex-start;
  border-bottom: 1px dashed var(--medium-stroke);
  padding: 30px 0 25px;

  &:first-child {
    padding: 0 0 25px;
    > .sk-modal__close-btn {
      top: 0;
    }
  }
  &.mui-override {
    min-height: 52px;
    height: 100%;
  }

  > .sk-image {
    height: 63px;
    width: 125px;
    margin-right: 9px;
    border-radius: 2px;
    position: relative;
    top: 4px;
  }
  &__details {
    display: flex;
    max-width: 150px;
    margin-right: 18px;
    flex: 1 1 auto;
    flex-direction: column;
    justify-content: space-between;
    > .paragraph-sk {
      @extend %p2;
      line-height: 20px;
    }
    > .paragraph-sk:first-child {
      color: var(--purple-dark);
      font-weight: 500;
      margin-bottom: 7px;
    }
  }

  > button {
    > svg {
      position: relative;
      right: 4px;
    }
  }
}
