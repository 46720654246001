$breakpoint-lg: 1366px;
$breakpoint-md: 1024px;
$breakpoint-sm: 767px;
$breakpoint-xs: 480px;

/* z-index */
$z-index-modal: 200;
$z-index-header: 101;
$z-index-footer: 98;
$z-index-sidebar: 50;
$z-index-banner: 800;
$z-index-chip-delete: 1500;
$z-index-drop-down: 10;
// header checkout-menu, z-index: 100
