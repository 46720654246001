@import "../../../utils/styles/variables";

.sk-filters {
  display: flex;
  justify-content: space-between;
  position: relative;
  @media screen and (min-width: 1023px) {
    display: grid;
    grid-template-columns: 1fr 4fr;
    grid-gap: 2rem;
    margin-top: 48px;
  }

  @media screen and (min-width: 1023px) {
    display: flex;
    justify-content: space-between;
  }

  @media screen and (min-width: $breakpoint-xs) {
  }

  .sk-filters-mobileFilter {
    svg {
      height: 22px;
      path {
        stroke: var(--color-primary-1);
      }
    }
  }

  .mui-override.sk-filter-select_menu__major {
    font-size: var(--text-fontSize-body);
    @media screen and (min-width: 1023px) {
      font-size: initial;
      margin: 1em 0;
    }
  }

  &__mobile-tablet {
    display: none;
    @media screen and (max-width: 1023px) {
      display: block;
    }

    button {
      display: flex;
    }

    align-self: center;
    color: var(--color-primary-1);
    height: 43px;

    &__text {
      margin-left: 0.5rem;
      margin-right: 0.5rem;
      font-size: var(--text-fontSize-body);
      vertical-align: super;
      color: var(--color-primary-1);
    }
  }

  &__main {
    justify-self: end;


    @media screen and (min-width: 1024px) {
      &__search {
        position:  absolute;
        right: 0;
        top: -31px;
      }
    }

    .sk-filter-group {
      display: flex;
      align-self: center;
      &_item:last-child{
        padding: 1em 0;
      }
    }

    &__additional {
      display: block;

      @media screen and (max-width: 1023px) {
        display: none;
      }

      &>div {
        margin-right: 12px;
        width: auto;
      }
    }

    &__additiona2 {
      display: flex;
      flex-direction: column;
      &.__filterBlock {
        margin: 25px auto;
        max-width: 300px;
        width: 100%;
      }
      .sk-filters__main__search {
        margin-bottom: 45px;
        display: block;
        .sk-search {
          max-width: 100%;
          display: flex;
          align-items: center;
          flex-direction: column;
          button {
            margin-top: 35px;
          }
        }
      }

    }

    &__search {
      display: block;

      @media screen and (max-width: 1023px) {
        display: none;
      }
    }
  }
}
