@charset "UTF-8";
/* z-index */
/* headers */
/* paragraphs */
.mui-override.sk-lec-element-component__header > span:nth-child(1), .mui-override.sk-lec-element-component__header > span:nth-child(2), .mui-override.sk-lec-element-component__duration, .mui-override.sk-lec-element-component > span {
  font-family: Roboto, Ubuntu, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: var(--base-text);
  font-weight: normal;
  font-style: normal; }

.mui-override.sk-lec-element-component__header > span:nth-child(1) {
  font-weight: bold; }

.mui-override.sk-lc-сurriculum-panel__summary.has-public .sk-lec-element-component__header > span:nth-child(2) {
  color: var(--purple-dark); }

.mui-override.sk-lec-element-component {
  display: flex;
  width: 100%;
  justify-content: space-between; }
  .mui-override.sk-lec-element-component__header {
    display: flex; }
    .mui-override.sk-lec-element-component__header__title {
      min-width: 85px;
      display: block;
      float: left;
      margin-right: 8px; }
    .mui-override.sk-lec-element-component__header > span:nth-child(1) {
      color: var(--dark-text); }
  .mui-override.sk-lec-element-component__duration {
    margin-left: 20px;
    margin-right: 50px;
    min-width: 80px;
    display: flex;
    align-items: center; }
  @media screen and (max-width: 480px) {
    .mui-override.sk-lec-element-component {
      flex-direction: column; }
      .mui-override.sk-lec-element-component__header {
        justify-content: space-between;
        align-items: center; }
      .mui-override.sk-lec-element-component__duration {
        margin-right: 30px; } }
