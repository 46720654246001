@import 'utils/styles/color.scss';
@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';
@import 'utils/styles/functions.scss';

.sk-student-toppings_card{
  width: 100%;
  display: grid;
  align-items: center;
  background: #FFFFFF;
  border: 1px solid #F1F6FC;
  box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.15);
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 15px 25px;
  transition: box-shadow .3s;
  grid-template-areas: 'picture title . type status';
  grid-template-columns: 1fr 3fr repeat(2, 1fr) 0fr;

  &__col{
    display: flex;
    align-items: center;
  }

  &__picture{
    width: 105px;
    height: 62px;
    overflow: hidden;
    border-radius: 5px;
    grid-area: picture;
    background: #fff;
    img{
      min-height: 70px;
    }
  }

  &__title{
    @extend %p1-medium;
    // Commented to align the center
    // margin-bottom: 0;
    // margin-top: 20px;
    line-height: 1.5;
    letter-spacing: 0.3px;
    transition: color .3s;
    display: inline-block;
    margin-left: 16px;
    grid-area: title;
  }

  &__type{
    grid-area: type;
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__type_label {
    margin: 0;
    margin-left: 10px;
    @extend %p2;
  }

  &__status{
    grid-area: status;
    display: flex;
    justify-content: flex-end;
    width: 100%;
    min-width: 176px;
  }

  &__status_label {
    @extend %p2-bold;
    color: var(--dark-text);
    padding: 8px 20px;
    background: #E8E7FF;
    border-radius: 25.5px;
    font-weight: 500;
    line-height: 18px;
    text-align: center;
    margin: 0;
    min-width: 150px;
    max-width: 90%;

    @media screen and (max-width: $breakpoint-sm){
      width: 150px;
    }
  }

  &:hover {
    box-shadow: 0px 40px 120px rgba(103, 151, 255, 0.4);
    &__title {
      color: var(--purple-solid);
    }
  }
  @media screen and (max-width: $breakpoint-md){
    grid-template-areas: 'picture title title . type'
                          'picture title title . status';
    grid-template-columns: 2fr 3fr 1fr 1fr;
    &.student{
      grid-template-areas: 'picture title . type';
      grid-template-columns: 2fr 3fr 1fr 1fr;
    }

    &__title{
      margin-left: 16px;
    }

    &__type{
      justify-content: flex-end;
    }
  }
  @media screen and (max-width: $breakpoint-sm){
    grid-template-areas: 'picture'
                          'title'
                          'type';
    grid-template-columns: 1fr;
    position: relative;
    padding: 20px;
    &.student{
      grid-template-areas: 'picture'
                            'title'
                            'type';
      grid-template-columns: 1fr;
    }
    &__picture{
      width: 100%;
      height: 100%;
      margin: 0 auto 12px;
    }
    &__title {
      margin-left: 0;
      margin-right: 0;
    }
    &__status{
      position: absolute;
      top: 8px;
      right: 8px;
    }
    &__type{
      justify-content: flex-start;
    }
  }
}
