.sk-c-nearest-webinar-date {
  &__row {
    display: flex;
    &:not(:last-of-type) {
      margin-bottom: 14px;
    }
  }
  &__icon {
    display: flex;
    width: 20px;
    margin-right: 12px;
  }
}

.sk-c-nearest-webinar-content {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
  &__header {
    margin-bottom: 13px;
  }
}

.sk-c-nearest-webinar {
  &__note {
    margin-top: 16px;
    font-style: italic;
  }
  &__asterix {
    color: var(--color-secondary-1);
  }
  &__title {
    display: inline-flex;
    margin-bottom: 3px;
    color: var( --color-grey1);
  }
  &__row {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}