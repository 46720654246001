.sk-checkout-menu-list {
  max-height: 225px;
  overflow-y: overlay;
  width: calc(100%+4px);
  &::-webkit-scrollbar {
    width: 4px;
    height: 100%;
  }
  &::-webkit-scrollbar-thumb {
    width: 4px;
    background: var(--purple-light);
    border-radius: 20px;
  }
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 5px;
  }
}
