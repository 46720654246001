.sk-add-entity-btn {
  &__text {
    color: var( --color-secondary-3);
  }
  &__plus {
    display: inline-flex;
    align-items: center;
    margin-right: 8px;
  }
 
}