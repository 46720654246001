@import "../../../../utils/styles/variables";

$backgroundColor: #E8E7FF;

.sk-input-custom {
  &-search {
    &__wrapper {
      display: flex;
      flex: 1;
      position: relative;
      height: 41px;
      justify-content: space-between;
      align-items: center;
      border: 0;
      padding: 0 15px;
      background: transparentize($backgroundColor, 0.8);
      border-radius: 8px;
      margin: 0 10px;
      @media (max-width: $breakpoint-sm) {
        padding: 8px;
      }
      @media (min-width: $breakpoint-sm) {
        // font-size: var(--text-fontSize-body);
        height: 42px;
      }
      // @media (max-width: 550px) {
      //   background: transparent;
      //   justify-content: flex-end;
      //   padding: 0;
      // }
    }

    // &__mobile-wrapper {
    //   display: flex;
    //   position: fixed;
    //   top: 0;
    //   left: 0;
    //   z-index: 300;
    //   width: 100%;
    //   height: 77px;
    //   justify-content: space-between;
    //   align-items: center;
    //   padding: 16px;
    //   background: var(--color-primary-3);
    //   font-size: var(--text-fontSize-body);
    //   .sk-input-custom-search__icon {
    //     position: absolute;
    //     right: 40px;
    //   }
    //   @media (min-width: 550px) {
    //     display: none;
    //   }
    // }
    &__field {
      width: 100%;
      background: transparent;
      outline: none;
      border: none;
      color: var(--color-white);
      &::placeholder {
        color: var(--purple-medium);
        font-weight: normal;
        font-size: 16px;
      }
      font-size: var(--text-fontSize-xsmall);
      @media (min-width: $breakpoint-sm) {
        font-size: 16px;
      }
      // @media (max-width: 550px) {
      //   display: none;
      // }
    }
    // &__mobile-field {
    //   display: none;
    //   border: none;
    //   outline: none;
    //   width: 100%;
    //   background: var(--color-primary-4);
    //   padding: 14px;
    //   border-radius: 8px;
    //   color: var(--color-white);
    //   line-height: normal;
    //   &::placeholder {
    //     color: var(--color-primary-6);
    //   }
    //   font-size: var(--text-fontSize-xsmall);
    //   @media (max-width: 550px) {
    //     display: flex;
    //   }
    // }
    &__icon {
      @media (max-width: 550px) {
        svg {
          width: 18px;
          height: 18px;
          margin-top: 4px;
        }
      }
    }
    &__cross-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 22px;
      width: 22px;
      position: absolute;
      right: 9px;
      top: 7px;
      background-color: var(--color-primary-6);
      color: var(--color-white);
      border-radius: 50%;
    }
  }
}
