@import 'utils/styles/text.scss';

.mui-override.sk-experts-cabinet-confirm-modal-close {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__text:first-child {
    @extend %h3;
  }
  &__text:nth-child(2) {
    @extend %p1;
  }
  &__buttons {
    &__button:last-child {
      margin-left: 10px;
    }
  }
}
