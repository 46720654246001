.sk-file-uploader {
  position: relative;

  &__label {
    position: relative;
    display: inline-flex;
    align-items: center;
    width: 100%;
    border: none;
    outline: none;
    background: transparent;
    padding: 0;
    cursor: pointer;
    top: -2px;
    &_absolute {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      justify-content: center;
    }
  }

  &__hidden-input {
    visibility: hidden;
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: -1;
  }
}
