@import '../../../../utils/styles/variables';

.sk-course-general-info {
  position: relative;
  padding: 53px 0px 48px;
  @media screen and (max-width: $breakpoint-md) {
    padding-left: 8px;
    padding-right: 8px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    padding-left: 18px;
    padding-right: 18px;
  }

  &__inner {
    display: flex;
    @media screen and (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
    }
  }
  &__form {
    padding-right: 40px;
    flex: 1;
    @media screen and (max-width: $breakpoint-sm) {
      flex-basis: 100%;
      padding-right: 0;
    }
  }
  &__status {
    position: relative;
    flex: 0 0 260px;
    margin: 10px 0;
    padding-left: 31px;
    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: '';
      display: block;
      width: 1px;
      height: 100%;
      border-left: 1px solid #eee;
      margin: 6px 0;

      @media screen and (max-width: $breakpoint-sm) {
        border: none;
      }
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex-basis: 100%;
      padding-left: 0;
    }
  }
  &__action-panel {
    position: absolute;
    right: 73px;
    top: -18px;
  }
}
