@import 'utils/styles/_variables.scss';

.sk-manage-account-sidebar {
  padding: 13px 16px 25px;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: auto;
  height: 100%;
  &_footer {
    display: flex;
    flex-shrink: 0;
    flex-basis: auto;
    padding: 16px 0;
  }
  &_btn {
    display: flex;
    align-items: center;
  }
}
.sk-sidebar-block {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  flex-basis: auto;
  &__item {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    flex-basis: auto;
  }
  &_header {
    display: flex;
    align-items: center;
    flex-shrink: 0;
    flex-basis: auto;
    margin-bottom: 14px;
  }
  &_icon {
    margin-left: 10px;
  }
}
