.sk-video-module {
  position: relative;
  &.landing-page-video{
    border-radius: 10px;
    overflow: hidden;
  }
  &.audio{
    margin-bottom: 35px;
    .react-player{
      display: none;
    }
    .sk-video-module-controls{
      position: static;
      border-radius: inherit;
    }
  }
  .react-player{
    border-radius: inherit;
  }
}
