.mui-override.sk-checkbox {
  padding: 0;
  margin: 0;
  &__checked {
    &.checked {
      color: var(--cyan-solid);
    }
  }
  &-label {
    margin-right: 10px;
    &_top {
      margin-top: 2.5px;
    }
  }
  &_top {
    align-items: flex-start;
  }
}
