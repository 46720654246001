@import 'utils/styles/_variables.scss';

.mui-override.sk-mobile-sidebar {
  position: absolute;
  z-index: $z-index-sidebar;
  overflow: auto;
  &__paper {
    width: 297px;
    overflow: auto;
    height: 100%;
    background: var(--color-grey8);
  }
  &__backDrop {
    position: absolute;
  }
}
