.sk-course-title-edit {
  display: flex;
  align-items: center;

  &__field {
    font-size: var(--text-fontSize-h1);
    color: var(--color-white);
    border-bottom: 1px solid var(--color-white);
    margin-right: 15px;
    line-height: 1.2;
    text-align: center;
    font-weight: 600;
    word-break: break-word;
    &:focus {
      outline: none;
    }
    &.disabled {
      border: none;
    }
    @media screen and (max-width: 1023px)  {
      font-size: var(--text-fontSize-h2);
    }
    @media screen and (max-width: 700px)  {
      font-size: var(--text-fontSize-h3);
    }
    @media screen and (max-width: 400px)  {
      font-size: var(--text-fontSize-h5);
    }
  }
  &__btn {
    padding-top: 10px;
  }
}
