.mui-override.sk-usersTable {
  &__cell {
    padding: 2px 10px;
    border-bottom: none;
    &__elements {
      display: flex;
      align-items: baseline;
      &__arrow {
        visibility: hidden;
        padding-left: 5px;
      }
      &:hover &__arrow {
        visibility: initial;
      }
      &:hover p {
        cursor: pointer;
      }
    }
    &:first-child {
      border-bottom: none;
      padding: 2px 15px 0 45px;
    }
    &:last-child {
      padding: 2px 45px 0 10px;
      border-bottom: none;
    }
    &_loader {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%)
    }
  }
}

.mui-override.show {
  visibility: initial;
}
.mui-override.rotate {
  transform: rotate(180deg);
  padding-left: 0;
  padding-right: 5px;
}
