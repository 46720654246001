@import "../../../../utils/styles/variables";
@import 'utils/styles/_variables.scss';

.sk-course-carousel {
  position: relative;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  .slider-dots {
   display: none;
  }
  &__monocard {
    display: flex;
    width: 100%;
    justify-content: center;
    padding: 0px 0px 36px;
  }
  &__swipe {
    position: relative;
    &-item {
      .sk-course-card{
        width: 100%;
      }
      display: flex;
      justify-content: center;
      flex-basis: calc(25% - 20px);
    }
    &-row.grid.landing {
      display: flex;
      height: 590px;
      margin-bottom: 0px;
      padding: 0 9vw;

      justify-content: space-between;
    }
    &-item {
      &:only-child {
        width: 100%;
      }
    }
  }
  &__nav-btn {
    position: absolute;
    top: calc(50% - 67px);
    transform: translateY(-50%);

    &_prev {
      left: 5%;
      right: auto;
    }
    &_next {
      right: 5%;
      left: auto;
    }
  }

  @media screen and (max-width: 1366px) {
    margin: auto;
    &__monocard {
      justify-content: center;
    }
    &__swipe {
      &-row {
        justify-content: center;
      }
    &-row.grid.landing {
      padding: 0 6vw;


    }
      &-item {
        .sk-course-card{
          width: 100%;
        }
        display: flex;
        justify-content: center;
        flex-basis: calc(33% - 20px);
      }
      &:only-child {
        width: 100%;
      }
    }
    &__nav-btn {
      &_prev {
        left: 2.5%;
      }
      &_next {
        right: 2.5%;
      }
    }
  }

  @media screen and (max-width: 1023px) {
    &__swipe {
      &-item {
        .sk-course-card{
          width: 100%;
        }
        display: flex;
        justify-content: center;
        flex-basis: calc(50% - 20px);
      }
    }
    &__nav-btn {
      &_prev {
        left: 1.5%;
      }
      &_next {
        right: 1.5%;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    &__swipe {
       &-item {
        padding: 30px;
        margin-bottom: 20px;
       
        display: flex;
        justify-content: center;
        flex-basis: 100%;
      }
    }
    .slider-dots{
      display: flex;
      margin-top: 35px;
      margin-bottom: -5px;
    }
    &__nav-btn {
      display: none;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    &__swipe {
      &-item {
        width: 100%;
        padding: 20px;
        display: flex;
        justify-content: center;
        flex-basis: 100%;
      }
      &-row {
        height: auto;
        margin-top: 25px;
        margin-bottom: 25px;
      }
    }
    .slider-dots{
      display: flex;
      margin-top: 40px;
      margin-bottom: -10px;
    }
  }
}
