.sk-content {
  position: relative;
  flex: 1;
  display: flex;
  padding-top: 77px;
  min-height: 100vh;
  &_main {
    max-width: 100vw;
    display: flex;
    flex: 1;
    justify-content: center;
    padding-bottom: 32px;
    &__inner {
      display: flex;
      flex-direction: column;
    }
  }
}
