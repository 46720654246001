@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-plan-form {
  width: 48%;
  &__label {
    display: inline-block;
    @extend %p3;
    color: #9B9B9B;
    padding-bottom: 6px;
  }
  &__input {
    &__empty {
    }
  }
}

.sk-plan {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 360px;
  &__title {
    @extend %p1;
    font-weight: 500;
    margin-right: auto;
    padding-bottom: 15px;
  }
  .features {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .feature {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0;
    p {
      padding-left: 5px;
    }
  }
  .price {
    font-size: 2rem;
    font-weight: 500;
    margin-right: auto;
    padding-bottom: 15px;
    span {
      font-size: 1rem
    }
  }
  &__info {
    display: flex;
    margin-bottom: 32px;
    width: 100%;
    &__card {
      display: flex;
      width: 40%;
    }
    &__stripe {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__paymentInfo {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 28px;
      }
      &__or {
        @extend %h3;
        color: #0A0A0A;
        padding: 40px 0 25px;
      }
      &__paypal {
        height: 43px;
        width: 143px;
        min-width: 143px;
      }
    }
  }
  @media screen and (max-width: $breakpoint-lg) {
    margin: 0 auto;
    width: 668px;
  }
  @media screen and (min-width: 1000px) {
    &__info {
      &__stripe {
        width: 540px !important;
      }
    }
  }
  @media screen and (max-width: 1000px) and (min-width: 650px) {
    &__info {
      &__stripe {
        width: 450px !important;
      }
    }
  }
  @media screen and (max-width: 900px) {
    &__info {
      &__card {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 650px) {
    &__info {
      flex-direction: column;
      &__card {
        width: 100%;
      }
      &__stripe {
        position: relative;
        top: 30px;
        left: -20px;
      }
    }
  }
}

.sk-card-plan {
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 36px 40px 34px;
  .sk-form-row__images{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin: 0 auto 46px;
    img{
      display: inline-block;
      height: auto;
      &.visa_logo{
        width: 64px;
      }
      &.mastercard_logo{
        width: 107px;
      }
      &.discover_card_logo{
        width: 100px;
      }
      &.american_express_logo{
        width: 63px;
      }
    }
  }
  .sk-checkout-form {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin: 0 auto;
    &__card-info {
      display: flex;
      justify-content: space-between;
    }
    &__button {
      align-self: center;
      width: 150px;
      height: 43px;
      border-radius: 25px;
      margin-top: 30px;
      text-align: center;
      @extend %p1;
      color: #FFFFFF;
    }
  }

  @media screen and (max-width: 650px) {
    height: 300px;
    .sk-form-row__images{
      margin-bottom: 10px;
      flex-wrap: wrap;
      img{
        margin-bottom: 5px;
        &.discover_card_logo{
          margin-left: 0;
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    padding: 33px 19px 40px;
  }
}
