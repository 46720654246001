.sk-video-preview {
  cursor: pointer;

  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    background: var(--color-secondary-1);
    cursor: pointer;
    &:hover {
      background: var(--color-white);
    }
  }
  &__view {
    width: 100%;
    padding-top: 24px;
  }
}

.sk-text-preview {
  white-space: pre-wrap;
  word-wrap: break-word;
}

.sk-pdf-preview {
  &__document {
    margin-top: 20px;
    &__page {
      width: 100%;
      height: auto;
      & > canvas, > div {
        width: inherit !important;
        height: inherit !important;
      }
      & > div:nth-child(2) {
        display: none;
      }
    }
  }
}
