@import 'utils/styles/text.scss';
.sk-stripe {
  width: 48%;
  &__label {
    display: inline-block;
    @extend %p3;
    color: #9B9B9B;
    padding-bottom: 6px;
  }
  &__input {
    &__empty {
    }
  }
}
