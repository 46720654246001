@import 'utils/styles/_variables.scss';
@import 'utils/styles/color.scss';

%base {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 50px;
  height: 70%;
  border: 0;
  outline: 0;
  padding: 0;
  z-index: 4;
  display: flex;
  justify-content: center;
  align-items: center;
  &.opened{
    top: 30px;
  }
  button{
    width: 30px;
    height: 30px;
  }
}

.slider-button-detail {
  @extend %base;
}

.slider-button-next {
  @extend %base;
  right: 15px;
  button{
    margin-left: -15px;
  }
}

.slider-button-prev {
  @extend %base;
  left: 15px;
}

@media screen and (max-width: $breakpoint-sm){
  .slider-button-next,
  .slider-button-prev {
    top: 24px !important;
    background: transparent;
    width: 30px;
    height: 30px;
    button{
      width: 100%;
      height: 100%;
      margin-left: 0;
    }
  }

  .slider-button-prev {
    left: 48%;
    transform: translateX(-30px);
  }
  .slider-button-next {
    right: 48%;
    transform: translateX(30px);
  }
}
