@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-topping-edit-details {
  &__variant {
    display: flex;
    align-items: flex-end;
    > button {
      margin-left: 45px;
    }
  }
  &__language{
    margin-top: 18px;
  }
  @media (max-width: $breakpoint-xs) {
    &__variant {
      flex-direction: column;
      align-items: initial;
      > button {
        margin-left: 0;
        margin-top: 25px;
        width: 100%;
      }
    }
  }
}
