.mui-override.sk-stepper {
  padding: 0;
  width: fit-content;
  position: relative;
  &__step {
    padding: 0;
    margin-right: 38px;
    &:last-child{
      margin-right: 0;
    }
  }
  &__btn {
    padding: 0;
    margin: 0;
    span {
      border-radius: 50%;
    }
  }
  &__iconContainer {
    width: 35px;
    height: 35px;
    padding-right: 0;
    z-index: 10;
  }
  &__connector {
    border-color: var(--color-grey3);
  }
}

.sk-stepper {
  &::before {
    width: 100%;
    height: 1px;
    content: "";
    display: table;
    background-color: #E8E7FF;
    position: absolute;
    left: 0;
    right: 0;
    top: 50%;
  }
}