.sk-exp-cab-homeworks-table {
  padding: 36px 0px;
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 24px;
  }
  &__title {
    color: var(--color-black);
    margin-right: 8px;
  }
  &__counter {
    color: var(--color-charcoal);
    font-weight: 300;
    letter-spacing: 0.4px;
  }
  &__heading {
    text-transform: uppercase;
    color: var(--color-black);
    letter-spacing: 1px;
  }
  &__heading-group {
    margin-bottom: 27px;
  }
}