.editor-icon-svg {
  overflow: visible;
  & > .default {
    display: initial;
  }
  & > .active {
    display: initial;
  }
  & > .hover {
    display: none;
  }
  &:hover {
    & > .default {
      display: none;
    }
    & > .active {
      display: none;
    }
    & > .hover {
      border-radius: 2px;
      background: #FFFFFF;
      box-shadow: 0px 5px 10px rgba(138, 130, 165, 0.15);
      display: initial;
    }
  }
}

.sk-editor-fullScreen-icon{
  cursor: pointer;
  &:hover path {
    stroke: #7145D8;
    fill: #7145D8;
  }
}
