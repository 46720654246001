@import 'utils/styles/_variables.scss';

.sk-social-share {
    position: relative;
    &-wrapper {
        position: absolute;
        right: -86px;
        @media screen and (max-width: 1023px) {
            left: 0;
            top: auto;
            bottom: auto;
        }
    }
    svg * {
        transition: all 100ms ease-in-out;
    }

    &-popover {
        &-content {
            top: 60px;
            align-items: center;
            justify-content: center;
            margin: auto;
            background: white;
            overflow: visible;
            outline: none;
            height: auto;
            min-width: 332px;
            width: 100%;
            padding: 25px 40px;
        }

        &__close-btn {
            position: absolute;
            z-index: $z-index-modal;
            background: transparent;
            top: 18px;
            right: 18px;
            border: none;
            width: auto;
            padding: 0;
            margin: 0;

        }
    }
    &:hover{
      .share-circle rect{
        fill: url(#paint_fill_linear);
      }
    }
}
