@import 'utils/styles/text.scss';

.sk-admin-plans {
  .sk-content-header__breadcrumbs {
    .sk-link {
      @extend %p2;
      font-weight: 500;
    }
  }

  &__navigation {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .mui-override.sk-tabs {
      height: 0;
      box-shadow: none;
      margin: 20px 0 25px;
      width: auto;
    }

    .mui-override.sk-tabs-tab {
      height: 0;
      width: auto;
      &:first-child {
        margin-right: 18px;
      }
    }
  }
}
