.sk-terms-of-use {
  padding-top: 54px;
  display: flex;
  justify-content: center;
  &__wrapper {
    max-width: 1366px;
    width: 80.2%;
    align-self: stretch;
    display: flex;
  }
  &__card {
    padding: 32px;
  }
}