@import "../../../../utils/styles/variables";

.mui-override.sk-materialized-select {
  display: flex;
  align-items: center;
  min-height: 40px;
  padding: 0;
  font-weight: 600;

  &:focus,
  &:hover {
    background: transparent;
  }

  &_minor {
    &__empty {
      @media screen and (min-width: $breakpoint-md) {
        padding-right: 25px;
      }
      color: var(--color-secondary-1);
    }
  }
  &_major {
    &__empty {
      color: var(--color-grey3);
    }
  }

  &-item {
    padding: 6px 10px;
    font-size: 14px;
    &:hover,
    &.selected {
      background: var(--list-item-hover-bg);
      color: var(--list-item-hover-text);
    }
  }

  &.sk-materialized-select-item_hidden {
    display: none;
  }
}
