.sk-completedCoursesSidebar {
  display: flex;
  align-items: center;
  flex: 1;
  padding: 7px 11px;
  height: 38px;
  background: var(--color-grey10);
  transition: all var(--transition-time-casual) ease-in-out;
  &:hover,
  &.active {
    background: var(--color-white);
    color: var(--list-item-hover-text);
  }
  span {
    font-size: var(--text-fontSize-caption);
  }
}