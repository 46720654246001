@import "utils/styles/_variables.scss";

%flex {
  display: flex;
}

%flex-hor-center {
  @extend %flex;
  flex-flow: row wrap;
  justify-content: center;
}

.mui-override.sk-modal {
  &-overlay {
    display: flex;
    position: fixed;
    z-index: $z-index-modal;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: auto;
  }

  &-content {
    align-items: center;
    justify-content: center;
    margin: auto;
    background: white;
    overflow: visible;
    outline: none;
    height: auto;
    width: 95%;
    // Align modal in center
    // margin-top: calc(77px + 6vh);
    // bottom: 3vh;
    padding: 25px !important;
    &_block {
      margin-bottom: 7px;
      @extend %flex-hor-center;
    }
    &_text {
      text-align: center;
      line-height: 26px;
    }
    &__sm {
      max-width: 351px;
    }
    &__md {
      max-width: 505px;
      padding: 14px !important;
    }
    &__lg {
      max-width: 788px;
    }
    &__xlg {
      max-width: 1015px;
      padding: 42px 45px;
    }
  }
}

.sk-modal__close-btn {
  position: absolute;
  z-index: $z-index-modal;
  background: transparent;
  top: 18px;
  right: 18px;
  border: none;
  width: auto;
  padding: 0;
  margin: 0;

  &__disabled {
    visibility: hidden;
  }
}
