@import 'utils/styles/text.scss';

.sk-shopping-cart-notification {
  width: 326px;
  height: 83px;
  right: -20px;
  top: 15px;
  position: fixed;
  background: #30d5c8;
  // background: linear-gradient(83.66deg, #30d5c8 -5.88%, #55d5ca 105.42%, #3ad1c5 105.42%);
  border-radius: 5px;
  box-shadow: 0px 10px 20px rgba(228, 84, 87, 0.38);
  box-sizing: border-box;

  > .paragraph-sk {
    width: 100%;
    height: 100%;
    @extend %p1;
    color: var(--white-text);
    display: flex;
    justify-content: center;
    align-items: center;
  }

  & > svg {
    position: absolute;
    top: -30px;
    right: 0;
    z-index: -1;
  }
}
