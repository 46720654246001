.sk-pc-details {
  width: 100%;
  display: flex;
  border-bottom-right-radius: 8.8px;
  border-bottom-left-radius: 8.8px;

  &__homework {
    width: 40%;
    border-left: 2px solid var(--color-grey2);
  }

  &__article {
    width: 60%;

    &.__no-homework {
      width: 100%;
    }

    &__desc {
      margin: 20px 10px 20px 0px;
      padding-left: 60px;

      &__collaps {
        padding-right: 50px;
        margin-bottom: 10px;
      }

      .paragraph-sk {
        margin-bottom: 5px;
        font-weight: 700;
        font-size: var(--text-fontSize-body);
      }

      &-button {
        color: var(--color-secondary-1);
        padding-left: 60px;
        margin-bottom: 20px;
      }
    }

    &__item {
      margin-bottom: 40px;

      p {
        padding-left: 60px;
        margin-bottom: 10px;
      }

      .paragraph-sk {
        text-decoration: none;
        text-decoration-line: none;
      }
    }
  }

  @media screen and (max-width: 900px) {
    flex-direction: column;
      &__homework {
        width: 100%;
        border-left: none;
      }
      &__article {
        width: 100%;
      }
  }

  @media screen and (max-width: 480px){
    &__article {
      &__item {
        p {
          padding-left: 30px;
          margin-bottom: 10px;
        }
      }
    }
  }
}

.sk-pc-details-button {
  span {
    margin: 0;
  }
}

.sk-subtitle{
  margin-bottom: 5px;
  font-weight: 700;
  font-size: var(--text-fontSize-body);
}
