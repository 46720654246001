.sk-upload-controller {
  display: flex;
  .sk-doc-preview{
    + .sk-tooltip-trigger {
      transform: translate(28px, 0);
    }
  }
  .sk-tooltip-trigger {
    transform: translate(-5px, 0);
  }
  
  > button {
    &:first-child {
      > svg {
        margin-left: 0;
      }
    }
    > svg {
      margin-left: 34px;
    }
    &__info {
      margin-left: 10px;
    }
  }
}

.disabled {
  opacity: 0.5;
}
