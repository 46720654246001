@import "utils/styles/text.scss";

.sk-buy-subscription-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  > .paragraph-sk {
    @extend %h3;
    text-align: center;
    margin-bottom: 40px;
    padding: 0 24px;
  }
  &__actions {
    display: flex;
    justify-content: center;
    > button:first-child {
      margin-right: 30px;
    }
  }
}
