.slider-dots {
    display: none;
    margin-top: 0px;
    margin-bottom: 30px;
    justify-content: center;
    align-items: center;
    width: 100%;
    z-index: 10;
    .slider_dot{
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 50%;
      transition: all .3s;
      box-shadow: 0px 20px 40px rgba(67, 0, 104, 0.1) !important;
      border: 1px solid #30d5c8 !important;
      cursor: pointer;
      &:not(:first-child),
      &:not(:last-child){
        margin: 0 3px;
      }
      &:hover,
      &.active{
        background: linear-gradient(90deg, #30d5c8 0%, #3ad1c5 100%);
        box-shadow: 0px 20px 40px rgba(67, 0, 104, 0.1);
        transform: scale(1.5);
      }
    }
}
