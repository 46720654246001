.sk-course-rec-block {
  &__header {
    margin-bottom: 14px;
  }
  &__content {
    padding: 30px 0 43px;
  }

  &__card {
    box-shadow: 0 16px 48px var(--box-shadow-color-1);
  }
}
