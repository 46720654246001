@import 'utils/styles/text.scss';
@import 'utils/styles/color.scss';

$prefix: sk-toppings-carousel_content;

.#{$prefix}_author{
  width: 100%;
  &__top{
    display: flex;
    margin-bottom: 15px;
  }
  &__photo{
    width: 60px;
    height: 60px;
    .sk-avatar-user_wrapper{
      width: 100%;
      height: 100%;
    }
  }
  &__name{
    margin-left: 33px;
    &_string{
      @extend %p1;
      color: #616161;
      margin: 0;
      letter-spacing: 0.2931px;
    }
    &_position{
      @extend %p2;
      font-weight: 300;
      letter-spacing: 0.223333px;
      color: #ACACAC;
      margin: 0;
    }
  }

}
