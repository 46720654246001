:root {
  /* primary colors */
  --color-primary-1: #7145d8;
  --color-primary-2: #6033c7;
  --color-primary-3: #784edc;
  --color-primary-4: #663acc;
  --color-primary-5: #5525c6;
  --color-primary-6: #9576de;
  --color-primary-7: #ae00fc;
  --color-primary-8: #c3aff2;
  --color-primary-9: #8fa6eb;
  --color-primary-10: #8fa6eb;
  --color-primary-11: #493e78;
  --color-primary-12: #8fa6eb;
  --color-primary-13: #e9e6f0;
  --color-primary-14: #f6f2ff;

  --color-box-shadow-primary: rgba(211, 194, 251, 0.56);

  /* secondary colors */
  --color-secondary-1: #30d5c8;
  --color-secondary-2: #30d5c8;
  --color-secondary-3: #30d5c8;
  --color-box-shadow-secondary: rgba(253, 195, 184, 0.87);

  /* general colors */
  --color-black: #0a0a0a;
  --color-white: #ffffff;
  --color-error: #d0021b;
  --color-charcoal: #444444;
  --color-night-rider: #333333;
  --color-dim-grey: #666666;
  --color-gun-powder: #444455;
  --color-green-cyan: #33cb67;
  --color-new-grey: #6D7C90;

  --color-overlay: rgba(0, 0, 0, 0.25);

  /* text */
  --text-fontSize-h1: 40px;
  --text-fontSize-h2: 30px;
  --text-fontSize-h3: 24px;
  --text-fontSize-h4: 22px;
  --text-fontSize-h5: 20px;
  --text-fontSize-body: 16px;
  --text-fontSize-medium: 15px;
  --text-fontSize-small: 14px;
  --text-fontSize-xsmall: 13px;
  --text-fontSize-xxsmall: 11px;
  --text-fontSize-caption: 12px;
  --text-color-basic: var(--color-charcoal);
  --text-color-dark-basic: var(--color-gun-powder);
  --text-color-secondary: var(--color-dim-grey);

  --text-color-landing: #6D7C90;

  /* grey */
  --color-grey1: #9b9b9b;
  --color-grey2: #e6e6e6;
  --color-grey3: #b2b2b2;
  --color-grey4: #eae6f3;
  --color-grey6: #979797;
  --color-grey7: #f5f6fc;
  --color-grey8: #f8f7fa;
  --color-grey9: #e7e0f6;
  --color-grey10: #f2f1f5;

  /* border */
  --border-color-primary: var(--color-primary-1);
  --border-color-secondary: var(--color-secondary-3);

  /* box-shadow */
  --box-shadow-sizes-1: 0 16px 16px 0;
  --box-shadow-sizes-2: 0 11px 26px 0;
  --box-shadow-color-1: rgba(91, 80, 126, 0.1);
  --button-new-shadow-size: 0px 10px 20px;
  --button-new-secondary-shadow-color: rgba(228, 84, 87, 0.38);
  --button-new-primary-shadow-color: rgba(103, 151, 255, 0.15);

  --box-shadow-paper-xs: 0 2px 14px rgba(178, 178, 188, 0.3);
  --box-shadow-paper-sm: 0 2px 12px 0 rgba(129, 118, 162, 0.24);
  --box-shadow-paper-md: 0 14px 21px 0 var(--box-shadow-color-1);
  --box-shadow-paper-lg: 0 27px 60px 0 var(--box-shadow-color-1);
  --box-shadow-input: 0 7px 8px 0 rgba(69, 52, 107, 0.21);
  --box-shadow-price-board: rgba(177, 177, 177, 0.13);
  --button-lg-primary-box-shadow: var(--box-shadow-sizes-1)
    var(--color-box-shadow-primary);
  --button-lg-secondary-box-shadow: var(--box-shadow-sizes-1)
    var(--color-box-shadow-secondary);
  --button-md-primary-box-shadow: var(--box-shadow-sizes-2)
    var(--color-box-shadow-primary);
  --button-md-secondary-box-shadow: var(--box-shadow-sizes-2)
    var(--color-box-shadow-secondary);

  /* button */
  --button-border-radius-1: 25.5px;
  --button-round-dark-bg-color: var(--color-primary-1);
  --button-round-dark-bg-color-hover: var(--color-primary-3);
  --button-round-dark-bg-color-active: var(--color-primary-4);
  --button-round-light-bg-color: var(--color-primary-3);
  --button-round-light-bg-color-hover: var(--color-primary-8);
  --button-round-light-bg-color-active: var(--color-primary-10);
  --button-round-accent-bg-color: var(--color-secondary-1);
  --button-round-new-accent-bg-color: linear-gradient(90deg, #30d5c8 0%, #68d1c9 100%);
  --button-new-primary-border: #8F26AF;
  --button-new-primary-background: linear-gradient(113.63deg, #8F26AF 0%, #141685 98.52%);
  --button-new-primary-text-color: #610FB6;
  --button-new-primary-shadow: var(--button-new-shadow-size) var(--button-new-primary-shadow-color);
  --button-new-primary-active: #610FB6;
  --button-new-secondary-background: linear-gradient(84.95deg, #30d5c8 -5.88%, #69ddd4 105.42%, #9bd9d4 105.42%);
  --button-new-secondary-text-color: #30d5c8;
  --button-new-secondary-border: #30d5c8;
  --button-new-secondary-shadow: var(--button-new-shadow-size) var(--button-new-secondary-shadow-color);
  --button-new-secondary-active: #30d5c8;

  /*  lists */
  --list-item-hover-bg: #f0ebfc;
  --list-item-hover-text: #7145d8;

  --transition-time-casual: 350ms;

  --button-gradient1: #f2f3fa;
  --button-gradient2: #fbfbfb;
  --button-gradient3: #efefef;

  --table-border-divider-color: #f2f3fa
  
}
