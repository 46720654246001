
 @import '../../../../utils/styles/variables';

.sk-hw-download-btn {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 17px;
 
  @media screen and (max-width: $breakpoint-lg) { 
    flex-wrap: wrap;
    justify-content: center;
  }
  &__text {
    font-size: var(--text-fontSize-caption);
    margin-right: 8px;
    @media screen and (max-width: $breakpoint-lg) { 
      margin-right: 0;
    }
  }
}