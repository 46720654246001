@import 'utils/styles/_variables.scss';

.sk-course-variants {
  display: flex;
  flex-direction: column;
  &__label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    align-items: center;
    @media screen and (max-width: $breakpoint-xs) {
      align-items: flex-start;
      flex-direction: column;
      .heading-sk__h3{
        margin-bottom: 15px;
      }
    }
  }
  &__fields {
    display: flex;
    justify-content: space-between;
    &.hidden {
      display: none;
    }
    @media screen and (max-width: $breakpoint-xs) {
      align-items: flex-start;
      flex-direction: column;
      .sk-course-variant-price{
        margin-bottom: 15px;
      }
    }
  }
  &__item {
    max-width: 32%;
    width: 32%;
  }
  & > div:last-of-type {
    top: 10;
  }
}
