.sk-course-module-article {
  &__title {
    text-align: center;
    margin-bottom: 22px;
    h3 {
      color: var(--color-black);
    }
  

  }
  &__text {
    line-height: 1.63;
  }
}
