@import 'utils/styles/text.scss';

.sk-admin-plan-page-layout {
  .sk-content-header__breadcrumbs {
    .sk-link {
      @extend %p2;
      font-weight: 500;
    }
  }
  &__form {
    margin-top: 30px;
    padding: 30px 55px 55px;
  }
}
