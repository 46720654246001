@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-experts-cabinet-commission-active {
  display: flex;
  flex-direction: column;
  align-items: center;
  > .paragraph-sk {
    @extend %h2;
    &_small {
      @extend %h3;
    }
  }
  > .button-sk {
    margin-top: 35px;
  }

  @media screen and (max-width: $breakpoint-lg) {
  }
}
