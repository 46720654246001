.mui-override.sk-card {
  position: relative;
  width: 100%;
  background: var(--color-white);
  box-shadow: 0px 30px 90px rgba(103, 151, 255, 0.2);
  border-radius: 8px;
  &__shrink {
    padding: 40px;
  }
  &__scrollable {
    overflow: auto;
    max-height: 100%;
  }
}
