@import ".../../../../../../utils/styles/variables";

.sk-course-creation-form {
  padding: 0 27px;
  &__inner {
    margin-bottom: 50px;
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 26px;
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
    width: 100%;

    @media screen and (max-width: $breakpoint-sm) {
      flex-flow: column wrap;
      align-items: center;
    }
    & > * {
      max-width: 215px;

      &:not(:last-child) {
        @media screen and (max-width: $breakpoint-sm) {
          margin-bottom: 20px;
        }
      }
    }
  }
  &__trigger {
    position: absolute;
    left: -27px;
    top: 0px;
  }
  &__link {
    @media screen and (max-width: $breakpoint-sm) {
      width: 100%;
    }
  }
  .mui-override.sk-materialized-select {
    min-height: 30px;
  }
}
