.sk-course-information-form {
  &__inner {
    margin-bottom:  44px;
  }
  &__trigger {
    position: absolute;
    left: -27px;
    top: 6px;
  }
  &__tooltip {
    max-width: 500px;
  }
  &__row {
    &:not(:last-of-type) {
      margin-bottom: 0px;
    }
    & > div {
      margin-bottom: 5px;
    }
    @media screen and (min-width: 768px) {
      & > div:first-of-type {
        margin-bottom: 5px;
      }
    }
  }
}


.sk-desc-for-admin {
  &__list {
    padding-left: 0;
    margin: 0;
  }
  &__heading {
    margin-bottom: 12px;
  }
  &__subheading {
    margin-bottom: 6px;
  }
}

.sk-course-information-form-heading{
  margin-bottom: 25px;
  &__admin{
    margin-top: 15px;
  }
}
