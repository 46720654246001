@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-toppings-carousel_content {
  position: relative;
  height: 33vw;
  transform: translateY(-60px);

  &__background,
  &__background__shadow,
  &__background__image,
  &__area {
    position: absolute;
    top: 0;
    bottom: 0;
  }
  &__area{
    overflow-y: scroll;
  }

  &__area_bottom{
    max-width: 500px;
    width: 100%;
    bottom: 0;
  }

  &__background {
    left: 0;
    right: 0;

    &__shadow {
      left: 0;
      background: #fff;
      width: 40%;
      z-index: 2;

      &:before {
        content: '';
        position: absolute;
        z-index: 10;
        background-image: linear-gradient(to right,#fff,transparent);
        top: 0;
        bottom: 0;
        left: 100%;
        width: 100%;
      }
    }

    &__image {
      right: 0;
      width: 60%;
      height: 100%;
      background-position: center 10%;
      background-size: cover;
      z-index: 1;
      overflow: hidden;
      .sk-video-module{
        height: 100%;
        width: 100%;
        margin-right: 0;
        margin-left: auto;
        overflow: hidden;
        video{
          width: 100% !important;
          height: auto !important;
          float: right;
        }
      }
    }
  }

  &__close {
    width: 30px;
    height: 30px;
    background: #fff;
    outline: none;
    border: none;
    position: absolute;
    top: 30px;
    right: 50px;
    background: #FFFFFF;
    border: 1px solid #F1F6FC;
    box-sizing: border-box;
    box-shadow: 0px 20px 45px rgba(103, 151, 255, 0.2);
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    z-index: 100;
    @media screen and (max-width: $breakpoint-md){
      top: 80px; 
    }
  }

  &__mute{
    width: 30px;
    height: 30px;
    position: absolute;
    bottom: 30px;
    right: 50px;
    outline: none;
    border: none;
    z-index: 100;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 50%;
    svg{
      width: 17px;
      height: 14px;
    }
  }

  @media screen and (max-width: $breakpoint-md){
    height: auto;
    background: #fff;
    display: block;
    &__area{
      width: 100%;
      position: relative;
      padding: 90px 55px 0 55px;
      &:before{
        display: none;
      }
    }
    &__area_bottom{
      max-width: 100%;
      width: 100%;
      position: static;
      margin: 30px 0 0;
    }
    &__action-block_col{
      margin-top: 25px;
    }
    &__background{
      width: 100%;
      position: relative;
      transform: translate(0, 45px);
      &__image{
        width: 100%;
        position: static;
        &.author{
          display: none;
        }
      }
      &__shadow{
        display: none;
      }
    }
    .mui-override.sk-tabs-tab{
      width: 33%;
    }
  }
  @media screen and (max-width: 767px){
    &__area_bottom{
      margin: 0;
    }
    &__background{
      transform: translate(0, 0);
    }
  }
  @media screen and (max-width: $breakpoint-sm){
    transform: translateY(-70px);
    margin-bottom: 0px;
    &__area{
      padding-left: 35px;
      padding-top: 25px;
      padding-right: 35px;
      padding-bottom: 0;
      &__features{
        grid-template-columns: 1fr 1fr;
        grid-column-gap: 20px;
      }
    }
    &__area_bottom{
      margin-bottom: 30px;
    }
    .mui-override.sk-tabs-tab{
      width: 50%;
      font-size: 15px;
      color: var(--base-text);
      &.tab-selected {
        color: var(--color-secondary-2);
      }
    }
    &__close {
      top: 15px;
      right: 15px;
    }
    &__mute{
      display: none;
    }
  }
}
