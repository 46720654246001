@import 'utils/styles/_variables.scss';

@function calculateWidth($numberPx){
    @return unquote($numberPx * 100 / 1366 + "vw");
}

@function calculateLargeWidth($numberPx){
    @return unquote($numberPx * 100 / $breakpoint-lg + "vw");
}

@function calculateMediumWidth($numberPx){
    @return unquote($numberPx * 100 / $breakpoint-md + "vw");
}

@function calculateSmallWidth($numberPx){
    @return unquote($numberPx * 100 / $breakpoint-sm + "vw");
}

@function calculateXSmallWidth($numberPx){
    @return unquote($numberPx * 100 / $breakpoint-xs + "vw");
}
