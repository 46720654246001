.sk-pc-summary {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  padding: 10px 60px;
  width: 100%;
  background: var(--color-white);

  &-title {
    display: flex;
    .paragraph-sk {
      margin-left: 5px;
      font-weight: 700;
    }
    span {
      padding-right: 5px;
    }
  }

  &-clock {
    display: flex;
    min-width: 105px;
    width: 105px;
    margin-right: 20px;
  }

  &-mobile {
    display: none;
  }

  @media screen and (max-width: 900px){
    display: none;
  }
}

.sk-pc-summary-mobile {
  display: none;
  flex-direction: column;
  width: 100%;
  padding: 10px 5px 10px 16px;
  &-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }

  &-clock {
    justify-content: space-between;
    display: flex;
    min-width: 75px;
    .sk-duration-component__clock{
      display: block;
      margin-top: -2px;
    }
  }
  .paragraph-sk {
    font-weight: 700;
  }
  @media screen and (max-width: 900px){
    display: flex;
  }
}
