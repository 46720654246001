@import 'utils/styles/text.scss';

.sk-quiz-preview-answer {
  &:not(:last-child) {
    margin-bottom: 20px;
  }

  &__general-info {
    display: flex;
    align-items: center;
    position: relative;

    & > label {
      margin-right: 14px !important;
    }

    & > p {
      @extend %p2;
    }
    & > div:last-child {
      position: absolute;
      right: 0px;
    }
  }
  & > .sk-quiz-uploaded-image {
    margin: 15px 32px 0;
  }
}
