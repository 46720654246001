@import 'utils/styles/text.scss';
@import 'utils/styles/color.scss';
@import 'utils/styles/_variables.scss';

.sk-student-course__flex{
  display: block;
  padding: 46px 36px 57px 36px;
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.3);
  border-radius: 10px;
  top: -20px;
  z-index: 15;
  p{
    @extend %p1;
    line-height: 20px;
    color: var(--purple-dark);
    width: 50%;
    @media screen and (max-width: 1024px) {
      margin-top: 110px;
      width: 100%;
      text-align: center;
    }
    @media screen and (max-width: 480px) {
      margin-top: 70px;
    }
  }
  &_preview{
    position: absolute;
    width: 356px;
    height: 180px;
    top: -90px;
    right: 10px;
    box-shadow: 0px 11px 37px rgba(103, 151, 255, 0.3);
    border-radius: 5px;
    // img{
    //   object-fit: fill;
    // }
    @media screen and (max-width: 1024px) {
      height: 180px;
      top: -40px;
      left: 50%;
      transform: translate(-50%);
      max-width: 90%;
    }
    @media screen and (max-width: 480px) {
      height: 130px;
      top: -40px;
      left: 50%;
      transform: translate(-50%);
      max-width: 90%;
    }
  }
}
