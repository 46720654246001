@import "../../../utils/styles/variables";
@import 'utils/styles/text.scss';

%relative-position {
  @media screen and (max-width: $breakpoint-sm) {
    position: relative;
  }
}

.sk-buy-course {
  position: absolute;
  width: 45%;
  top: 250px;
  z-index: 11;

  &._start {
    position: fixed;
    top: 180px;
    @extend %relative-position;
  }
  &._stop {
    position: absolute;
    @extend %relative-position;
  }
  &._stop2 {
    position: absolute;
    @extend %relative-position;
  }
  .sk-price-border {
    &:before {
      box-shadow: 0px 6px 21px rgba(103, 151, 255, 0.2);
    }
    &__choice {
      &__radio-button {
        .sk-radio-button-label {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          .sk-radio-button-label{
            margin-left: 0px !important;
            &__name{
              margin-left: 0px;
            }
          }
        }
      }
    }
  }
  

  &__description {
    position: relative;
    background: var(--color-white);
    width: 405px;
    margin: 0 auto;
    border-radius: 8.8px;
    box-shadow: var(--box-shadow-paper-lg);
    padding: 0 24px 20px;
    box-sizing: border-box;

    &__image {
      position: absolute;
      width: calc(100% - 48px);
      height: 180px;
      top: -90px;
      box-shadow: var(--box-shadow-paper-lg);
      border-radius: 5px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #fff;

      img{
        height: 100%;
        width: 100%;
      }
    }

    &__info {
      padding-top: 105px;

      > .sk-price-border {
        .sk-price-border__choice__radio-button-group {
          margin: 14px 0;
          > .sk-price-border__choice__radio-button {
            .sk-radio-button-label {
              margin-left: 5px;
              > p {
                @extend %h3;
                color: #6D7C90;
              }
              > span {
                @extend %p2;
                color: #A9B6C8;
              }
            }
          }
        }
      }

      &__button {
        margin-bottom: 0;
        margin-top: 30px;
      }
    }
  }
  @media screen and (max-width: $breakpoint-md) {
    position: relative;
    top: -40px;
    width: 100%;
    box-shadow: var(--box-shadow-paper-xs);
    border-bottom-left-radius: 8.8px;
    border-bottom-right-radius: 8.8px;
    &__description {
      display: flex;
      width: 100%;
      flex-direction: column;
      padding: 0 83px 20px;
      &__image {
        width: calc(100% - 166px);
        top: -90px;
      }
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    position: relative;
    top: -60px;
    width: 90%;
    &__description {
      flex-direction: column;
      padding: 0 17px 20px;
      &__image {
        height: 132px;
        width: 264px;
        top: -72px;
        left: 50%;
        transform: translateX(-50%);
      }
      &__info {
        padding-top: 75px;
        > .sk-price-border {
          .sk-price-border__choice__radio-button-group {
            margin: 14px 0;
            > .sk-price-border__choice__radio-button {
              display: flex;
              align-items: center;
              padding-top: 15px;
              .sk-radio-label{
                margin-right: 4px;
              }
              .sk-radio-button-label {
                margin-left: 0;
                > p {
                  font-size: 16px;
                }
                > span {
                  font-size: 12px;
                }
              }
            }
          }
        }
      }
    }
  }
}
