@import 'utils/styles/text.scss';

.sk-webinar-details-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 40px;
  &__header {
    @extend %h2;
    text-align: center;
    margin-bottom: 15px;
  }
  &__info {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 10px;

    &__row {
      display: flex;
      align-items: center;
      margin: 7px 0;
      &:first-child svg {
        position: relative;
        right: 3px;
      }
      & > svg {
        width: 46px;
      }
      & > p, span {
        @extend %p2;
        letter-spacing: 0.09px;
      }
    }
  }
  &__actions {
    width: 70%;
    display: flex;
    justify-content: space-between;
    & > button {
      width: 150px;
      &:nth-child(2) {
        margin-left: 20px;
      }
    }
  }
}
