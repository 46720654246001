@import 'utils/styles/_variables.scss';

.sk-lec-materials {
  &__header {
    margin-bottom: 12px;
    display: flex;
    align-items: center;
    &__title {
      max-width: 640px;
      width: 100%;
      margin: 10px 30px 10px 0;
    }
    &__extras {
      display: flex;
      justify-content: space-between;
      width: 111px;
      @media screen and (max-width: $breakpoint-sm) {
        display: none;
      }
      > .sk-tooltip-trigger {
        position: relative;
        right: -5px;
      }
    }
  }
  &__content {
    position: relative;
    min-height: 30px;
    margin-bottom: 21px;
  }

  &__actions {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-row-gap: 10px;
    margin-bottom: 7px;
    @media screen and (max-width: 585px){
      grid-template-columns: 1fr 1fr;
    }
  }
  &__btn {
    &:not(:last-of-type) {
      margin-right: 22px;
    }
    & > .sk-add-entity-btn > .sk-add-entity-btn__text {
      display: block;
      text-align-last: center;
      text-align: left;
    }
  }
  &__sidenote {
    line-height: 1.5;
    letter-spacing: 0.3px;
    font-style: italic;
    width: 85%;
    display: inline-block;
  }
  &__asterix {
    color: var(--color-secondary-1);
  }
}
