@import '../../utils/styles/variables';

.mui-override.sk-tabs-tab__indicator {
  background-color: var(--cyan-solid);
}
.mui-override.sk-tabs {
  height: 76px;
  box-shadow: var(--box-shadow-paper-xs);
  &-tab {
    height: 76px;
    text-transform: none;
    font-size: var(--text-fontSize-body);
    color: var(--color-grey1);
    width: 33%;
    &.tab-selected {
      color: var(--color-secondary-2);
    }
    &__indicator {
      width: 33%;
      border-color: var(--color-secondary-3);
    }
  }


  @media screen and (max-width: $breakpoint-sm) {
    height: 58px;
    &-tab {
      line-height: 1.4;
      font-size: 15px;
      height: 58px;
    }
    &__scroller > div {
      @media screen and (max-width: $breakpoint-sm) {
        justify-content: space-around;
        // padding: 0 42px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    &-tab {
      font-size: 12px;
    }
  }
}
