@import 'utils/styles/text.scss';
.sk-ac-bank-details {
  &__header {
    margin-bottom: 16px;
  }
  &__btn {
    margin-top: 50px !important;
    transform: translate(-50%, 0px) !important;
    margin-left: 50% !important;
  }
  &__card {
    align-self: stretch;
    padding: 58px 55px 61px 55px;
    &__title {
      @extend %p1;
      font-weight: bold;
    }
  }
}
