@import 'utils/styles/text.scss';
.sk-course-prices-conversions-modal {
  display: flex;
  flex-direction: column;
  text-align: center;
  &__title {
    @extend %h3;
    font-weight: 600;
    font-size: 31px;
    line-height: 42px;
  }
  &__subtitle {
    @extend %p1;
    margin: 12px 0 40px;
  }
  &__fields {
    display: flex;
    position: relative;
    & > img {
      width: 20px !important;
      height: 20px;
      position: relative;
      bottom: bo;
      margin-right: 30px;
      bottom: -28px;
    }
    & > div {
      width: 100%;
      display: flex;
      justify-content: space-between;
      & > div {
        width: 100% !important;
      }
    }
  }
  &__line {
    height: 1px;
    width: 100%;
    background: #E4E4E4;
    margin: 40px 0;
  }

  &__other-currencies {
    margin-bottom: 10px;
  }
}
