.sk-terms-of-use-text {
  &__header {
    margin-bottom: 16px;
  }
  &__block {
    margin-bottom: 8px;
  }
}

.sk-how-to-create-course {
  &__header {
    margin-bottom: 16px;
  }
  &__block {
    margin-bottom: 12px;
  }
  &__heading {
    font-weight: bold;
    display: block;
    font-size: 13px;
  }
  &__title {
    line-height: 18px;
  }
  &__footer {
    line-height: 18px;
    display: block;
  }
}
