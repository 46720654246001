@import 'utils/styles/text.scss';

.sk-quiz-preview-question {
  &:not(:first-child) {
    margin-top: 40px;
  }
  & > .sk-quiz-uploaded-image {
    margin: 15px 0 25px;
  }

  &__general-info {
    display: flex;

    & > p {
      @extend %p1;
      font-weight: bold;
    }
  }

  &__answers-group {
    margin: 10px 0px;
    & > p:first-child {
      margin: 0 0 20px;
      @extend %p1;
      font-weight: 300;
      letter-spacing: 0.446667px;
    }
  }

  &__explanation {
    display: flex;
    margin-top: 25px;
    & > p:last-child {
      position: relative;
      left: 14px;

      @extend %p1;
      font-style: italic;
      letter-spacing: 0.446667px;
    }
  }
}
