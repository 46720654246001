.paragraph-sk {
  color: var(--text-color-basic);
  font-weight: 400;
  padding: 0;
  margin: 0;

  &_basic {
    font-size: var(--text-fontSize-body);
  }
  &_small {
    font-size: var(--text-fontSize-small);
  }
  &_medium {
    font-size: var(--text-fontSize-medium);
  }
  &_caption {
    font-size: var(--text-fontSize-caption);
  }
  &_light {
    font-weight: 300;
  }
  &_semiBold {
    font-weight: 600;
  }
  &_bold {
    font-weight: 700;
  }
  &_accent {
    color: var(--color-primary-1);
  }
  &_error {
    color: var(--color-error);
  }
  &.disabled {
    color: var(--color-grey1);
  }
  &_new-line {
    white-space: pre-line;
  }
}
