.cross-icon{
  g{
    transition: opacity .15s;
  }
  .white{
    opacity: 0
  }
  &:hover{
    .white{
      opacity: 1
    }
    .purple{
      opacity: 0
    }
  }
}
