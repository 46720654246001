@import 'utils/styles/_variables.scss';
@import 'utils/styles/text.scss';

.sk-course-review-card {
  position: relative;
  background: #FFFFFF;
  box-shadow: 0px 15px 50px rgba(103, 151, 255, 0.2);
  border-radius: 10px;
  padding: 40px 38px 30px 98px !important;
  width: 85%;
  margin: 0 auto 48px !important;
  top: 50px;

  &__photo {
    position: absolute;
    z-index: 2;
    top: 30px;
    left: -25px;
    width: 97px;
    height: 97px;
    > .sk-avatar-user_wrapper {
      &:before {
        content: '';
        position: absolute;
        z-index: -1;
        left: -13px;
        top: -6px;
        border-radius: 50%;
        width: 94%;
        height: 94%;
        background: linear-gradient(90deg, #30d5c8 0%, #3ad1c5 100%);
        box-shadow: 0px 30px 90px rgba(103, 151, 255, 0.2);
      }
      width: 100%;
      height: 100%;
      border: solid 5px white;
      border-radius: 50%;
      box-shadow: 4px 9px 46px rgba(103, 151, 255, 0.2);
      > .mui-override.sk-avatar-user {
        border: none;
        background-color: white;
      }
    }
  }

  &__content {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &__stars {
      display: flex;
      width: 100%;
      margin-bottom: 20px;
      svg {
        margin-right: 5px;
      }
    }
    &__time {
      @extend %p2;
      font-weight: bold;
      text-align: left;
      margin-bottom: 15px;
    }
    > p:first-of-type {
      text-align: left;
      @extend %p1;
      line-height: 28px;
      margin-bottom: 20px;
    }
    &__author {
      > svg {
        position: absolute;
        left: -40px;
      }
      > p {
        text-align: left;
        margin: 0px !important;
        @extend %p2;
        font-weight: bold !important;;
        color: var(--text-color-landing) !important;;
      }
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: 75px 16px 29px !important;
    &__photo {
      top: -40px;
      left: calc(50% - 48.5px);
    }
    &__content {
      position: relative;
      display: flex;
      flex-direction: column;
      justify-content: center;

      &__stars {
        margin-bottom: 20px;
        justify-content: center;
      }
      &__time {
        text-align: center;
        margin-bottom: 15px;
      }
      > p:first-of-type {
        text-align: center;
        line-height: 28px;
      }
      &__author {
        display: flex;
        flex-direction: column;
        align-items: center;
        > svg {
          position: relative;
          left: 0;
          align-self: center;
        }
      }
    }
  }
}
