.mui-override {
  .sk-tooltip {
    max-width: 372px;
    width: 100%;
    padding: 22px 20px;
    color: var(--text-color-basic);
    font-size: var(--text-fontSize-body);
    background: #fff;
    &_wide {
      max-width: 450px;
    }
  }
  &.sk-popper {
    opacity: 1;
  }
}

.sk-tooltip-trigger {
  display: inline-flex;
  cursor: pointer;
  &_question {
    margin-right: 10px;
  }
}
