:root{
  /*New color scheme*/

  /* colors */
  --purple-dark: #610FB6;
  --purple-medium: #D7B6FF;
  --purple-light: #E8E7FF;
  --purple-solid: #610FB6;
  --red-solid: #FF4370;
  --dark-stroke: #20292F;
  --white-text: #FFFFFF;
  --base-text: #6D7C90;
  --dark-text: #20292F;
  --icons: #A9B6C8;
  --medium-stroke: #A9B6C8;
  --disable-color: #B9BDBF;
  --error-color: #FA6060;
  --table-row: #F1F6FC;
  --landing-header: #120D87;
  --cyan-solid: #30d5c8;
  --cyan-hover: #30d5c8;
  --cyan-pressed: #30d5c8;
  /* GRADIENTS */
  /* gradients colors*/
  --purple-gradient-to: #141685;
  --purple-gradient-from: #8F26AF;
  --red-gradient-from: #FF4370;
  --red-gradient-middle: #FF6969;
  --red-gradient-to: #FF8787;
  --red-gradient-pressed-from: #FF2E60;
  --red-gradient-pressed-to: #FF5C5C;
  --blue-gradient-from: #50C4E9;
  --blue-gradient-to: #7EE3FF;
  --cyan-gradient-from: #30d5c8;
  --cyan-gradient-middle: #3ad1c5;
  --cyan-gradient-to: #55d5ca;
  --cyan-gradient-pressed-from: #30d5c8;
  --cyan-gradient-pressed-to: #4dd3c8;


  /* gradients map */
  /*purple gradient*/
  --purple-gradient: linear-gradient(276.99deg, var(--purple-gradient-from) -8.8%, var(--purple-gradient-to) 101.98%);
  /*red gradient*/
  --red-gradient: linear-gradient(88.59deg, var(--red-gradient-from) -5.88%, var(--red-gradient-middle) 105.42%, var(--red-gradient-to) 105.42%);
  --red-gradient-hover: linear-gradient(280.01deg, var(--red-gradient-from) -2.69%, var(--red-gradient-middle) 100.93%, var(--red-gradient-to) 100.93%);
  --red-gradient-pressed:  linear-gradient(295.69deg, var(--red-gradient-pressed-from) 12.69%, var(--red-gradient-middle) 87.81%, var(--red-gradient-pressed-to) 87.81%);
  /*blue gradient*/
  --blue-gradient: linear-gradient(360deg, var(--blue-gradient-from) -9.25%, var(--blue-gradient-to) 113.25%);

  /*cyan gradient*/
  --cyan-gradient: linear-gradient(88.59deg, var(--cyan-gradient-from) -5.88%, var(--cyan-gradient-middle) 105.42%, var(--cyan-gradient-to) 105.42%);
  --cyan-gradient-hover: linear-gradient(280.01deg, var(--cyan-gradient-from) -2.69%, var(--cyan-gradient-middle) 100.93%, var(--cyan-gradient-to) 100.93%);
  --cyan-gradient-pressed:  linear-gradient(295.69deg, var(--cyan-gradient-pressed-from) 12.69%, var(--cyan-gradient-middle) 87.81%, var(--cyan-gradient-pressed-to) 87.81%);
}
