.sk-video-controllers_icon{
  cursor: pointer;
  path.outline{
    display: block;
  }
  path.filled{
    display: none;
  }
  &:hover{
    path.outline{
      display: none;
    }
    path.filled{
      display: block;
    }
  }
}

.sk-video-controllers_list-arrow{
  path.close{
    display: block;
  }
  path.open{
    display: none;
  }
  &.open{
    path.close{
      display: none;
    }
    path.open{
      display: block;
    }
  }
}
