@import '../../../utils/styles/variables.scss';
@import '../../../utils/styles/text.scss';

.sk-landing-page__courses{
  margin-top: -32px;
  position: relative;
  width: 100%;
  &.popular{
    .sk-lp-category-item__heading{
      margin-bottom: -8px;
    }
  }
  &_popular,
  &_new{
    width: 100%;
  }
  &_loader-wrapper{
    height: 200px;
    margin-bottom: 100px;
  }

  &.new{
    @media screen and (max-width: $breakpoint-sm){
      margin-top: -60px;
    }
    @media screen and (max-width: $breakpoint-xs){
      margin-top: -92px;
    }
  }
  .float-element{
    position: absolute;
    top: -14px;
    right: 0;
    @media screen and (max-width: $breakpoint-md){
      display: none;
    }
  }
  .sk-lp-category-item__heading{
    @extend %h2;
    margin-bottom: 0px;
  }
}
