.sk-webroom-chat-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 16px 11px;
  &__title {
    display: flex;
    align-items: center;
  }
  &__chat-icon {
    display: flex;
    margin-right: 9px;
  }
  &__chat-text {
    line-height: 1.5;
  }
  &__members-text {
    line-height: 1.5;
    color: var(--color-grey3);
  }
}
