@import 'utils/styles/_variables.scss';

.mui-override.overwrite {
  &.sk-lc-panel {
    &__panel {
      background: var(--color-white);
      box-shadow: 0px 5px 20px rgba(91, 80, 126, 0.1);
      border-radius: 5px;
      margin-bottom: 5px;
      &[class*="MuiExpansionPanel-expanded-"] {
        margin-bottom: 0;
      }

    }

    &__summary {
      display: flex;
      padding: 15px 0;
      min-height: 16px;
      width: 83%;
      margin: 0 auto;
      background: unset;
      &-content {
        margin: 0;
      }
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        padding: 20px 17px 20px 18px;
        > .sk-lc-panel__btn {
          position: absolute;
          top: 15px;
          right: 30px !important;
          background: #FFFFFF;
          border: 1px solid #F1F6FC;
          box-sizing: border-box;
          box-shadow: 0px 20px 45px rgba(103, 151, 255, 0.2);
          width: 30px;
          padding: 4px 0px 0px 3px;
          height: 30px;
          border-radius: 50%;
        }
      }
    }

    &__details {
      background: var(--color-white);
      padding: 0 0 32px;
      box-shadow: 0 4px 26px 0 rgba(219, 209, 242, 0.5);
      border-radius: 6px;
    }
    &__btn {
      right: -1%;
      svg{
        path{
          stroke: var(--purple-dark);
        }
      }
      top: 0px;
    }
  }
}
