.sk-answerful-step {
  padding-right: 12px;
  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 16px;
    align-items: center;
  }
  &__icon {
    margin-right: 14px;
  }
  &__action {
    display: flex;
    justify-content: center;
    width: 100%;
    text-decoration: none;
  }
  &__file {
    display: flex;
  }
  &__text {
    margin-right: 8px;
  }
}

.inactive{
  color: #BCBCBC;
  font-size: 12px;
}
