%sideBorders {
  border-top-right-radius: 8.8px;
  border-top-left-radius: 8.8px;
}

.mui-override.sk-purchased-courses-lecture {
  border-bottom: 1px solid var(--color-white);

  &:not(:last-child) {
    @extend %sideBorders;
  }

  &:last-child {
    border: none;
  }

  &__expansionPanel {
    border: none;
    box-shadow: none;
    @extend %sideBorders;
  }

  &__expansionPanelSummary {
    background: var(--color-white);
    border: none;
    box-shadow: none;
    padding: 0;
    border-bottom: 1px solid var(--color-white);
    box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.3);

    &_first {
      @extend %sideBorders;
      background: var(--color-white);
      border: none;
      box-shadow: none;
      padding: 0;
      border-bottom: 1px solid var(--color-white);
      box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.3);
      .MuiIconButton-edgeEnd{
        margin-right: 0 !important;
      }
    }

    &_last {
      border-bottom-right-radius: 8.8px;
      border-bottom-left-radius: 8.8px;
      background: var(--color-white);
      border: none;
      box-shadow: none;
      padding: 0;
      box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.3);
      .MuiIconButton-edgeEnd{
        margin-right: 0 !important;
      }
    }

    .MuiIconButton-edgeEnd{
      margin-right: 0 !important;
    }
  }

  &__expansionDetails {
    border-bottom-right-radius: 8.8px;
    border-bottom-left-radius: 8.8px;
    padding: 0;
    @extend %sideBorders;
  }
}
