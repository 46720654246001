@import 'utils/styles/_variables.scss';
@import 'utils/styles/text.scss';

.sk-nearest-webinar-info {
  display: grid;
  grid-template-columns: 1fr 1fr;

  &__row {
    display: flex;
    align-items: center;
    margin: 7px 0;
    &:first-child svg {
      position: relative;
      right: 3px;
    }
    & > svg {
      width: 46px;
    }
    & > p, span {
      font-weight: 600 ;
      letter-spacing: 0.09px;
    }
  }

  @media screen and (min-width: $breakpoint-lg) {
    &__row {
      & > p, span {
        max-width: 240px;
      }
    }
  }
  @media screen and (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    &__row {
      & > p, span {
        max-width: 100px;
      }
    }
  }
  @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
    &__row {
      & > p, span {
        max-width: 210px;
      }
    }
  }
  @media screen and (min-width: $breakpoint-xs) and (max-width: $breakpoint-sm) {
    &__row {
      & > p, span {
        max-width: 135px;
      }
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    display: flex;
    flex-direction: column;
    &__row {
      & > p, span {
        max-width: 225px;
      }
    }
  }
}
