@import 'utils/styles/_variables.scss';

.sk-quiz-general-info {
  min-height: 127px;
  width: auto !important;
  margin: 94px 68px 0px;
  padding: 30px 50px 50px;

  position: relative;
  display: grid;
  grid-template-columns: auto 291px;
  grid-gap: 30px;

  @media screen and (max-width: $breakpoint-md) {
    margin: 68px 40px 0px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin: 68px 40px 0px;
    padding: 30px 30px 40px;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: 30px 21px 40px;
    margin: 68px 16px 0px;
  }
}
