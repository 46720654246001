.sk-webroom-chat-input {
  padding-top: 10px;
  display: flex;

  &__field {
    flex: 1 1 auto;
  }
  &__button {
    flex: 0 0 16px;
    display: flex;
    justify-content: center;
  }
}