@import 'utils/styles/text.scss';

.sk-topping-type-selector {
  fieldset {
    align-self: start;
    > .radio-button-group-label {
      @extend %p3;
    }
  }
  &__radio-button-group {
    margin-top: 10px;
    &.mui-override {
      flex-direction: row;
    }
  }

  &__radio-button.mui-override {
    margin-left: 38px !important;
    &:first-child{
      margin-left: 0 !important;
    }
    span:last-child {
      @extend %p1;
    }
  }
}
