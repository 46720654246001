@import 'utils/styles/text.scss';

.sk-button-expert-plans {
  &__subscription-details {
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    > .paragraph-sk {
      @extend %p2;
      color: var(--purple-dark);
      text-decoration-line: underline;
    }
    img {
      margin-right: 13px;
      width: 43px;
      height: 34px;
    }
  }
}
