@import 'utils/styles/text.scss';

.sk-upload-expert-photo-modal {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    @extend %h3;
    line-height: 0px;
    margin-bottom: 40px;
  }
  &__text {
    @extend %p1;
    margin: 0;
  }
  &__requirements {
    @extend %p3;
    font-style: italic;
    font-weight: 200;
    margin: 20px 0;
  }
}
