@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-experts-cabinet-subscription-active {
  width: 100%;
  padding: 35px 55px 65px;
  display: flex;
  align-items: center;
  flex-direction: column;

  > .paragraph-sk {
    @extend %h3;
    margin-bottom: 11px;
  }

  > .sk-link {
    @extend %p2;
    color: var(--purple-dark);
    text-decoration: underline;
    margin-top: 50px;
    margin-right: auto;
    cursor: pointer;
  }

  @media screen and (max-width: $breakpoint-sm) {
    > .sk-link {
      padding-top: 50px;
      margin: 0 auto;
    }
  }
}

.sk-experts-cabinet-subscription-active-detail {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 25px 0;
  border-bottom: 1px dashed var(--purple-medium);

  &__title {
    display: flex;
    > .paragraph-sk {
      @extend %p1;
      color: var(--dark-text);
    }
    > svg {
      margin-right: 14px;
    }
  }
  &__info {
    display: flex;
    flex-direction: column;
    text-align: right;

    > .paragraph-sk {
      @extend %p1;
      &_bold {
        font-weight: 500;
        color: var(--dark-text);
      }
    }
    > .sk-link {
      margin-top: 30px;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: flex-start;
    &__info {
      margin-top: 12px;
      position: relative;
      left: 39px;
      text-align: left;
    }
  }
}

.sk-expert-subscription-canceled-warning {
  margin-top: 17px;
  display: flex;
  > svg {
    margin-right: 38px;
  }
  &__text {
    display: flex;
    flex-direction: column;
    > .paragraph-sk {
      @extend %h3;
      margin-bottom: 11px;
      color: var(--dark-text);
      text-align: left;
      &_small {
        @extend %p1;
      }
    }
  }

  @media screen and (min-width: $breakpoint-sm) {
    margin-right: auto;
  }
}

