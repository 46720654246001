@import 'utils/styles/_variables.scss';

.sk-course-details-heading {
  margin-top: 35px;
  margin-bottom: 18px;
}
.sk-course-details__messenger-input{
  @media screen and (max-width: $breakpoint-xs) {
    .MuiInputLabel-marginDense{
      transform: translate(0, 12px) scale(1);  
    }
    .MuiInputLabel-shrink{
      transform: translate(0, 1.5px) scale(0.75);  
    }
  }
}