@import 'utils/styles/_variables.scss';

.sk-course-sidebar {
  padding: 0 15px;

  &__block {
    margin-bottom: 15px;
    button {
      width: 100% !important;
    }
  }
  &__action-block {
    button {
      width: 100% !important;
    }
    > button {
      margin: 22px 0;
    }
  }
}
