@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

$prefix: sk-topping-details;

.#{$prefix}_rating-modal{
  padding: 50px 90px 60px;
  &__text{
    @extend %h3;
    font-weight: 500;
    text-align: center;
  }
  &__stars-container{
    width: 70%;
    margin: 55px auto 0;
    display: flex;
    justify-content: space-between;

  }
  @media screen and (max-width: $breakpoint-sm){
    padding: 25px 0;
    &__stars-container{
      width: 100%;
    }
  }
}
