@import 'utils/styles/text.scss';

.sk-student-hw {
  padding: 21px 42px;
  margin-bottom: 32px;
  &__header {
    margin-bottom: 11px;
    text-align: center;
  }
  &__main {
    display: flex;
    flex-direction: column;
    max-width: 216px;
    margin: auto;
  }
  &__stepper {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 28px;
  }
  &__step-block {
    position: relative;
    display: flex;
    align-items: center;
    min-height: 150px;
    padding: 22px 10px 22px 22px;
  }
  &__disable-homework-explanation {
    @extend %p1;
    margin-top: 30px;
  }
}
