input[type=email],
input[type=password] {
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus {
    -webkit-box-shadow: 0 0 0px 1000px #ffffe0e0 inset;
    transition: background-color 5000s ease-in-out 0s;
    background: transparent;
  }
}
