@import 'variables';

.rbc-row {
  display: flex;
  flex-direction: row;
}

.rbc-row-segment {
  padding: 0 1px 1px 1px;

  .rbc-event-content {

  }
}

.rbc-selected-cell {
  background-color: var(--rbc-date-selection-bg-color);
}

.rbc-show-more {
  background-color: var(--color-grey3);
  z-index: var(--rbc-event-zindex);
  font-weight: 700;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap;
}

.rbc-month-view {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%; // ie-fix
}

.rbc-month-header {
  display: flex;
  flex-direction: row;
}

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0; // postcss will remove the 0px here hence the duplication below
  flex-basis: 0px;
  overflow: hidden;

  height: 100%; // ie-fix

  & + & {
    border-top: 1px solid var(--rbc-cell-border);
  }
}

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right;

  &.rbc-now {
    font-weight: 700;
  }

  > a {
    &, &:active, &:visited {
      color: inherit;
      text-decoration: none;
    }
  }
}

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden;
}

.rbc-day-bg {
  flex: 1 0 0%;

  & + & {
    border-left: 1px solid var(--color-grey3);
  }

  .rbc-rtl & + & {
    border-left-width: 0;
    border-right: 1px solid var(--color-grey3);
  }
}

.rbc-overlay {
  position: absolute;
  z-index: var(--event-zindex) + 1;
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
  box-shadow: 0 5px 15px rgba(0,0,0,.25);
  padding: 10px;

  > * + * {
    margin-top: 1px;
  }
}

.rbc-overlay-header {
  border-bottom: 1px solid var(--color-white);
  margin: -10px -10px 5px -10px ;
  padding: 2px 10px;
}
