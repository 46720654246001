@import 'variables';
@import 'time-column';

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: var(--rbc-time-selection-bg-color);
  color: var(--rbc-time-selection-color);
  font-size: 75%;
  width: 95%;
  padding: 3px;
}

.rbc-slot-selecting {
  cursor: move;
}

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: none;
  min-height: 0;
  box-shadow: 0 30px 67px 0 rgba(129, 118, 162, 0.2);

  .rbc-time-gutter {
    white-space: nowrap;
  }

  .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative;
  }
  .rbc-allday-cell.rbc-allday-cell {
    border-left: none;
  }

  .rbc-allday-events {
    position: relative;
    z-index: 4;
  }

  .rbc-row {
    box-sizing: border-box;
    min-height: 20px;
  }
}

.rbc-time-header {
  display: flex;
  flex: 0 0 auto; // should not shrink below height
  flex-direction: row;

  &.rbc-overflowing {
    border-top-left-radius: 8.8px;
    border-top-right-radius: 8.8px;
    border-bottom: 1px solid var(--rbc-cell-border);
    background: var(--color-primary-1);
    padding-right: 0;
    margin-right: 0;
  }

  .rbc-rtl &.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid var(--rbc-cell-border);
  }

  > .rbc-row:first-child {
    border-bottom: 1px solid var(--rbc-cell-border);
  }

  > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid var(--rbc-cell-border);
  }

}

.rbc-time-header-cell-single-day {
  display: none;
}

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column;

  .rbc-rtl & {
    border-left-width: 0;
  }
}

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  position: relative;
  border-left: 1px solid var(--rbc-cell-border);
  box-sizing: content-box;

  .rbc-now {
    background: var(--color-box-shadow-secondary);
  }

  > .rbc-time-gutter {
    flex: none;
  }

  > * + * > * {
    border-left: 1px solid var(--rbc-cell-border);
  }

  .rbc-rtl & > * + * > * {
    border-left-width: 0;
    border-right: 1px solid var(--rbc-cell-border);
  }

  > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none;
  }
}

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;

  background-color: var(--color-primary-1);
  pointer-events: none;
}
