@import 'utils/styles/_variables.scss';

.sk-students-cabinet-courses{
  display: flex;
  flex-direction: column;

  &__heading {
    margin: 25px 0;
    display: flex;
    justify-content: space-between;
    > .sk-content-header {
      height: auto;
    }

    &__filters {
      display: flex;
      justify-content: space-between;
      .mui-override.sk-dropdown{
        z-index: 50;
      }

      &__search{
        max-width: 295px;
        width: 100%;
        position: relative;
        .mui-override.sk-materialized-input_left{
          width: calc(100% - 20px);
        }
        svg{
          position: absolute;
          right: 0;
          top: 50%;
        }
      }
    }
  }

  > .sk-link {
    width: 100%;
  }
  @media screen and (max-width: $breakpoint-sm) {
    &__heading {
      &__filters {
        margin-top: 25px;
      }
    }
  }
}
