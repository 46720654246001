@import 'utils/styles/text.scss';

.sk-why-delete-course {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__header {
    @extend %h3;
  }
  &__sub-header {
    @extend %p1;
    margin: 10px 0;
  }
  &__checkbox-group {
    width: 100%;
    margin: 8px 0;
    align-self: flex-start;
    & > div {
      width: calc(100% - 35px);
      margin-left: 35px;
      margin-top: 0;
      margin-bottom: 0;
      & > div {
        margin-top: 6px;
      }
    }
  }
  & > button {
    margin: 25px 0;
  }
}
