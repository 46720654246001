@import '../../utils/styles/variables';
@import '../../utils/styles/functions';

.sk-cookie-banner {
  position: fixed;
  z-index: $z-index-banner;
  bottom: 0;
  left: 0;

  &__content {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 8px calculateWidth(100);
    background: linear-gradient(318.22deg, #8F26AF -8.8%, #141685 101.98%);
    box-shadow: 0px 27px 60px rgba(91, 80, 126, 0.1);
    margin: 0 auto;
    border-radius: 10px;

    svg{
      height: auto;
      width: 64px;
      height: 62px;
    }

    &__description{
      font-size: 14px;
      line-height: 20px;
      letter-spacing: 0.293125px;
      color: #FFFFFF;
      margin: 0 auto;
      width: 65%;
      a{
        color: inherit;
        text-decoration: none;
      }
    }
    &__button.mui-override{
      margin-left: 10px;
      border-radius: 25.5px;
      height: 43px;

    }

    @media screen and (max-width: $breakpoint-md) {
      padding: 8px 35px;
    }

    @media screen and (max-width: $breakpoint-sm) {
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      padding: 24px 12px;
      &__description{
        width: 100%;
        margin-top: 0;
        margin-bottom: 24px;
      }
    
      & > button {
        width: 144px;
        justify-self: center;
      }
    }

    
    @media screen and (max-width: $breakpoint-xs) {
      & > svg {
        display: none;
        justify-self: center;
      }
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: 0;
  }
  &.hide {
    display: none !important;
  }
}
