.mui-override.sk-dropdown {
  display: flex;
  flex-direction: column;
  flex: 1 1 auto;
  z-index: 1500;
  &-item {
    &:hover {
      background-color: var(--color-white);
      color: var(--color-primary-4);
      span {
        &:only-child {
          color:  var(--color-primary-4);
        }
      }
    }
    &__disable {
      color: #ababad;
      cursor: not-allowed !important;
      opacity: 0.5;
      text-decoration: none;
      &:hover {
        background-color: #f8f7fa;
      }
    }
    &__gutters {
      padding: 8px 16px;
    }
    &__text {
      font-size: var(--text-fontSize-caption);
    }

  }
  &-toggler {
    background: none;
    border: none;
    outline: none;
    margin: 0;
    padding: 0;
    flex: 1;
  }

  &__group {
      padding: 12px 0;
      &:not(:last-child) {
        border-bottom: 1.2px dashed var(--color-grey9);
      }
  }
}
.mui-override.paper {
  display: flex;
  flex-direction: column;
  background-color: var(--color-grey8);
  &-shadow {
    box-shadow: var(--box-shadow-paper-sm);
  }
  &.user-dropdown{
    background: #E8E7FF;
    box-shadow: var(--box-shadow-paper-sm);
    border-radius: 5px;
    .sk-auth-block_mobile{
      display: block;
      padding: 30px 25px;
      &__item{
        display: block;
        width: 100%;
        height: 33px;
        text-align: center;
        margin-bottom: 25px;
        button{
          width: 100%;
        }
      }
    }
  }
}
