@import 'variables';

.rbc-event {
  border: none;
  margin: 0;
  padding: var(--rbc-event-padding);
  background-color: var(--color-primary-1);
  border-radius: var(--rbc-event-border-radius);
  color: var(--rbc-event-color);
  cursor: pointer;
  width: 80%;
  text-align: left;
  box-shadow: 0 6px 13px 0 rgba(171, 150, 220, 0.77);

  .rbc-slot-selecting & {
    cursor: inherit;
    pointer-events: none;
  }

  &:focus {
    outline: 5px auto var(--rbc-event-outline);
  }
}

.rbc-event-label {
  font-size: 80%;
}

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51,51,51,.5);
}

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}
.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}


.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}
.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
