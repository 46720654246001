@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-checkout-order-total {
  display: flex;
  align-items: center;
  margin-left: auto;
  &__title {
    @extend %h3;
    color: #0A0A0A;
  }
  &__sum {
    margin-left: 21px;
    @extend %h2;
    color: #30d5c8;
  }

  &__price-crossed {
    @extend %p1;
    margin-left: 26px;
    color: var(--disable-color);
    text-decoration: line-through;
  }

  @media screen and (max-width: $breakpoint-xs) {
    width: 100%;
    justify-content: space-between;
    margin-bottom: 30px;
  }
}
