@import 'utils/styles/text.scss';

.sk-checkout-subscription-success-submit {
  display: flex;
  flex-direction: column;
  align-items: center;
  > .paragraph-sk {
    @extend %h3;
    text-align: center;
    &_small {
      @extend %p2;
    }
    &:nth-child(2) {
      margin: 19px 0 11px;
    }
  }
  > button {
    margin-top: 36px;
  }
}
