@import 'utils/styles/text.scss';

.sk-subscription-select-price {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 25px 0;

  > .paragraph-sk {
    @extend %p1;
    > .paragraph-sk_small {
      @extend %h2;
    }
  }
}
