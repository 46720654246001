@import 'utils/styles/_variables.scss';
@import '../../../../utils/styles/variables';
@import 'utils/styles/text.scss';

.sk-buy-course-course-features {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 7px;
  justify-content: space-between;
  margin: 25px 0px 25px;

  > div {
    display: flex;
    svg {
      margin-right: 16px;
    }
    > .paragraph-sk {
      @extend %p2;
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    grid-template-columns: 1fr 1fr;
    div svg {
      margin-right: 10px;
    }
  }
}
