@import 'utils/styles/text.scss';
.sk-expert-analytics-empty {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 0;
  position: relative;
  top: 20%;
  &__text {
    @extend %p1;
    margin: 20px 0;
    &:first-child {
      font-weight: 600;
    }
  }
}
