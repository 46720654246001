@import '../../../../utils/styles/variables';

.sk-hw-mark-form {
  display: flex;
  align-items: center;
  @media screen and (max-width: $breakpoint-md) {
    &_scrolled {
      justify-content: space-between;
      & > div {
        flex: 0 0 47.5%;
      }
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    &_scrolled {
      & > div {
        &:first-child {
          flex: 0 0 60%;
        }
        &:last-child {
          flex: 0 0 37.5%;
        }
      }
    }
  }

  &__mark {
    max-width: 57px;
    @media screen and (max-width: $breakpoint-md) {
      justify-content: space-between;
      &_scrolled {
        max-width: 100%;
      }
    }
    @media screen and (min-width: $breakpoint-md) {
      max-width: 90px;
    }
  }

  &__btn {
    margin-left: 13px;
  }
}
