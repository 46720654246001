@import '../../../../utils/styles/variables';

.sk-hw-summary {
  width: 100%;
  display: grid;
  grid-template-columns: 3fr 4fr 3fr;
  padding-right: 0;
  @media screen and (max-width: $breakpoint-lg) { 
    grid-template-columns: minmax(210px, 3fr) 4fr 3fr;
  }
}

.sk-hw-summary-status-block {
  display: flex;
  align-items: center;
  &__icon {
    display: flex;
    margin-right: 14px;
  }
}