.sk-draft-editor-inline-toolbar {
  max-width: 200px;
  width: 200px;
  left: 50%;
  transform: translate(-50%) scale(0);
  position: fixed;
  background: #fff;
  border-radius: 2px;
  box-shadow: 0px 1px 3px 0px rgba(220, 220, 220, 1);
  z-index: 9999;
  box-sizing: border-box;

  & > .sk-modal__close-btn  {
    z-index: 2;
  }

  & > .sk-editor-inline-toolbar-link-content  {
    position: relative;
    z-index: 2;
    background: #fff;
  }

  & > svg {
    position: absolute;
    z-index: 1;
  }
}
