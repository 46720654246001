@import 'utils/styles/_variables.scss';
@import 'utils/styles/text.scss';

.sk-quiz-question {
  min-height: 327px;
  width: auto !important;
  margin: 68px 68px 0px;
  padding: 50px;

  & > .sk-quiz-icons-group {
    display: flex;
    position: absolute;
    top: -18px;
    right: 32px;

    & > button, & > label, & > div {
      margin: 0 21px;
    }
  }
  &__general-info {
    position: relative;
    display: grid;
    grid-template-columns: auto 291px;
    grid-gap: 30px;
    & > label {
      @extend %p1;

      position: absolute;
      left: -23px;
      top: 29px;
    }
  }
  &__answers-group {
    .sk-add-entity-btn {
      margin: 25px 40px 15px;
    }
  }

  @media screen and (max-width: $breakpoint-md) {
    margin: 68px 40px 0px;
  }

  @media screen and (max-width: $breakpoint-sm) {
    margin: 68px 40px 0px;
    padding: 21px 30px;
    &__general-info {
      display: flex;
      flex-direction: column;
      & > div:last-child {
        max-width: 100% !important;
      }
    }
    & > .sk-quiz-icons-group {
      right: calc(50% - 117px);
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    margin: 68px 16px 0px;
  }
}
