@import 'utils/styles/text.scss';
@import 'utils/styles/functions.scss';

.sk-purchasedCoursesHero {
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  background: linear-gradient(288.84deg, #8F26AF -8.8%, #141685 101.98%);
  padding: 25px 0 110px;

  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  &__header {
    flex: 0 0 auto;
    margin-bottom: 26px;
  }
  &__title {
    @extend %h1;
    margin: 0 auto;
    color: var(--color-white);
    .heading-sk.heading-sk__h1{
      color: inherit;
      font-size: inherit;
      @media screen and (max-width: 767px) {
        word-break: break-all;
      }
    }
    
    &__rating{
      display: flex;
      .paragraph-sk.paragraph-sk_basic{
        @extend %p1;
        color: var(--color-white);
        line-height: 1;
        display: inline-block;
        margin-top: 37px;
        margin-left: 25px;
      }
    }

    &__link {
      border: solid 1px var(--color-white);
      border-radius: var(--button-border-radius-1);
      color: var(--color-white);
      text-transform: none;
      white-space: nowrap;
      width: fit-content;
      min-width: 140px;
      padding: 12px 32px;
      text-decoration: none;
      height: 43px;
      line-height: 1.2;
      font-size: var(--text-fontSize-small);
      margin: 0 auto;
    }
  }

  @media screen and (max-width: 480px){
    &__wrapper {
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
    }
    &__title,
    &__rating{
      text-align: center;
    }
    &__rating{
      flex-direction: column;
      align-items: center;
      .paragraph-sk.paragraph-sk_basic{
        margin-left: 0;
      }
    }
    &__link{
      width: 100%;
    }
  }
}
