.sk-user-profile-photo {
  display: flex;
  flex-direction: column;
  align-items: center;
  &__photo {
    margin: 25px 0;
    position: relative;
    width: 200px;
    height: 200px;
    & > img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      opacity: 0.5;
    }
    &__loader > div {
      height: 100px !important;
      width: 100px !important;
      position: absolute;
      top: calc(50% - 50px);
      right: calc(50% - 50px);
    }
    &__uploader {
      position: absolute;
      bottom: 0;
      right: 0;
    }
  }
}
