@import './mixins';
@import './variables';
@import './functions';

$i: 1;
$columnCount: 24;
$gapCell: 1.2;

.grid {
  display: grid;
  max-width: 1365px;
  margin: 0 auto;
  &_left {
   margin: 0;
  }
  grid-template-columns: repeat($columnCount, 1fr);
  grid-column-gap: $gapCell * 0.7em;
  grid-row-gap: 0em;
  @media (min-width: $breakpoint-sm) {
    grid-column-gap: $gapCell * 1em;
  }
  @media (min-width: $breakpoint-md) {
    grid-column-gap: $gapCell * 2em;
  }
  &_gapFree {
    grid-column-gap: 0;
  }
  &_fullWidth {
    width: 100%;
  }
  &.full{
    grid-template-columns: 1fr;
  }
}

@include grid-generator(xs, $columnCount);

@include respond-from($breakpoint-sm) {
  @include grid-generator(sm, $columnCount);
}

@include respond-from($breakpoint-md) {
  @include grid-generator(md, $columnCount);
}

@include respond-from($breakpoint-lg) {
  @include grid-generator(lg, $columnCount);
}

.grid.landing{
  display: flex;
  padding: 0 calculateWidth(126);
  max-width: 100%;
  @media screen and (max-width: $breakpoint-md){
    padding: 0 30px;
  }
  @media screen and (max-width: $breakpoint-xs){
    padding: 0 12px;
  }
  &.center{
    justify-content: center;
  }
  &.space-between{
    justify-content: space-between;
  }
  &.start{
    justify-content: flex-start;
  }
  &.end{
    justify-content: flex-end;
  }
  &.vcenter{
    align-items: center;
  }
  &.vstart{
    align-items: flex-start;
  }
  &.vend{
    align-items: flex-end;
  }
}
