.sk-editor-controls-bar-font-size-select {
  .mui-override.materialized-select-input-formControl {
    width: auto;
    top: -7px;
    margin-right: 8px;
  }
  .mui-override.sk-materialized-select {
    min-height: 25px;
    width: 45px;
  }
  .MuiFormHelperText-root-75 .MuiFormHelperText-filled-81 {
    display: none;
  }
}

#menu-fontSize {
  z-index: 9999;
}
