@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-payment {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 530px;
  &__title {
    @extend %p1;
    font-weight: 500;
    margin-right: auto;
    padding-bottom: 15px;
  }
  &__info {
    display: flex;
    margin-bottom: 32px;
    width: 100%;
    &__card {
      display: flex;
      width: 40%;
    }
    &__stripe {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__paymentInfo {
        width: 100%;
        display: flex;
        align-items: flex-end;
        justify-content: space-between;
        margin-bottom: 28px;
      }
      &__or {
        @extend %h3;
        color: #0A0A0A;
        padding: 40px 0 25px;
      }
      &__paypal {
        height: 43px;
        width: 143px;
        min-width: 143px;
      }
    }
  }
  @media screen and (max-width: $breakpoint-lg) {
    margin: 0 auto;
    width: 668px;
  }
  @media screen and (min-width: 1000px) {
    &__info {
      &__stripe {
        width: 540px !important;
      }
    }
  }
  @media screen and (max-width: 1000px) and (min-width: 650px) {
    &__info {
      &__stripe {
        width: 450px !important;
      }
    }
  }
  @media screen and (max-width: 900px) {
    &__info {
      &__card {
        width: 50%;
      }
    }
  }
  @media screen and (max-width: 650px) {
    &__info {
      flex-direction: column;
      &__card {
        width: 100%;
      }
      &__stripe {
        position: relative;
        top: 30px;
        left: -20px;
      }
    }
  }
}
