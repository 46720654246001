@import 'utils/styles/text.scss';

.sk-subscription-max-courses-limit {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  > .paragraph-sk {
    @extend %h3;
    text-align: center;
    color: var(--dark-text);
    &_small {
      @extend %p2;
      margin-top: 15px;
    }
  }
  > button {
    margin-top: 30px;
  }
}
