.mui-override.sk-radio {
	padding: 0;
	margin: 0;
	&__checked {
		&.checked {
			color: var(--cyan-solid);
		}
		svg g circle {
			stroke: var(--cyan-solid);
		}
	}
	&-label {
		margin-right: 6px;
		@media (max-width: 360px) {
			margin-right: 0;
		}
	}
}
