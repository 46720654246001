.heading-sk {
  color: var(--text-color-basic);
  font-weight: 600;
  padding: 0;
  margin: 0;
  &__h1 {
    font-size: var(--text-fontSize-h1);
  }
  &__h2 {
    font-size: var(--text-fontSize-h2);
  }
  &__h3 {
    font-size: var(--text-fontSize-h3);
  }
  &__h4 {
    font-size: var(--text-fontSize-h4);
  }
  &__h5 {
    font-size: var(--text-fontSize-h5);
  }
  &__bold {
    font-weight: 700;
  }
  &_new-line {
    white-space: pre-line;
  }
  &__center {
    text-align: center;
  }
}

.sk-lp-category-item {
  &__heading {
    &:hover {
      color: var(--color-primary-1);
      cursor: pointer;
    }
  }
}
