@import 'utils/styles/text.scss';

%scroll-blur {
  position: absolute;
  right: 0;
  left: 0;
  height: 90px;
  content: '';
  background: linear-gradient(0.75deg, #FFFFFF 29.97%, rgba(255, 255, 255, 0) 96.22%);
}

.sk-checkout-courses-list {
  min-height: 200px;
  max-height: 349px;
  display: flex;
  flex-direction: column;
  padding: 0 10px 0 26px;

  &__scroll-window {
    width: 100%;
    height: 100%;
    overflow: overlay;
    &::-webkit-scrollbar {
      width: 4px;
      height: 100%;
    }
    &::-webkit-scrollbar-thumb {
      width: 4px;
      background: var(--purple-light);
      border-radius: 20px;
    }
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 5px;
    }
  }
  &::after {
    @extend %scroll-blur;
    bottom: 0;
  }
}

.sk-checkout-course {
  display: flex;
  padding: 26px 0 26px;
  margin-right: 16px;
  cursor: pointer;

  &:first-child {
    padding: 35px 0 26px;
    > .sk-modal__close-btn {
      top: 0;
    }
  }
  &:not(:last-child) {
    border-bottom: 1px dashed var(--medium-stroke);
  }

  > .sk-image {
    height: 73px;
    width: 145px;
    margin-right: 22px;
    border-radius: 5px;
  }
  &__details {
    display: flex;
    flex-wrap: wrap;
    flex-grow: 1;
    margin-right: 20px;

    &__title {
      width: 100%;
      @extend %p3;
      line-height: 18px;
      font-weight: 500;
      color: var(--purple-dark);
      margin-bottom: 10px;
    }
    &__course-type {
      display: flex;
      align-items: center;
      > .paragraph-sk {
        @extend %p3;
        line-height: 18px;
      }
    }
  }

  > button {
    position: relative;
    top: 0;
    left: 0;
    right: 0;
    height: fit-content;
  }
}

.sk-checkout-course-price {
  margin-left: auto;
  .paragraph-sk {
    @extend %p1;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: 0.293125px;
    color: var(--dark-text);
  }

  &__price-with-discount {}
  &__original-price {}
  &__price-crossed {
    text-decoration: line-through;
  }
}
