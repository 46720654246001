.mui-override.sk-admin-coourses-list-filter {
  &__checkboxes-group {
    padding: 0 15px;
    padding-bottom: 15px;
    display: flex;
    flex-direction: column;
    &__button {
      align-self: flex-end;
    }
  }
}
