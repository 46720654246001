@import 'utils/styles/text.scss';

%editing-area-styles {
  border-top: 1px solid #ddd;
  cursor: text;
  background: #FFFFFF;
  border: 1px dashed #ADADAD;
  border-radius: 12px;
  font-size: 16px;
  margin-top: 10px;

  .public-DraftStyleDefault-orderedListItem {
    list-style-type: decimal;
    margin-left: 2em !important;
    &:before {
      content: none;
    }
  }

  .public-DraftEditorPlaceholder-root, .public-DraftEditor-content {
    margin: 25px 25px;
    overflow-y: scroll;
  }
  .public-DraftEditor-content {
    min-height: 100px;
    max-height: 60vh;
  }
  .sk-editor-block-code-block {
    @extend %p1;
    font-weight: normal;
    font-size: 16px;
    line-height: 22px;
  }
  .sk-editor-block-blockquote {
    @extend %p1;
    border-left: 5px solid #eee;
    color: #666;
    margin: 16px 0;
    padding: 10px 20px;
  }
  .sk-editor-block-alignment {
    &-left > div {
      text-align: left;
    }
    &-center > div {
      text-align: center;
    }
    &-right > div {
      text-align: right;
    }
    &-justify > div {
      text-align: justify;
    }
  }
  .public-DraftStyleDefault-pre {
    @extend %p1;
    background-color: rgba(0, 0, 0, 0.05);
    font-size: 16px;
    padding: 20px;
  }
}

.sk-editor-root {
  position: relative;
  background: #fff;
  @extend %p1;
  font-size: 14px;
  padding: 15px 0;
  width: 100%;

  &__full-screen {
    position: fixed;
    width: 100vw;
    height: 100vh;
    left: 0;
    top: 0;
    z-index: 9999;
    padding-left: 15px;
    padding-right: 15px;
  }

  ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
  }
}

.sk-editor-editing-area {
  @extend %editing-area-styles;
}

.sk-editor-editing-area-disable-text-select {
  .public-DraftEditor-content {
    user-select: none !important;
  }
}

.sk-editor-editing-area-full-screen {
  @extend %editing-area-styles;

  .public-DraftEditor-content {
    min-height: calc(100vh - 130px);
    max-height: calc(100vh - 130px);
  }
}

.sk-editor-preview{
  .public-DraftEditor-content{
    min-height: 100px;
    height: 50vh;
  }
}
