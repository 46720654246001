@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-expert-filter-status-form {
  display: flex;
  flex-direction: column;
  padding: 21px 25px 25px;

  > .sk-checkbox {
    &:not(:last-of-type) {
      margin-bottom: 15px;
    }
    > span:last-child {
      @extend %p2;
    }
  }

  > button {
    width: 100%;
    margin-top: 21px;
  }

  @media screen and (max-width: $breakpoint-xs) {
    height: 300px;
    overflow: auto;
  }
}
