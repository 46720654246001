@import "../../../utils/styles/color";

.sk-stepper-icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: var(--color-white);
  box-shadow: 0 5px 10px 0 rgba(148, 152, 190, 0.29);
  border-radius: 50%;
  &__text {
    color: var(--color-grey1);
    line-height: 1px;
    &_active {
      color: var(--color-white);
    }
  }
  &_active {
    background-image: var(--cyan-gradient-pressed);

  }
}
