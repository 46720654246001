@import 'utils/styles/_variables.scss';

.sk-quiz-answer {
  position: relative;
  & > label {
    max-width: 25px;
    height: fit-content;
    position: absolute;
    top: 27px;
  }
  & > .sk-radio {
    top: 30px;
  }
  &__general-info {
    display: grid;
    grid-template-columns: auto 291px;
    grid-gap: 30px;
    margin-left: 36px;
    & > .sk-quiz-icons-group {
      position: absolute;
      top: 28px;
      right: 0;
      display: flex;
      height: fit-content;
      align-self: center;
      & > div, button {
        margin: 0 29px;
        &:last-child {
          margin-right: 12px;
        }
        & > label {
          top: 0;
        }
      }
    }
  }
  &__images-group {
    margin-left: 36px;

    & > .sk-quiz-uploaded-image {
      margin: 10px 0;
      &:first-child {
        // margin: 0;
      }
    }
  }


  @media screen and (max-width: $breakpoint-sm) {
    &__general-info {
      display: flex;
      flex-direction: column;
      & > .sk-quiz-icons-group {
        position: static;
        align-self: flex-start;
        margin: 12px 0;
        & > div, button {
          &:first-child {
            margin-left: 0;
          }
        }
      }
    }
  }
}
