@import 'utils/styles/_variables.scss';

.sk_radio-button-group {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-wrap: nowrap;
  @media screen and (max-width: $breakpoint-xs) {
    flex-direction: column !important;
    align-items: flex-start;

  }
  .sk-radio:last-child {
    margin-left: 35px !important;
    @media screen and (max-width: $breakpoint-xs) {
      margin-left: 0px !important;
      margin-top: 15px !important;
    }
  }
}

.sk-course-details-setting-item {
  margin-bottom: 30px;
  width: 90%;
  .sk-price-border__choice__radio-button:last-child {
    margin-left: 25px !important;
  }
  // &.hide {
  //   opacity: .5;
  // }
}

.sk-course-details-setting__radiobutton {
  width: 75%;
  margin-top: 15px;
  flex-direction: column;
  &__item {
    align-items: flex-start !important;
    margin-bottom: 17px !important;
    .sk-radio {
      margin-top: 3px;
      margin-left: 0 !important;
    }
  }
}
