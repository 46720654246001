.sk-course-details-author-rating {
  $this: &;
    display: flex;
    align-items: center;
    justify-content: space-between;
    display: flex;
    height: 54px;
    padding: 0 20px;
    margin-left: 8px;

  &__item {
    display: flex;
    align-items: center;
    &:first-child {
      margin-right: 16px;
      @media screen and (max-width: 480px) {
        margin-right: 0;
      }
    }
  }
  &__icon {
    display: flex;
    margin-right: 7px;
  }
  &__counter {
      color: var(--color-new-grey);
      margin-right: 4px;
    &, #{$this}__name {
      line-height: 1.8;
    }
    #{$this}__name {
      color: var(--color-grey3);
    }

  }
}

@media screen and (max-width: 480px) {
  .sk-courseDetails-author{
    &__avatar{
      & > div:first-child{
        margin-right: 16px;
        margin-bottom: 10px;
        float: left;
      }
    }
  }
  .sk-courseDetails-author__name{
    margin-left: 0;
    margin-top: 18px;
  }
  .sk-course-details-author-rating{
    padding: 0 12px;
    margin: 0;
    margin-bottom: 65px;
  }
}
