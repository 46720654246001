%cutString {
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
}

.sk-simple-sidebar-menu {
  $this: &;
  padding: 0;
  margin: 0;
  &_withSubMenu {
    padding: 0 10px;
    border-radius: 6px;
    background: var(--color-white);
    & #{$this}__item {
      background: transparent;
      &.active {
          background: var(--color-primary-13);
          border-radius: 6px;
      }
    }
    #{$this}__subTitle {
      justify-content: space-between;
      & span {
        font-size: var(--text-fontSize-small);
        font-weight: 600;
        &:first-child{
          @extend %cutString;
        }
      }
      &.active {
        background: transparent;
        span {
          color: var(--list-item-hover-text);
        }
      }
    }
    #{$this}__subMenu {
      &.mui-override {
        background: inherit;
      }
    }

  }
  &_darkSubmenu {
    background: transparent;
    padding: 0;
  }
  #{$this}_opened {
    padding-bottom: 15px;
  }
  &__link {
    display: inline-flex;
    width: 100%;
    &:not(:last-of-type) {
      margin-bottom: 4px;
    }
  }
  &__item {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 5px 11px;
    height: 40px;
    background: var(--color-grey10);
    transition: all var(--transition-time-casual) ease-in-out;
    text-align: left;
    line-height: 1.4;
    overflow: hidden;
    vertical-align: middle;
    &:hover,
    &.active {
      background: var(--color-white);
      color: var(--list-item-hover-text);
    }
    span {
      font-size: var(--text-fontSize-caption);
      &:first-child{
        @extend %cutString;
      }
    }
  }
  &__btn {
    width: 100%;
    margin-bottom: 4px;
    &_darkSubmenu {
      background: var(--color-grey10);
    }
  }


  &__icon {
    margin-right: 10px;
    width: 12px;
  }
}
