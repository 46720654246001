%flex-vertically-centered {
  display: flex;
  align-items: center;
}

%icon {
  button > svg, label > svg {
    margin-right: 10px;
  }
}

.sk-preview-uploader {
  display: flex;
  flex-direction: column;
  padding-top: 18px;
  &__header {
    letter-spacing: 0.4px;
    color: var(--color-grey1);
    margin-bottom: 30px;
  }
  &__content {
    display: flex;
  }
  &__block {
    position: relative;
    @extend %flex-vertically-centered;
  }
  &__btn {
    position: relative;
    margin-right: 10px;
  }
  &__reg {
    flex: 0 0 auto;
  }
  &__error {
    position: absolute;
    bottom: -21px;
    left: 0;
  }

  &__item {
    display: flex;
    flex-direction: column;
    margin-bottom: 8px;
    &__data {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 8px;
      &__desc {
        display: flex;
        align-items: flex-start;
        flex: 0 0 60%;
      }
      &__icon {
        margin-right: 9px;
        @extend %icon;
      }
      &__info {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        flex: 0 0 40%;
        &__controller {
          @extend %icon;
        }
        &__duration {
          display: flex;
        }
      }
    }
    &__question {
      font-size: 12px;
      margin: 0;
    }
  }
}

.sk-lec-materials {
  &__sidenote {
    line-height: 1.5;
    letter-spacing: 0.3px;
    font-style: italic;
  }
  &__asterix {
    color: var(--color-secondary-1);
  }
}

.sk-uploaded-preview {
  @extend %flex-vertically-centered;
  justify-content: flex-start;
  &__view {
    margin-right: 11px;
  }
  &__title {
    margin-right: 25px;
    span {
      line-height: 1;
    }
  }
  &__loader {
    @extend %flex-vertically-centered;
  }
  &.disabled {
    opacity: 0.5;
  }
  &__controller {
    margin-right: 24px;
  }
}

.inactive {
  color: #BCBCBC;
}

.any-margin {
  margin: 0px;
}
