.sk-calendar {
  display: flex;
  flex: 1 1;
  justify-content: center;

  @media screen and (max-width: 768px) {
    margin-bottom: 80px;
  }

  &__container {
    max-width: 1110px;
    width: 100%;

    .rbc-calendar {
      flex: 1 1;
      min-height: 1000px;
      box-sizing: border-box;
      height: 70%;
      display: flex;
      flex-direction: column;
      align-items: stretch;

      .rbc-toolbar {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        margin-bottom: 30px;

        @media screen and (max-width: 620px) {
          .rbc-toolbar-label {
            margin-top: 20px;
            margin-bottom: 20px;
          }
        }
        .rbc-btn-group {
          .rbc-active {
            background-image: var(--cyan-gradient-pressed);
            color: var(--color-white);
            border-color: var(--cyan-gradient-middle);
            box-shadow: none;
          }

          button {
            width: 70px;
            height: 31px;
            background-image: var(--color-white);
            border-radius: 8.8px;
            margin-right: 10px;
            color: var(--cyan-solid);
            padding: 5px 5px;

            &:last-child {
              margin-right: 0;
            }

            &:hover {
              background-image: var(--cyan-gradient-hover);
              color: var(--color-white);
              border-color: var(--cyan-gradient-middle);
            }

            &:active {
              background-image: var(--cyan-gradient-pressed);
              color: var(--color-white);
              border-color: var(--cyan-gradient-middle);
            }
            &:visited {
              background-image: var(--cyan-gradient-hover);
              color: var(--color-white);
              border-color: var(--cyan-gradient-middle);
            }
          }
        }

        .rbc-toolbar-label {
          font-size: 16px;
          color: var(--base-text);
          text-transform: capitalize;
        }

        .rbc-btn-group {
          button {
            color: var(--cyan-solid);
          }
        }
      }

      .rbc-month-view {
        position: relative;
        display: flex;
        flex-direction: column;
        flex: 1 0;
        width: 100%;
        user-select: none;
        height: 100%;
        border-radius: 8.8px;
        box-shadow: 0 30px 67px 0 rgba(129, 118, 162, 0.2);

        .rbc-month-header {
          display: flex;
          flex-direction: row;
          height: 34px;
          padding-top: 9px;
          background: var(--color-primary-1);
          border-top-left-radius: 8.8px;
          border-top-right-radius: 8.8px;
        }

        .rbc-row {
          display: flex;
          flex-direction: row;

          .rbc-row-segment {
            .rbc-event {
              padding: 0;
              background: transparent;
              .rbc-event-content {
                &:has(.sk-calendar__event.alone) {
                  width: auto;
                  height: auto;
                  white-space: normal;
                }
                width: 100%;
                height: 100%;
                .sk-calendar__event {
                  &.alone {
                    padding: 12px;
                    white-space: normal;
                  }
                }
              }
            }
          }

          .rbc-show-more {
            background-color: transparent;
          }

          .rbc-header {
            overflow: hidden;
            flex: 1 0;
            text-overflow: ellipsis;
            white-space: nowrap;
            padding: 0 3px;
            text-align: center;
            vertical-align: middle;
            font-size: 12px;
            min-height: 0;
            border-left: none;

            span {
              color: var(--color-white);
            }
          }
        }

        .rbc-month-row {
          display: flex;
          position: relative;
          flex-direction: column;
          flex: 1 0;
          flex-basis: 0px;
          overflow: hidden;
          height: 100%;
          background-color: var(--color-white);

          &:last-child {
            border-bottom-left-radius: 8.8px;
            border-bottom-right-radius: 8.8px;
          }

          .rbc-row-bg {
            display: flex;
            flex-direction: row;
            flex: 1 0;
            overflow: hidden;

            .rbc-off-range-bg {
              background: var(--color-white);
            }

            .rbc-day-bg {
              flex: 1 0;
              border-left: 1px solid var(--color-grey2);
            }

            .rbc-today {
              background: var(--cyan-gradient);
            }
          }

          .rbc-abs-full.rbc-row-bg {
            overflow: hidden;
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
          }

          .rbc-row-content {
            position: absolute;
            user-select: none;
            z-index: 4;
            width: 100%;
            height: 100%;

            .rbc-row {
              display: flex;
              flex-direction: row;

              .rbc-date-cell {
                flex: 1 1;
                min-width: 0;
                padding-right: 10px;
                padding-top: 10px;
                text-align: right;
                font-size: var(--text-fontSize-xsmall);

                a {
                  color: var(--color-dim-grey);
                  text-decoration: none;
                }
              }

              .rbc-date-cell.rbc-now {
                a {
                  color: var(--color-white);
                }
              }

              .rbc-off-range {
                a {
                  color: var(--color-grey3);
                  text-decoration: none;
                }
              }

              .rbc-row-segment {
                padding: 0 1px 1px 1px;
              }
            }
          }
        }
      }

      .rbc-time-view {
        .rbc-time-header {
          .rbc-time-header-content {
            .rbc-allday-cell {
              display: none;
              .rbc-event-allday {
                margin-top: 10px;
                background-color: var(--rbc-event-color);
                color: var(--color-primary-1);
              }
            }
          }
        }
      }
    }
  }

  &__modal {
    text-align: center;
  }
  &__nearest-webinar {
    margin-bottom: 37px;
  }
}

.sk-calendar__event {
  padding: 2px 5px;
  width: 100%;
  height: 100%;
  background: #7145d8;
  border-radius: 5px;
  display: block;
  overflow: hidden;
  word-break: break-word;
  font-size: var(--text-fontSize-xsmall);
  text-overflow: ellipsis;
}

.sk-select-time-btn {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
  left: auto;
  &__up,
  &__down {
    display: flex;
  }
  &__up {
    margin-bottom: 4px;
  }
}

.sk-date-picker-icon {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 87%;
}

.sk-calendar__select {
  div {
    label {
      margin-top: 10px;
    }

    div {
      margin-top: 5px;
    }

    p {
      margin-top: 0;
    }
  }
}

.sk-calendar__selectMenu {
  // position: relative;
  width: 30%;
  min-width: 141px;

  .sk-time {
    min-width: 100%;

    div {
      min-height: 40px;
    }

    p {
      display: none;
    }
  }
}
