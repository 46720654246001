@import 'utils/styles/_variables.scss';
@import 'utils/styles/text.scss';

.sk-checkout-menu {
  width: 370px;
  padding: 32px 25px 39px;
  background: var(--purple-light);
  border-radius: 5px;
  &__footer {
    padding-top: 30px;
    display: flex;
    align-items: center;
    &__empty {
      padding: 16px;
    }
    &__total {
      @extend %h3;
      color: var(--dark-stroke);
      &:first-child {
        margin-right: 20px;
      }
    }
    > button {
      margin-left: auto;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    max-width: 100vw;
  }
}
