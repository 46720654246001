@import '../../utils/styles/variables';

.mui-override.button-sk {
  $this: &;
  height: 35px;
  padding: 8px 32px;
  outline: none;
  min-width: auto;
  line-height: 1.2;
  font-size: var(--text-fontSize-small);
  border-radius: var(--button-border-radius-1);
  text-transform: none;
  color: var(--color-white);
  white-space: nowrap;

  &__sm {
    height: 32px;
    width: auto;
    padding: 8px 18px;
    font-size: var(--text-fontSize-caption);
  }

  &.button-sk__sm.button-sk__primary {
    &:hover {
      box-shadow: 0px 10px 20px rgba(103, 151, 255, 0.15);
    }
  }

  &.button-sk__sm.button-sk__secondary {
    &:hover {
      box-shadow: 0px 10px 20px rgba(228, 84, 87, 0.38);
    }
  }

  &__lg {
    height: 43px;
    width: fit-content;
    min-width: 140px;
    padding: 12px 32px;

    @media screen and (max-width: $breakpoint-sm) {
      &:not(.button-sk__fullWidth) {
        min-width: auto;
      }
    }
  }

  &__primary {
    border: 1px solid var(--button-new-primary-text-color);
    background: var(--button-new-primary-background);

    &.button-sk__dark {
    background: var(--button-new-primary-background);
      &:hover {
      background: var(--button-new-primary-background);
      }
    }

    &:hover {
      background: var(--button-new-primary-background);
      color: var(--color-white);
      box-shadow: var(--button-new-primary-shadow);
    }
    &:active {
      background: var(--button-new-primary-background);
    }
  }

  &.button-sk__outlined.button-sk__primary {
    color: var(--button-new-primary-border);
    background: transparent;
    &:hover,
    &.sbForceHover {
      color: var(--color-white);
      border-color: transparent;
      background: var(--button-new-primary-background);
    }
    &:active,
    &.sbForceActive {
      color: var(--color-white);
      border-color: transparent;
      background: var(--button-new-primary-background);
    }
  }

  &.button-sk__outlined.button-sk__secondary {
    color: var(--button-new-secondary-text-color);
    background: var(--color-white);
    &.button-sk__transparent {
      background: transparent;
    }
    &:hover,
    &.sbForceHover {
      color: var(--color-white);
      border-color: transparent;
      background: linear-gradient(84.95deg, #30d5c8 -5.88%, #54d1c6 105.42%, #69d7ce 105.42%);
      box-shadow: none;
    }
    &:active,
    &.sbForceActive {
      color: var(--color-white);
      border-color: transparent;
      background: linear-gradient(84.95deg, #30d5c8 -5.88%, #54d1c6 105.42%, #69d7ce 105.42%);
      box-shadow: none;
    }
  }

  &__secondary {
    border: 1px solid var(--button-new-secondary-border);
    background: linear-gradient(84.95deg, #30d5c8 -5.88%, #54d1c6 105.42%, #69d7ce 105.42%);
    &:hover,
    &.sbForceHover {
      color: var(--color-white);
      background: linear-gradient(84.95deg, #30d5c8 -5.88%, #54d1c6 105.42%, #69d7ce 105.42%);
      box-shadow: var(--button-new-secondary-shadow);
    }
  }

  &.button-sk__lg.button-sk__primary {
    &:hover {
      box-shadow: var(--button-new-primary-shadow);
    }
  }

  &.button-sk__lg.button-sk__secondary {
    &:hover {
      box-shadow: var(--button-new-secondary-shadow);
    }
  }

  &__disabled {
    color: var(--color-white) !important;
    background-color: var(--disable-color);
    background-image: none;
    border-color: var(--color-grey2);
  }
  &__fullWidth {
    width: 100%;
  }
  &.sidePadFree {
    padding: 0;
  }
  &__loading {
    color: rgba(255, 255, 255, 0.4);
  }
  .button-sk-spinner {
    position: absolute;
    left: calc(50% - 10px);
    top: 27%;
    width: 20px;
    height: 20px;
    color: white;
  }
}
