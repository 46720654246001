@import 'utils/styles/_variables.scss';
.sk-calendar {
  &__modal {
    text-align: center;
    padding-top: 0;
    h2 {
      margin-bottom: 10px;
    }
  }
  &__input {
    margin-bottom: 20px;
  }
  &__datePicker {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
  }
  &__button {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  @media screen and (max-width: $breakpoint-xs) {
    &__datePicker {
      flex-direction: column;
      .sk-calendar__selectMenu {
        width: 100%;
      }
    }
  }
}
