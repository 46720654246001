.sk-status-changed-modal {
  padding: 36px 20px 40px 20px;
  &__header {
    margin-bottom: 6px;
    text-align: center;
  }
  &__heading {
    line-height: 1.46;
    text-align: center;
  }
  &__content {
    padding: 0 20px;
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    text-align: center;
    line-height: 1.5;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
}
