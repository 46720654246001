.mui-override.sk-admin-discounts-table-row {
  &__tableBodyRow {
    &:hover {
      cursor: pointer;
      background: lightblue;
    }
  }

  &__tableBodyCell {
    padding: 2px 10px;

    &._last {
      // padding: 2px 10px 30px 10px;
      border-bottom: none;
    }
  }

  &__tableBodyCellLeft {
    padding: 2px 10px;

    &._last {
      // padding: 2px 10px 30px 45px;
      border-bottom: none;
    }
  }

  &__tableBodyCellRight {
    padding: 2px 45px 0 15px;

    &._last {
      padding: 2px 45px 30px 15px;
      border-bottom: none;
    }
  }
}
