.sk-toggler {
  &__wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    cursor: pointer;
  }
  &__menu-icon {
    margin-right: 18px;
  }
  &__title {
    margin-right: 16px;
    color: var(--color-white);
    font-size: var(--text-fontSize-xsmall);
  }
}
