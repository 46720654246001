/*
 * Generates classes for a grid of columns, prefixed by size.
 * The result will be .col-[size]-[col]-[total-columns] E.g. .col-xs-6-12
 *
 * @param {string}	$size			The name of the size target (e.g. xs, sm, md, lg, xl, etc.)
 * @param {int}		$columns	Amount of columns
 */
@mixin grid-generator($size, $columns) {
  @for $i from 1 through $columns {
    @if ($size == "") {
      .grid_item-#{$i}\/#{$columns} {
        grid-column: span #{$i};
      }
      .grid_item-offset-#{$i}\/#{$columns} {
        grid-column: span #{$i};
        height: 100%;
      }
    } @else {
      .grid_item-#{$size}-#{$i}-#{$columns} {
        grid-column: span #{$i};
      }
      .grid_item-#{$size}-offset-#{$i}-#{$columns} {
        grid-column: span #{$i};
        height: 100%;
      }
    }
  }
}

/*
 * A mixin targeting a specific device width and larger.
 * @param {string} $media The target media
 */
@mixin respond-from($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}
