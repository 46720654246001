.sk-currencyRates {
  height: 100%;
  &__header {
    width: 50px;
  }

  &__table {
    position: relative;
    width: 100%;
    // height: 70%;
    background: var(--color-white);
    box-shadow: var(--box-shadow-paper-lg);
    border-radius: 8.8px;
  }

  &__tableHead {
    padding-top: 30px;
  }

  &__pagination {
    display: flex;
    justify-content: center;
    margin-top: 30px;
  }

  &__contentHeader {
    display: flex;
    width: 120px;
  }

  &__panelName {
    padding-top: 35px;
    white-space: nowrap;
  }
  &__update-currency {
    display: flex;
    justify-content: center;
    margin-top: 36px;
  }
}
