.sk-layout {
  display: grid;
  height: 100%;
  min-height: 100vh;
  max-width: 100vw;
  &__inner {
    display: grid;
    max-width: 100vw;
    min-height: 100vh;
    grid-template-columns: 100%;
    grid-template-rows: auto 1fr;
  }
}
