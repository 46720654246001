.sk-hw-lecture-title {
  display: flex;
  flex-direction: column;

  &__name {
    display: flex;
    align-items: center;
    margin-bottom: 2px;
    & > span {
      display: flex;
      white-space: nowrap;
      text-overflow: ellipsis;
      word-break: break-all;
    }
    & > :nth-child(-n+2){
      margin-right: 4px;
    }
  }
  &__submitted-text {
    font-size: var(--text-fontSize-xsmall);
  }
}
