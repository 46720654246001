@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-topping-edit {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 42px 27px;
  > .paragraph-sk {
    @extend %p1;
    align-self: start;
    margin: 0 0 85px;
  }
  > .sk-card {
    max-width: 880px;
    // min-height: 300px;
    padding: 32px 65px 43px;
    margin-top: 56px;
  }

  .sk-tooltip-trigger{
    transform: translate(-5px, 0) !important;
  }
  @media (min-width: $breakpoint-md) and (max-width: $breakpoint-lg) {
    min-width: 880px;
    > .paragraph-sk {
      margin: 0 0 45px;
    }
  }

  // @media (max-width: $breakpoint-md) {
  //   padding: 32px 42px 37px;
  // }

  @media (max-width: $breakpoint-sm) {
    padding: 0 35px 37px;

    > .sk-card {
      padding: 31px 16px 31px;
    }
  }

  @media (max-width: $breakpoint-xs) {
    padding: 0 12px 37px;

    > .sk-card {
      padding: 31px 16px 31px;
    }
  }
}
