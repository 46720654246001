.sk-contact-us {
  display: flex;
  justify-content: center;
  flex: 1;
  align-items: center;

  &__wrapper {
    max-width: 1110px;
    align-self: center;
    display: flex;
    height: 100%;
    width: 90%;
  }
  &__card {
    align-self: center;
    padding: 56px 95px 61px 95px;
  }

  &__content {
    margin-bottom: 46px;
  }
  &__header {
    margin-bottom: 24px;
    padding: 0 32px;
  }
  &__heading {
    margin-bottom: 16px;
  }
  &__heading, &__subheading {
    text-align: center;
  }
  &__subheading {
    line-height: 1.5;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }

  @media screen and (max-width: 414px) {
    &__header {
      padding: 0 0;
    }
    &__card {
      padding: 56px 35px 61px;
    }
  }
}
