.sk-rs-autocomplete {
  &_value-container {
    display: flex;
    flex-wrap: wrap;
    flex: 1;
    align-items: center;
    overflow: hidden;
    padding-bottom: 7px;
  }
 
  &_placeholder {
    position: absolute;
    opacity: 0; 
    display: none;
    height: -1px;
  }
  &_single-value {
    color: var( --color-black);
  }
  &_no-option {
    padding: 4px;
  }
 
}

.mui-override.sk-rs-autocomplete {
  &_control {
    display: flex;
    padding-top: 3px;

  }
} 