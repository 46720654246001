.mui-override.sk-user-view-info {
  padding: 0 63px;
  &__group {
    display: flex;
    justify-content: space-between;
    &__item {
      width: 49%;
    }
  }
}
