.mui-override.sk-pagination {
  &_icon-btn {
    padding: 6px;
    &:hover {
      background: var(--color-primary-1);
    }
  }

  &_label {
    color: var(--color-primary-1);
    &:hover {
      color: var(--color-white);
    }
  }

  &_toolbar {
    padding: 0;
  }

  &_caption {
    color: var(--color-primary-1);
    font-size: var(--text-fontSize-small);
  }

  &_actions {
    margin: 0 0px 0 10px;
  }
}
