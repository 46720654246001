.sk-avatar-uploader {
  position: relative;
  width: 100%;
  flex: 1;

  &_trigger {
    position: absolute;
    right: 0;
    bottom: 0;
  }
}
