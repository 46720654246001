@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-plans-features {
  margin-top: 30px;
  width: 100%;
  > .paragraph-sk {
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend %h3;
    color: var(--white-text);
    background: linear-gradient(330.33deg, #8F26AF -8.8%, #141685 101.98%);
    border-radius: 6px 6px 0 0;
  }

  > .sk-card {
    width: 100%;
    padding: 30px 40px 50px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 0 57px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    width: 660px;
    > .sk-card {
      grid-template-columns: none;
      grid-template-rows: repeat(auto-fit, minmax(200px, 1fr));
      grid-gap: 33px 0;
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
    > .sk-card {
      width: 100%;
      grid-template-rows: repeat(auto-fit, minmax(335px, 1fr));
      padding: 20px 12px 40px;
    }
  }
}

.sk-plans-features-group {
  &__header {
    @extend %p1;
    font-weight: 500;
    color: var(--dark-text);
  }
  &__features {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 35px 40px;
    &__feature {
      display: flex;
      > .paragraph-sk {
        @extend %p2;
      }
      > svg {
        margin-right: 14px;
        min-width: 25px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    &__header {
      text-align: center;
    }
    &__features {
      width: 100%;
      grid-template-columns: 150px 150px 150px;
      justify-content: space-between;
      grid-gap: 25px 0;
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    &__features {
      width: 100%;
      grid-template-columns: none;
      grid-template-rows: repeat(auto-fit, minmax(25px, 1fr));
      grid-gap: 13px 0;
    }
  }
}
