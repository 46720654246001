@import 'utils/styles/text.scss';
.sk-course-lectures-counter {
  display: flex;
  justify-content: space-between;
  &__title {
    display: flex;
    transform: translate(0, -6px);

    h3 {
      display: flex;
      justify-content: space-between;
    }

    @media screen and (max-width: 900px) {
      div:first-child {
        padding-top: 0;
        margin: 0 !important;
        width: 100%;
        transform: translate(0, -2px);
      }
    }
    @media screen and (max-width: 767px) {
      flex-direction: column;
      min-width: 100%;
      .sk-simple-selector{
        left: 0;
        margin-top: 15px;
      }
    }
  }
  &__duration {
    @extend %h3;
    @media screen and (max-width: 767px) {
      display: none;
    }
  }
  &__duration__mobile {
    @extend %h3;
    display: none;
    @media screen and (max-width: 767px) {
      display: flex;
    }
  }
}
