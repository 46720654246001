@import 'utils/styles/_variables.scss';

.sk-course-action-block {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;

  &__icons {
    display: flex;
    > div {
      margin-right: 40px;
    }
    > div:last-child {
      margin-right: 0;
    }
  }

  > .button-sk {
    height: 43px;
  }
  
  &__message {
    display: flex;
    justify-content: center;
    margin-top: 7px;
    margin-bottom: 7px;
  }


  @media screen and (max-width: $breakpoint-sm) {
    &__icons {
    > div:nth-child(1) {
      margin-right: 20px;
    }
  }
  }
  @media screen and (max-width: 374px) {
    flex-direction: column;
    &__icons {
      margin-bottom: 20px;
      > div:nth-child(1) {
        margin-right: 13px;
      }
    }
  }
}
