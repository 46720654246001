@import '../../../../../utils/styles/variables';

.sk-hw-lecture-row {
  position: relative;
  width: 100%;
  height: 80px;
  display: grid;
  grid-template-columns: 3.3fr 3fr 4fr  35px;
  grid-column-gap: 10.5px;
  align-items: center;
  overflow: hidden;
  @media screen and (max-width: $breakpoint-lg) {
    grid-template-columns: minmax(33%, 3fr) 50% calc(17% - 21px) 3fr 35px;
    &_scrolled {
     grid-template-columns: minmax(63%, 3fr) 4.4fr  3fr  35px;
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    grid-template-columns: minmax(50%, 3fr) 25% calc(25% - 21px) 3fr;
    &_scrolled {
      grid-template-columns: minmax(85%, 3fr) 4.4fr  3fr  35px;
     }
  }

  &__loader {
    display: flex;
    align-items: center;
    @media screen and (max-width: $breakpoint-lg) {
      justify-content: space-between;
      &_scrolled {
        display: none;
      }
    }
  }
  &__download {
    width: 114px;
    margin-right: 37px;
    @media screen and (max-width: $breakpoint-lg) {
      margin-right: 5%;
    }
  }
  &__title {
    @media screen and (max-width: $breakpoint-lg) {
      &_scrolled {
        display: none;
      }
    }

  }
  &__review {
    width: 114px;
    @media screen and (max-width: $breakpoint-sm) {
      display: none;
    }
  }
  &__text {
    color: var(--color-grey3);
  }
  &__scroll-btns {
    display: none;
    .sk-content-nav_item:last-child {
      transform: rotate(180deg);
    }
    @media screen and (max-width: $breakpoint-lg) {
      position: absolute;
      left: auto;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      height: 100%;
    }
  }

  &__scroll-offset {
    display: none;
    @media screen and (max-width: $breakpoint-lg) {
      &_visible {
        display: block;
      }
    }
  }

  &__status-block {
    @media screen and (max-width: $breakpoint-lg) {
      display: none;
    }
  }
}
