@import '../../../utils/styles/variables';

.sk-cat-block{
  &__label{
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
      span{
        color: #ffffff !important;
      }
      svg path {
        stroke: #ffffff !important;
      }
    }
    &_mobile{
      display: none;
    }
  }
  &__item {
    &.toggler{
      padding-left: 9px;
    }
    span{
      color: var(--purple-medium);
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: var(--text-fontSize-body);
      line-height: 1.57;
      text-align: center;
    }
    svg path {
      stroke: var(--purple-medium);
    }
  }
  @media screen and (max-width: $breakpoint-xs){
    &__label{
      display: none;
    }
    &__label_mobile{
      display: block;
    }
    &__item.title{
      border-bottom: 1px solid var(--purple-medium);
      &:hover{
        border-color: #ffffff;
      }
    }
  }
}
