@import 'utils/styles/_variables.scss';

.sk-course-module-quiz {
  & > .sk-card {
    padding: 50px;
    &:last-child {
      margin-top: 25px;
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    & > .sk-card {
      padding: 30px 21px;
    }
  }
}
