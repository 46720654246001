.sk-privacy-policy-text {
  &__header {
    margin-bottom: 16px;
  }
  &__block {
    margin-bottom: 8px;
  }
  &__heading {
    font-weight: bold;
    display: block;
    font-size: 13px;
  }
  &__title {
    line-height: 18px;
  }
}
