@charset "UTF-8";
:root {
  --rbc-out-of-range-color: lighten(#333, 40%);
  --rbc-out-of-range-bg-color: lighten(#333, 70%);
  --rbc-calendar-border: #ddd;
  --rbc-cell-border: #ddd;
  --rbc-border-color: #ccc;
  --rbc-segment-width: percentage(1 / 7);
  --rbc-time-selection-color: white;
  --rbc-time-selection-bg-color: rgba(0, 0, 0, .50);
  --rbc-date-selection-bg-color: rgba(0, 0, 0, .10);
  --rbc-event-bg: #3174ad;
  --rbc-event-border: darken(#3174ad, 10%);
  --rbc-event-outline: #3b99fc;
  --rbc-event-color: #fff;
  --rbc-event-border-radius: 5px;
  --rbc-event-padding: 2px 5px;
  --rbc-event-zindex: 4;
  --rbc-btn-color: #373a3c;
  --rbc-btn-bg: #fff;
  --rbc-btn-border: #ccc;
  --rbc-current-time-color: #74ad31;
  --rbc-css-prefix: rbc-i;
  --rbc-today-highlight-bg: #eaf6ff; }

.rbc-btn {
  color: inherit;
  font: inherit;
  margin: 0; }

button.rbc-btn {
  overflow: visible;
  text-transform: none;
  -webkit-appearance: button;
  cursor: pointer; }

button[disabled].rbc-btn {
  cursor: not-allowed; }

button.rbc-input::-moz-focus-inner {
  border: 0;
  padding: 0; }

:root {
  /*New color scheme*/
  /* colors */
  --purple-dark: #610FB6;
  --purple-medium: #D7B6FF;
  --purple-light: #E8E7FF;
  --purple-solid: #610FB6;
  --red-solid: #FF4370;
  --dark-stroke: #20292F;
  --white-text: #FFFFFF;
  --base-text: #6D7C90;
  --dark-text: #20292F;
  --icons: #A9B6C8;
  --medium-stroke: #A9B6C8;
  --disable-color: #B9BDBF;
  --error-color: #FA6060;
  --table-row: #F1F6FC;
  --landing-header: #120D87;
  --cyan-solid: #30d5c8;
  --cyan-hover: #30d5c8;
  --cyan-pressed: #30d5c8;
  /* GRADIENTS */
  /* gradients colors*/
  --purple-gradient-to: #141685;
  --purple-gradient-from: #8F26AF;
  --red-gradient-from: #FF4370;
  --red-gradient-middle: #FF6969;
  --red-gradient-to: #FF8787;
  --red-gradient-pressed-from: #FF2E60;
  --red-gradient-pressed-to: #FF5C5C;
  --blue-gradient-from: #50C4E9;
  --blue-gradient-to: #7EE3FF;
  --cyan-gradient-from: #30d5c8;
  --cyan-gradient-middle: #3ad1c5;
  --cyan-gradient-to: #55d5ca;
  --cyan-gradient-pressed-from: #30d5c8;
  --cyan-gradient-pressed-to: #4dd3c8;
  /* gradients map */
  /*purple gradient*/
  --purple-gradient: linear-gradient(276.99deg, var(--purple-gradient-from) -8.8%, var(--purple-gradient-to) 101.98%);
  /*red gradient*/
  --red-gradient: linear-gradient(88.59deg, var(--red-gradient-from) -5.88%, var(--red-gradient-middle) 105.42%, var(--red-gradient-to) 105.42%);
  --red-gradient-hover: linear-gradient(280.01deg, var(--red-gradient-from) -2.69%, var(--red-gradient-middle) 100.93%, var(--red-gradient-to) 100.93%);
  --red-gradient-pressed:  linear-gradient(295.69deg, var(--red-gradient-pressed-from) 12.69%, var(--red-gradient-middle) 87.81%, var(--red-gradient-pressed-to) 87.81%);
  /*blue gradient*/
  --blue-gradient: linear-gradient(360deg, var(--blue-gradient-from) -9.25%, var(--blue-gradient-to) 113.25%);
  /*cyan gradient*/
  --cyan-gradient: linear-gradient(88.59deg, var(--cyan-gradient-from) -5.88%, var(--cyan-gradient-middle) 105.42%, var(--cyan-gradient-to) 105.42%);
  --cyan-gradient-hover: linear-gradient(280.01deg, var(--cyan-gradient-from) -2.69%, var(--cyan-gradient-middle) 100.93%, var(--cyan-gradient-to) 100.93%);
  --cyan-gradient-pressed:  linear-gradient(295.69deg, var(--cyan-gradient-pressed-from) 12.69%, var(--cyan-gradient-middle) 87.81%, var(--cyan-gradient-pressed-to) 87.81%); }

.rbc-calendar {
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch; }

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
  box-sizing: inherit; }

.rbc-abs-full {
  overflow: hidden;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0; }

.rbc-ellipsis {
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap; }

.rbc-rtl {
  direction: rtl; }

.rbc-off-range {
  color: var(--rbc-out-of-range-color); }

.rbc-off-range-bg {
  background: var(--rbc-out-of-range-bg-color); }

.rbc-header {
  overflow: hidden;
  flex: 1 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0 3px;
  text-align: center;
  vertical-align: middle;
  font-size: var(--text-fontSize-xsmall);
  min-height: 0;
  border-bottom: none;
  padding-top: 10px;
  height: 34px;
  color: white; }
  .rbc-header + .rbc-header {
    border-left: none; }
  .rbc-rtl .rbc-header + .rbc-header {
    border-left-width: 0;
    border-right: 1px solid var(--rbc-cell-border); }
  .rbc-header > a, .rbc-header > a:active, .rbc-header > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-content {
  position: relative;
  user-select: none;
  -webkit-user-select: none;
  z-index: 4;
  height: 100%; }

.rbc-today {
  background: none; }

:root {
  --rbc-out-of-range-color: lighten(#333, 40%);
  --rbc-out-of-range-bg-color: lighten(#333, 70%);
  --rbc-calendar-border: #ddd;
  --rbc-cell-border: #ddd;
  --rbc-border-color: #ccc;
  --rbc-segment-width: percentage(1 / 7);
  --rbc-time-selection-color: white;
  --rbc-time-selection-bg-color: rgba(0, 0, 0, .50);
  --rbc-date-selection-bg-color: rgba(0, 0, 0, .10);
  --rbc-event-bg: #3174ad;
  --rbc-event-border: darken(#3174ad, 10%);
  --rbc-event-outline: #3b99fc;
  --rbc-event-color: #fff;
  --rbc-event-border-radius: 5px;
  --rbc-event-padding: 2px 5px;
  --rbc-event-zindex: 4;
  --rbc-btn-color: #373a3c;
  --rbc-btn-bg: #fff;
  --rbc-btn-border: #ccc;
  --rbc-current-time-color: #74ad31;
  --rbc-css-prefix: rbc-i;
  --rbc-today-highlight-bg: #eaf6ff; }

.rbc-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px; }
  .rbc-toolbar .rbc-toolbar-label {
    flex-grow: 1;
    padding: 0 10px;
    text-align: center; }
  .rbc-toolbar button {
    color: var(--cyan-solid);
    display: inline-block;
    margin: 0;
    text-align: center;
    vertical-align: middle;
    background: none;
    border: 1px solid var(--cyan-gradient-middle);
    padding: .375rem 1rem;
    border-radius: 4px;
    line-height: normal;
    white-space: nowrap; }
    .rbc-toolbar button:active, .rbc-toolbar button.rbc-active {
      background-image: var(--cyan-gradient-pressed);
      color: var(--color-white); }
      .rbc-toolbar button:active:hover, .rbc-toolbar button:active:focus, .rbc-toolbar button.rbc-active:hover, .rbc-toolbar button.rbc-active:focus {
        background-image: var(--cyan-gradient-hover);
        color: var(--rbc-btn-color); }

.rbc-btn-group {
  display: inline-block;
  white-space: nowrap; }
  .rbc-btn-group > button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:last-child:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:first-child:not(:last-child) {
    border-radius: 4px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }
  .rbc-rtl .rbc-btn-group > button:last-child:not(:first-child) {
    border-radius: 4px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0; }
  .rbc-btn-group > button:not(:first-child):not(:last-child) {
    border-radius: 0; }
  .rbc-btn-group button + button {
    margin-left: -1px; }
  .rbc-rtl .rbc-btn-group button + button {
    margin-left: 0;
    margin-right: -1px; }
  .rbc-btn-group + .rbc-btn-group,
  .rbc-btn-group + button {
    margin-left: 10px; }

:root {
  --rbc-out-of-range-color: lighten(#333, 40%);
  --rbc-out-of-range-bg-color: lighten(#333, 70%);
  --rbc-calendar-border: #ddd;
  --rbc-cell-border: #ddd;
  --rbc-border-color: #ccc;
  --rbc-segment-width: percentage(1 / 7);
  --rbc-time-selection-color: white;
  --rbc-time-selection-bg-color: rgba(0, 0, 0, .50);
  --rbc-date-selection-bg-color: rgba(0, 0, 0, .10);
  --rbc-event-bg: #3174ad;
  --rbc-event-border: darken(#3174ad, 10%);
  --rbc-event-outline: #3b99fc;
  --rbc-event-color: #fff;
  --rbc-event-border-radius: 5px;
  --rbc-event-padding: 2px 5px;
  --rbc-event-zindex: 4;
  --rbc-btn-color: #373a3c;
  --rbc-btn-bg: #fff;
  --rbc-btn-border: #ccc;
  --rbc-current-time-color: #74ad31;
  --rbc-css-prefix: rbc-i;
  --rbc-today-highlight-bg: #eaf6ff; }

.rbc-event {
  border: none;
  margin: 0;
  padding: var(--rbc-event-padding);
  background-color: var(--color-primary-1);
  border-radius: var(--rbc-event-border-radius);
  color: var(--rbc-event-color);
  cursor: pointer;
  width: 80%;
  text-align: left;
  box-shadow: 0 6px 13px 0 rgba(171, 150, 220, 0.77); }
  .rbc-slot-selecting .rbc-event {
    cursor: inherit;
    pointer-events: none; }
  .rbc-event:focus {
    outline: 5px auto var(--rbc-event-outline); }

.rbc-event-label {
  font-size: 80%; }

.rbc-event-overlaps {
  box-shadow: -1px 1px 5px 0px rgba(51, 51, 51, 0.5); }

.rbc-event-continues-prior {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0; }

.rbc-event-continues-after {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0; }

.rbc-event-continues-earlier {
  border-top-left-radius: 0;
  border-top-right-radius: 0; }

.rbc-event-continues-later {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0; }

:root {
  --rbc-out-of-range-color: lighten(#333, 40%);
  --rbc-out-of-range-bg-color: lighten(#333, 70%);
  --rbc-calendar-border: #ddd;
  --rbc-cell-border: #ddd;
  --rbc-border-color: #ccc;
  --rbc-segment-width: percentage(1 / 7);
  --rbc-time-selection-color: white;
  --rbc-time-selection-bg-color: rgba(0, 0, 0, .50);
  --rbc-date-selection-bg-color: rgba(0, 0, 0, .10);
  --rbc-event-bg: #3174ad;
  --rbc-event-border: darken(#3174ad, 10%);
  --rbc-event-outline: #3b99fc;
  --rbc-event-color: #fff;
  --rbc-event-border-radius: 5px;
  --rbc-event-padding: 2px 5px;
  --rbc-event-zindex: 4;
  --rbc-btn-color: #373a3c;
  --rbc-btn-bg: #fff;
  --rbc-btn-border: #ccc;
  --rbc-current-time-color: #74ad31;
  --rbc-css-prefix: rbc-i;
  --rbc-today-highlight-bg: #eaf6ff; }

.rbc-row {
  display: flex;
  flex-direction: row; }

.rbc-row-segment {
  padding: 0 1px 1px 1px; }

.rbc-selected-cell {
  background-color: var(--rbc-date-selection-bg-color); }

.rbc-show-more {
  background-color: var(--color-grey3);
  z-index: var(--rbc-event-zindex);
  font-weight: 700;
  font-size: 85%;
  height: auto;
  line-height: normal;
  white-space: nowrap; }

.rbc-month-view {
  border: none;
  position: relative;
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  width: 100%;
  user-select: none;
  -webkit-user-select: none;
  height: 100%; }

.rbc-month-header {
  display: flex;
  flex-direction: row; }

.rbc-month-row {
  display: flex;
  position: relative;
  flex-direction: column;
  flex: 1 0 0;
  flex-basis: 0px;
  overflow: hidden;
  height: 100%; }
  .rbc-month-row + .rbc-month-row {
    border-top: 1px solid var(--rbc-cell-border); }

.rbc-date-cell {
  flex: 1 1 0;
  min-width: 0;
  padding-right: 5px;
  text-align: right; }
  .rbc-date-cell.rbc-now {
    font-weight: 700; }
  .rbc-date-cell > a, .rbc-date-cell > a:active, .rbc-date-cell > a:visited {
    color: inherit;
    text-decoration: none; }

.rbc-row-bg {
  display: flex;
  flex-direction: row;
  flex: 1 0 0;
  overflow: hidden; }

.rbc-day-bg {
  flex: 1 0 0%; }
  .rbc-day-bg + .rbc-day-bg {
    border-left: 1px solid var(--color-grey3); }
  .rbc-rtl .rbc-day-bg + .rbc-day-bg {
    border-left-width: 0;
    border-right: 1px solid var(--color-grey3); }

.rbc-overlay {
  position: absolute;
  z-index: var(--event-zindex)1;
  border: 1px solid var(--color-white);
  background-color: var(--color-white);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.25);
  padding: 10px; }
  .rbc-overlay > * + * {
    margin-top: 1px; }

.rbc-overlay-header {
  border-bottom: 1px solid var(--color-white);
  margin: -10px -10px 5px -10px;
  padding: 2px 10px; }

:root {
  --rbc-out-of-range-color: lighten(#333, 40%);
  --rbc-out-of-range-bg-color: lighten(#333, 70%);
  --rbc-calendar-border: #ddd;
  --rbc-cell-border: #ddd;
  --rbc-border-color: #ccc;
  --rbc-segment-width: percentage(1 / 7);
  --rbc-time-selection-color: white;
  --rbc-time-selection-bg-color: rgba(0, 0, 0, .50);
  --rbc-date-selection-bg-color: rgba(0, 0, 0, .10);
  --rbc-event-bg: #3174ad;
  --rbc-event-border: darken(#3174ad, 10%);
  --rbc-event-outline: #3b99fc;
  --rbc-event-color: #fff;
  --rbc-event-border-radius: 5px;
  --rbc-event-padding: 2px 5px;
  --rbc-event-zindex: 4;
  --rbc-btn-color: #373a3c;
  --rbc-btn-bg: #fff;
  --rbc-btn-border: #ccc;
  --rbc-current-time-color: #74ad31;
  --rbc-css-prefix: rbc-i;
  --rbc-today-highlight-bg: #eaf6ff; }

.rbc-agenda-view {
  display: flex;
  flex-direction: column;
  flex: 1 0 0;
  overflow: auto;
  box-shadow: 0 30px 67px 0 var(--color-box-shadow-primary); }
  .rbc-agenda-view table.rbc-agenda-table {
    width: 100%;
    border: none;
    border-spacing: 0;
    border-collapse: collapse; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td {
      padding: 5px 10px;
      vertical-align: top; }
    .rbc-agenda-view table.rbc-agenda-table .rbc-header {
      background: var(--color-primary-1); }
      .rbc-agenda-view table.rbc-agenda-table .rbc-header:first-child {
        border-top-left-radius: 8.8px; }
      .rbc-agenda-view table.rbc-agenda-table .rbc-header:last-child {
        border-top-right-radius: 8.8px; }
    .rbc-agenda-view table.rbc-agenda-table .rbc-agenda-time-cell {
      padding-left: 15px;
      padding-right: 15px;
      text-transform: lowercase; }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left: 1px solid var(--rbc-cell-border); }
    .rbc-rtl .rbc-agenda-view table.rbc-agenda-table tbody > tr > td + td {
      border-left-width: 0;
      border-right: 1px solid var(--rbc-cell-border); }
    .rbc-agenda-view table.rbc-agenda-table tbody > tr + tr {
      border-top: 1px solid var(--rbc-cell-border); }
    .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
      padding: 3px 5px;
      text-align: left;
      border-bottom: 1px solid var(--rbc-cell-border); }
      .rbc-rtl .rbc-agenda-view table.rbc-agenda-table thead > tr > th {
        text-align: right; }

.rbc-agenda-time-cell {
  text-transform: lowercase; }
  .rbc-agenda-time-cell .rbc-continues-after:after {
    content: ' »'; }
  .rbc-agenda-time-cell .rbc-continues-prior:before {
    content: '« '; }

.rbc-agenda-date-cell,
.rbc-agenda-time-cell {
  white-space: nowrap; }

.rbc-agenda-event-cell {
  width: 100%; }

:root {
  --rbc-out-of-range-color: lighten(#333, 40%);
  --rbc-out-of-range-bg-color: lighten(#333, 70%);
  --rbc-calendar-border: #ddd;
  --rbc-cell-border: #ddd;
  --rbc-border-color: #ccc;
  --rbc-segment-width: percentage(1 / 7);
  --rbc-time-selection-color: white;
  --rbc-time-selection-bg-color: rgba(0, 0, 0, .50);
  --rbc-date-selection-bg-color: rgba(0, 0, 0, .10);
  --rbc-event-bg: #3174ad;
  --rbc-event-border: darken(#3174ad, 10%);
  --rbc-event-outline: #3b99fc;
  --rbc-event-color: #fff;
  --rbc-event-border-radius: 5px;
  --rbc-event-padding: 2px 5px;
  --rbc-event-zindex: 4;
  --rbc-btn-color: #373a3c;
  --rbc-btn-bg: #fff;
  --rbc-btn-border: #ccc;
  --rbc-current-time-color: #74ad31;
  --rbc-css-prefix: rbc-i;
  --rbc-today-highlight-bg: #eaf6ff; }

:root {
  --rbc-out-of-range-color: lighten(#333, 40%);
  --rbc-out-of-range-bg-color: lighten(#333, 70%);
  --rbc-calendar-border: #ddd;
  --rbc-cell-border: #ddd;
  --rbc-border-color: #ccc;
  --rbc-segment-width: percentage(1 / 7);
  --rbc-time-selection-color: white;
  --rbc-time-selection-bg-color: rgba(0, 0, 0, .50);
  --rbc-date-selection-bg-color: rgba(0, 0, 0, .10);
  --rbc-event-bg: #3174ad;
  --rbc-event-border: darken(#3174ad, 10%);
  --rbc-event-outline: #3b99fc;
  --rbc-event-color: #fff;
  --rbc-event-border-radius: 5px;
  --rbc-event-padding: 2px 5px;
  --rbc-event-zindex: 4;
  --rbc-btn-color: #373a3c;
  --rbc-btn-bg: #fff;
  --rbc-btn-border: #ccc;
  --rbc-current-time-color: #74ad31;
  --rbc-css-prefix: rbc-i;
  --rbc-today-highlight-bg: #eaf6ff; }

.rbc-time-column {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  background-color: var(--color-white); }
  .rbc-time-column .rbc-timeslot-group {
    flex: 1; }

.rbc-timeslot-group {
  border-bottom: 1px solid var(--rbc-cell-border);
  min-height: 40px;
  display: flex;
  flex-flow: column nowrap; }

.rbc-time-gutter,
.rbc-header-gutter {
  flex: none; }

.rbc-label {
  padding: 0 5px;
  box-sizing: content-box; }

.rbc-day-slot {
  position: relative; }
  .rbc-day-slot .rbc-events-container {
    bottom: 0;
    left: 0;
    position: absolute;
    right: 0;
    margin-right: 10px;
    top: 0; }
    .rbc-day-slot .rbc-events-container.rbc-is-rtl {
      left: 10px;
      right: 0; }
  .rbc-day-slot .rbc-event {
    border: 1px solid var(--rbc-event-border);
    display: flex;
    max-height: 100%;
    min-height: 20px;
    flex-flow: column wrap;
    align-items: flex-start;
    overflow: hidden;
    position: absolute; }
  .rbc-day-slot .rbc-event-label {
    flex: none;
    padding-right: 5px;
    width: auto; }
  .rbc-day-slot .rbc-event-content {
    width: 100%;
    flex: 1 1 0;
    word-wrap: break-word;
    line-height: 1;
    height: 100%;
    min-height: 1em; }
  .rbc-day-slot .rbc-time-slot span {
    font-size: var(--text-fontSize-xsmall); }

.rbc-time-view-resources .rbc-time-gutter,
.rbc-time-view-resources .rbc-time-header-gutter {
  position: sticky;
  left: 0;
  background-color: white;
  border-right: 1px solid var(--rbc-cell-border);
  z-index: 10;
  margin-right: -1px; }

.rbc-time-view-resources .rbc-time-header {
  overflow: hidden; }

.rbc-time-view-resources .rbc-time-header-content {
  min-width: auto;
  flex: 1 0 0;
  flex-basis: 0px; }

.rbc-time-view-resources .rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-view-resources .rbc-day-slot {
  min-width: 140px; }

.rbc-time-view-resources .rbc-header {
  border: none;
  height: 34px; }

.rbc-time-view-resources .rbc-day-bg {
  width: 140px;
  flex: 1 1 0;
  flex-basis: 0px; }

.rbc-time-header-content.rbc-time-header-content {
  margin-left: -1px; }

.rbc-time-slot {
  font-size: var(--cyan-gradient-middle);
  flex: 1 0 0;
  padding-top: 10px; }
  .rbc-time-slot.rbc-now {
    font-weight: 700;
    background: var(--cyan-gradient); }

.rbc-day-header {
  text-align: center; }

.rbc-slot-selection {
  z-index: 10;
  position: absolute;
  background-color: var(--rbc-time-selection-bg-color);
  color: var(--rbc-time-selection-color);
  font-size: 75%;
  width: 95%;
  padding: 3px; }

.rbc-slot-selecting {
  cursor: move; }

.rbc-time-view {
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  border: none;
  min-height: 0;
  box-shadow: 0 30px 67px 0 rgba(129, 118, 162, 0.2); }
  .rbc-time-view .rbc-time-gutter {
    white-space: nowrap; }
  .rbc-time-view .rbc-allday-cell {
    box-sizing: content-box;
    width: 100%;
    height: 100%;
    position: relative; }
  .rbc-time-view .rbc-allday-cell.rbc-allday-cell {
    border-left: none; }
  .rbc-time-view .rbc-allday-events {
    position: relative;
    z-index: 4; }
  .rbc-time-view .rbc-row {
    box-sizing: border-box;
    min-height: 20px; }

.rbc-time-header {
  display: flex;
  flex: 0 0 auto;
  flex-direction: row; }
  .rbc-time-header.rbc-overflowing {
    border-top-left-radius: 8.8px;
    border-top-right-radius: 8.8px;
    border-bottom: 1px solid var(--rbc-cell-border);
    background: var(--color-primary-1);
    padding-right: 0;
    margin-right: 0; }
  .rbc-rtl .rbc-time-header.rbc-overflowing {
    border-right-width: 0;
    border-left: 1px solid var(--rbc-cell-border); }
  .rbc-time-header > .rbc-row:first-child {
    border-bottom: 1px solid var(--rbc-cell-border); }
  .rbc-time-header > .rbc-row.rbc-row-resource {
    border-bottom: 1px solid var(--rbc-cell-border); }

.rbc-time-header-cell-single-day {
  display: none; }

.rbc-time-header-content {
  flex: 1;
  display: flex;
  min-width: 0;
  flex-direction: column; }
  .rbc-rtl .rbc-time-header-content {
    border-left-width: 0; }

.rbc-time-content {
  display: flex;
  flex: 1 0 0%;
  align-items: flex-start;
  width: 100%;
  overflow-y: auto;
  position: relative;
  border-left: 1px solid var(--rbc-cell-border);
  box-sizing: content-box; }
  .rbc-time-content .rbc-now {
    background: var(--color-box-shadow-secondary); }
  .rbc-time-content > .rbc-time-gutter {
    flex: none; }
  .rbc-time-content > * + * > * {
    border-left: 1px solid var(--rbc-cell-border); }
  .rbc-rtl .rbc-time-content > * + * > * {
    border-left-width: 0;
    border-right: 1px solid var(--rbc-cell-border); }
  .rbc-time-content > .rbc-day-slot {
    width: 100%;
    user-select: none;
    -webkit-user-select: none; }

.rbc-current-time-indicator {
  position: absolute;
  z-index: 3;
  left: 0;
  right: 0;
  height: 1px;
  background-color: var(--color-primary-1);
  pointer-events: none; }
