@import "../../../../utils/styles/variables";
@import "utils/styles/text.scss";

.sk-end-course-modal {
  padding: 0 20px 45px;
  text-align: center;

  @media screen and (max-width: $breakpoint-sm) {
    padding: 0 0 45px;
  }

  &__title {
    text-align: center;
    font-size: var(--text-fontSize-h3);
  }

  &__desc {
    text-align: center;
    margin-bottom: 30px;
  }

  &__rating {
    margin-bottom: 30px;
    position: relative;
    text-align: left;

    .paragraph-sk_small {
      color: var(--color-secondary-1);
    }

    &-table {
      width: 55px;
      height: 54px;
      display: flex;
      &-apply {
        line-height: 32px;
        display: flex;
        margin: auto;
        cursor: pointer;
      }
    }

    &-title {
      display: flex;
      margin-bottom: 20px;

      span {
        @extend %p1;
      }
    }

    &-stars {
      display: flex;

      button {
        margin-left: 10px;

        &:first-child {
          margin-left: 0;
        }
      }
    }
  }
}

@keyframes clap {
  0% {
    opacity: 0;
  }
  15% {
    opacity: 1;
  }
  95% {
    opacity: 0;
  }
}
