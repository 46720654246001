.sk-onboarding {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px 0;
  height: 100%;
  background-size: 95% 95%;
  &_wrapper {
    max-width: 1110px;
    width: 80.2%;
    align-self: stretch;
    display: flex;
  }
  &_card {
    align-self: stretch;
    padding: 58px 95px 61px 95px;
  }
  &_contetnt {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_header {
    margin-bottom: 28px;
  }
  &_heading {
    text-align: center;
  }

  &_policy {
    &_area {
      max-height: 510px;
      height: 100%;
      overflow: auto;
      padding: 10px 30px 40px 30px;
      border: 1px solid var(--color-grey1);
      border-radius: 4px;
      margin-bottom: 10px;
    }
    &_heading {
      display: inline-block;
      margin-bottom: 27px;
    }
    &_terms {
      margin-bottom: 38px;
    }
    &_button {
      display: flex;
      justify-content: center;
    }
  }
}
