.sk-ma-pw-change-form {
  &_inner {
    display: flex;
    flex-flow: column;
    margin-bottom: 42px;
  }
  &_row {
    width: 100%;
    display: flex;
    margin-bottom: 14px;
  }
  &_footer {
    display: flex;
    justify-content: center;
  }
}
