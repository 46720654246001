@import 'utils/styles/_variables.scss';

.sk-content-header {
  display: flex;
  align-items: center;
  height: 90px;
  width: 100%;

  &_withAction {
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 33px;
  }

  &__sidebar-toggle {
    display: flex;
    position: relative;
    z-index: $z-index-sidebar;
  }

  &__breadcrumbs {
    z-index: 1;
    display: inline-flex;
    align-items: center;
    color: var(--text-color-secondary);
    &.white {
      color: var(--color-white);
    }
    &.black {
      color: var(--color-black);
    }
  }

  &__complex-content {
    @media screen and (min-width: $breakpoint-sm) and (max-width: $breakpoint-md) {
      left: 258px;
    }
  }
}
