@import 'utils/styles/text.scss';

.mui-override.sk-user-view-expert-details {
  padding: 0 63px;
  &__group {
    display: flex;
    justify-content: space-between;
    &__item {
      width: 49%;
    }
  }
  &__section-text {
    display: inline-block;
    padding-top: 48px;
    @extend %p1;
  }
  &__payment > div {
    // display: flex;
    // flex-direction: row;
  }
}
