.sk-checkbox-label {
  &_text {
    position: relative;
    display: inline-flex;
  }

  &_errorText {
    position: absolute;
    top: 100%;
  }
}
