@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-toppings-carousel_content__action-block{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  
  &_col{
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
  &__price_label{
    @extend %h2;
    span {
      font-size: 40px;
      sup {
        top: -0.7em;
        font-size: 0.5em;
      }
    }
    margin: 0;
  }
  &_add_to_cart{
    margin: 0 32px 0 0 !important;
  }
  @media screen and (max-width: $breakpoint-md){
    &_col{
      width: 60%;
    }
  }
  @media screen and (max-width: $breakpoint-sm){
    display: block;
    &_add_to_cart{
      width: 45px;
      margin: 0 0 25px auto;
    }
    &_col{
      width: 100%;
      justify-content: space-between;
    }
  }
}
