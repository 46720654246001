%smooth {
  transition: transform 100ms ease-in-out;
}
.sk-round-icon {
  $this: &;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: none;

  &__sm {
    height: 30px;
    width: 30px;
  }

  &__sm-button {
    height: 32px;
    width: 32px;
  }

  &__md {
    height: 36px;
    width: 36px;
  }

  &__lg {
    height: 41px;
    width: 41px;
  }
  &__xlg {
    height: 58px;
    width: 58px;
  }

  &__opacity {
    &-75 {
      opacity: .75;
    }
  }

  &-type {
    &__accent {
      background: var(--button-round-new-accent-bg-color);
    }

    &__dark-outline {
      transition: all 100ms ease-in-out;
      border: 1px var(--button-round-dark-bg-color-hover) solid;
      svg path {
        transition: fill 100ms ease-in-out;
      }
      &:hover {
        background: var(--button-round-dark-bg-color-hover);
        svg path {
          fill: #fff;

        }
      }
      &:active {
        background: var(--button-round-dark-bg-color-active);
      }
    }
    &__dark {
      background: var(--button-round-dark-bg-color);
      &:hover {
        background: var(--button-round-dark-bg-color-hover);
      }
      &:active {
        background: var(--button-round-dark-bg-color-active);
      }
    }
    &__light {
      background: var(--color-primary-9);
      &:hover {
        background: var(--button-round-light-bg-color-hover);
      }
      &:active {
        background: var(--button-round-light-bg-color-active);
      }
    }
    &__flat {
      background: var(--color-white);
      box-shadow: 0 9px 27px 0 #5b507e2a;
      &_action {
        background: var(--color-white);
        box-shadow: 0 9px 27px 0 #5b507e2a;
        &:hover {
          background: var(--button-round-new-accent-bg-color);
          svg g {
            stroke: #fff;
          }
        }
      }
    }
    &__flat-secondary {
      background: var(--button-new-secondary-border);
    }

    &__nav {
      background: transparent;
      &:hover {
        background: var(--button-round-dark-bg-color-hover);
      }
      &:active {
        background: var(--button-round-dark-bg-color-active);
      }
    }

    &__outline {
      border: 1px solid var(--button-new-secondary-border);
      &:hover {
        background: var(--button-round-new-accent-bg-color);
        box-shadow: var(--box-shadow-button-1);
        cursor: pointer;
      }
    }
  }
  &_action {
    cursor: pointer;
  }

  & > svg,
  div {
    margin: auto;
    display: inline-flex;
  }

  &_chevron {
    &_left > svg,
    div {
      transform: translate(-20%, 3%);
      @extend %smooth;
    }
    &_right > svg,
    div {
      transform: translate(20%, -5%) rotate(180deg);
      @extend %smooth;
    }
  }
  &.sk-round-icon_disabled {
    cursor: inherit;
    border-color: var(--color-grey1);
    &:hover {
      background: inherit;
    }
  }
}
