.sk-pw-change {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  padding: 100px 0;
  background-size: 85% 85%;

  &_container {
    max-width: 540px;
    box-shadow: var(--box-shadow-paper-xs);
  }

  &_inner {
    padding: 32px 45px 43px;
  }

  &_header {
    margin-bottom: 21px;
    text-align: center;
  }
  &_heading {
    letter-spacing: 0.4px;
    margin-bottom: 17px;
    text-align: center;
  }
  &_subheading {
    line-height: 1.5;
  }
}
