.sk-swipe-btn {
  position: relative;
  border-radius: 50%;
  min-width: auto;
  line-height: 0px;

  &__medium {
    width: 40px;
    height: 40px;
  }
  &__small {
    width: 30px;
    height: 30px;
  }
  &__primary {
    border: 1px solid var(--purple-solid);
    transition: box-shadow .3s;
    svg {
      path {
        stroke: var(--color-white);
      }
    }
    border-color: var(--purple-solid);
    background-color: var(--purple-solid);
    &:hover {
      border-color: var(--purple-solid);
      background-color: var(--purple-solid);
      box-shadow: 0px 10px 20px rgba(37, 24, 139, 0.3);
    }
  }
  &__secondary {
    border: 1px solid var(--color-secondary-1);
    &:hover {
      border-color: var(--color-secondary-1);
      background-color: var(--color-secondary-1);
    }
  }

  &__icon {
    display: flex;
    position: absolute;
    transform: translate(-50%, -50%);

    &_prev {
      left: 43%;
      top: 53%;
    }
    &_next {
      top: 50%;
      left: 54%;
      transform: translate(-50%, -50%) rotate(180deg);
      & > svg {
        transform: rotate(0);
      }
    }
  }

  &:hover {
    svg {
      path {
        stroke: var(--color-white);
      }
    }
  }
}
