@import "../../utils/styles/color";
@import "../../utils/styles/_variables";

.mui-override.sk-filter {
  &-chip {
    width: 100%;
    flex: 1;
    background-color: var(--cyan-gradient-hover);
    cursor: pointer;
    &:focus {
      background-color: var(--cyan-gradient-hover);
    }
  }
  &-input {
    $this: &;
    cursor: pointer;
    width: 100%;
    &_major,
    &_major.sk-filter-input__disabled {
      background: transparent !important;
    }


    &__disable {
      background: none;
    }
    &:before {
      border: none;
    }
    &,
    &:hover,
    &:focus,
    &:active {
      &:before,
      &:after {
        border: none !important;
        transition: none !important;
      }
    }

    &_focused.sk-filter-input {
      $this: &;
      background: var(--cyan-gradient-hover);
      &_major {
        #{$this}__disabled {
          background: transparent !important;
        }
      }
    }

    &_minor {
      background: var(--cyan-gradient-hover);
      border-radius: 20px;
      height: 40px;
      border-bottom: none;
      display: flex;
      align-items: center;
      padding: 10px;

      &,
      &:hover,
      &:focus,
      &:active {
        background-color: var(--cyan-gradient-hover);
      }

      &__empty.sk-filter-input {
        &,
        &:hover,
        &:focus,
        &:active {
          padding: 10px 12px;
          color: var(--text-color-basic);
          background: transparent;
          border: 1px solid var(--cyan-gradient-middle);
        }
      }
    }
    &_major {
      height: 70px;
      display: flex;
      align-items: center;

      &,
      &:hover,
      &:focus,
      &:active {
        background: transparent !important;
      }
    }
  }

  &-select-icon {
    &__fullfield {
      display: none;
    }
  }

  &-select_menu {
    &__major {
      font-size: var(--text-fontSize-h2);
      letter-spacing: 0.5px;
      padding-right: 25px;
      justify-content: center;
    }
    &__minor {
      font-size: var(--text-fontSize-caption);
      color: var(--cyan-solid);
      & .sk-filter-chip {
        color: var(--color-white);
      }
    }
  }
}

.sk-filter-chip {
  &.delete-icon {
    background: transparent;
    border: none;
    width: auto;
    padding: 0;
    margin: 0;
    z-index: $z-index-chip-delete;
    position: absolute;
    right: 20px;
  }
}
