.sk-applause{
  position: relative;
  g{
    transition: opacity .3s;
  }
  .filled{
    position: absolute;
    opacity: 0;
  }
  .unfilled{
    position: absolute;
    opacity: 1;
  }
  &:hover,
  &[data-fill="true"]{
    .filled{
      opacity: 1;
    }
    .unfilled{
      opacity: 0;
    }
  }
}
