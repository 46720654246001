@import 'utils/styles/text.scss';

.sk-subscription-unlimited-courses {
  display: flex;
  align-items: center;
  > .paragraph-sk {
    @extend %h3;
    color: #30d5c8;
    margin-left: 13px;
  }
}
