@import 'utils/styles/color.scss';
@import 'utils/styles/_variables.scss';

.slider-item {
  // height: 218px;
  height: calc(100% - 20px);
  background: #fff;
  transition: transform 300ms ease 100ms;
  margin: 0 10px;
  padding: 0 12px;
  position: relative;
  box-shadow: 0px 15px 55px rgba(103, 151, 255, 0.2);
  border-radius: 5px;
  flex: 0 0 calc(25% - 20px);
  .picture {
    width: 100%;
    margin: 0 auto;
    // height: 200px;
    padding-top: calc(175 / 320 * 100%);
    overflow: hidden;
    border-radius: 5px;
    background: #d8d8d8;
    // transform: translateY(-40px);
    top: -40px;
    position: relative;
    box-shadow: 0px 11px 37px rgba(103, 151, 255, 0.3);
    display: flex;
    justify-content: center;
    align-items: center;
    &_open {
      // transform: translateY(10px);
      top: 10px;
      box-shadow: none;
    }
    .photo {
      position: absolute;
      display: inline-block;
      object-fit: cover;
      height: 100%;
      width: 100%;
      top: 0;
      left: 0;
      &.blur {
        filter: blur(8px);
      }
    }
    .sk-video-module {
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      position: absolute;
      &_video {
        video {
          object-fit: fill;
        }
      }
      &_video-muted {
        position: absolute;
        z-index: 50;
        right: 13px;
        top: 50%;
        width: 30px;
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: rgba(255, 255, 255, 0.5);
        transform: translate3d(0, -20px, 0);
        border-radius: 50%;
        svg {
          width: 17px;
          height: 14px;
        }
      }
    }
    .picture-overlay {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      z-index: 10;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      background: linear-gradient(0.27deg, rgba(78, 17, 106, 0.4) 0.95%, rgba(56, 2, 81, 0) 98.7%);
      cursor: pointer;
      .gift-label {
        width: 100%;
        height: 50%;
        position: relative;
        svg {
          position: absolute;
          top: -2px;
          right: -2px;
        }
      }
      .description-block {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        background: linear-gradient(0.48deg, #323232 0.95%, rgba(66, 66, 66, 0) 98.7%);
        width: 100%;
        box-sizing: border-box;
        padding: 5px 16px 15px;
        .title {
          width: 68%;
          font-family: 'Roboto';
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 18px;
          letter-spacing: 0.293125px;
          color: #ffffff;
          margin: 0;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        .rating {
          font-size: 11px;
          color: #ffffff;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          p {
            display: inline-block;
            margin-left: 5px;
            font-size: 11px;
            color: #ffffff;
          }
        }
        .sk-star-rating {
          .sk-star-rating_item {
            svg {
              width: 14px;
              height: 14px;
            }
          }
        }
        .price-block {
          width: 100%;
          margin-top: 5px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          img {
            width: 14px;
            height: auto;
          }
          span {
            display: inline-block;
            margin-left: 5px;
            font-family: 'Roboto';
            font-style: normal;
            font-weight: bold;
            font-size: 14px;
            letter-spacing: 0.293125px;
            color: #ffffff;
            sup {
              top: -0.3em;
              font-size: 9px;
            }
          }
        }
      }
    }
    &:hover {
      .open-dropdown {
        opacity: 1;
        transform: translateX(-50%) translateY(0);
      }
    }
  }
  .mark {
    box-sizing: border-box;
    // border-bottom: solid 1px #fff;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    z-index: 4;

    &:before,
    &:after {
      position: absolute;
      width: 0;
      height: 0;
      content: '';
      top: 100%;
      left: 50%;
      margin-left: -13px;
      border-style: solid;
      border-width: 7px 13px 0 13px;
    }

    &:before {
      border-color: rgba(0, 0, 0, 0.15) transparent transparent transparent;
      margin-top: 1px;
    }

    &:after {
      // margin-top: px;
      border-color: #fff transparent transparent transparent;
    }
  }
  .open-dropdown {
    color: #fff;
    position: absolute;
    left: 50%;
    bottom: 12px;
    font-weight: 300;
    bottom: 1px;
    font-size: 24px;
    font-weight: bold;
    line-height: 0.25;
    z-index: 50;
    cursor: pointer;
    opacity: 0;
    transform: translateX(-50%) translateY(-2px);
    transition: transform 0.4s, opacity 1s;
  }
  @media screen and (max-width: 1320px) {
    flex: 0 0 calc(33% - 20px);
  }
  @media screen and (max-width: 1020px) {
    flex: 0 0 calc(50% - 20px);
  }
  @media screen and (max-width: $breakpoint-sm) {
    flex: 0 0 calc(100% - 20px);
    .picture .picture-overlay .description-block .title {
      width: 50%;
    }
  }
}
