@import 'utils/styles/_variables.scss';

.sk-lec-study-materials {
  &__public-state {
    margin: 0 20px 0 0;
    min-height: 18px;
    width: 56px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .extras-only-sm {
      display: none;
    }
    &__helper-title {
      font-family: Roboto, Ubuntu, Helvetica, Arial, sans-serif;
      font-size: 14px;
      line-height: 20px;
      color: #6D7C90;
    }
    @media screen and (max-width: $breakpoint-sm) {
      justify-content: start;
      width: 100%;
      margin-right: 0;
      > svg {
        margin: 0 9px;
      }
      .extras-only-sm {
        display: flex;
        align-items: center;
      }
      .sk-tooltip-trigger {
      	margin-left: 5px;
      }
    }
  }
}
