.sk-c-module-structure {
  &__content {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin-bottom: 31px;
  }
  &__footer {
    display: flex;
    justify-content: flex-end;
    .sk-content-nav_item:last-child {
      button {
        position: relative;
        top: -0.5px;
        > span:last-child {
          position: relative;
          top: -1px;
        }
      }
    }
  }
  padding-bottom: 36px;
  position: relative;
  &__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    width: 42px;
    height: 42px;
    background: var(--color-secondary-1);
    cursor: pointer;
    &:hover {
      background: var(--color-white);
    }
  }
}
