.sk-ma-pw-change {
  display: flex;
  flex-direction: column;
  margin-bottom: 107px;
  &__inner {
    flex: 1;
    padding: 80px 75px 80px;
  }
}

