.sk-discount-duration {
  display: flex;
  justify-content: space-between;
  background: #F5F6FC;
  border-radius: 10px;
  padding: 12px 23px;
  margin: 20px 0;
  &__selector {
    width: 22.5%;
  }
}
