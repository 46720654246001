.sk-uploadIcon{
  path{
    fill: var(--purple-medium);
  }
  &:hover{
    path{
      fill: #ffffff;
    }
  }
}
