.sk-auth-block {
  display: flex;
  align-items: center;
  &__item {
    &:first-of-type {
      margin-right: 11px;
    }
    button{
      background: transparentize(#E8E7FF, 0.8) !important;
      color: #ffffff !important;
      border: none !important;
    }
    &:hover{
      button{
        background: transparentize(#55d5ca, 0.8) !important;
      }
    }
  }
}
