.sk-web-room {
  height: 100%;
  &-not-created {
    height: 100%;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    &__card {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 25px 56px;
    }
   
  }
}
