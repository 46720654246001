@import '../../../../utils/styles/variables';

.sk-course-delete-modal {
  padding: 15px 20px;
  &__header {
    margin-bottom: 24px;
    text-align: center;
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 43px;
     }
  }
  &__heading {
    color: var(--color-night-rider);
    letter-spacing: 0.12px;
    text-align: center;
    @media screen and (max-width: $breakpoint-sm) {
     font-size: var(--text-fontSize-body)
    }
  }
  &__content {
    display: flex;
    justify-content: space-between;
    max-width: 75%;
    margin: 0 auto;
    @media screen and (max-width: $breakpoint-sm) {
      padding: 0 28px;
     }
  }
 
}
