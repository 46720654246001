@import 'utils/styles/_variables.scss';
.sk-edit-event-form {
  .sk-calendar__input {
    margin-bottom: 20px;
  }

  .sk-calendar__datePicker {
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    margin-top: 20px;
  }

  .sk-calendar__button {
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 20px;
    margin-top: 40px;
  }

  @media screen and (max-width: $breakpoint-xs) {
    .sk-calendar__datePicker {
      flex-direction: column;
      .sk-calendar__selectMenu {
        width: 100%;
      }
    }
    .sk-calendar__button {
      flex-direction: column;
      > button {
        width: 100%;
        &:first-child {
          margin-bottom: 30px;
        }
      }
    }
  }
}

.sk-time {
  div {
    input {
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 14px;
        background-size: 100%;
        cursor: pointer;
        z-index: 2;
      }
      &::-webkit-outer-spin-button {
        -webkit-appearance: none;
        -moz-appearance: none;
        appearance: none;
        width: 30px;
        z-index: 2;
      }
    }
  }
}
