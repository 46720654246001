.sk-admin-companies-list {
  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__sidebarButton {
      width: 7%;
    }
  }
  &__pagination {
    display: flex;
    justify-content: center;
  }
}
