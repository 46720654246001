%box-shadow {
  box-shadow: 0px 3px 22px rgba(91, 80, 126, 0.229506);
  border-radius: 50%;
}

.quiz-icon-with-round {
  overflow: visible;
  & > .outlined {
    display: initial;
    @extend %box-shadow;
  }
  & > .filled {
    display: none;
    @extend %box-shadow;
  }
  &:hover {
    & > .outlined {
      display: none;
    }
    & > .filled {
      display: initial;
    }
  }
}

.quiz-icon {
  overflow: visible;
  & > .outlined {
    display: initial;
  }
  & > .filled {
    display: none;
  }
  &:hover {
    & > .outlined {
      display: none;
    }
    & > .filled {
      display: initial;
    }
  }
}
