@import 'utils/styles/text.scss';

.sk-experts-cabinet-cancel-subscription {
  display: flex;
  flex-direction: column;
  align-items: center;
  > .paragraph-sk {
    @extend %h3;
    text-align: center;
    &_small {
      @extend %p2;
    }
    &:nth-child(2) {
      margin: 19px 0 11px;
    }
  }
  &__actions {
    display: flex;
    justify-content: center;
    margin-top: 36px;
    > button:first-child {
      margin-right: 30px;
    }
  }
}
