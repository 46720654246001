@import '../../../utils/styles/variables.scss';
@import 'utils/styles/text.scss';

.sk-landing-page__comments{
  align-items: center;
  justify-content: space-between;
  position: relative;
  @media screen and (max-width: $breakpoint-lg){
    padding: 0;
    margin-top: 80px !important;
  }
  @media screen and (max-width: $breakpoint-xs){
    margin-top: 24px !important;
    padding: 0;
  }
  &_background{
    position: absolute;
    top: 0;
    right: 0;
  }
  &_container{
    .row{
        width: 100%;
        display: flex;
        justify-content: space-between;
        // align-items: center;
        flex-wrap: wrap;
        @media screen and (max-width: $breakpoint-md){
          grid-template-columns: 1fr;
          grid-auto-rows: 1fr 4fr;
        }
        .col{
          & > h2 {
            @extend %h2;
            font-weight: bold;
            margin-bottom: 35px;
          }
          &:first-child{
            width: 35%;
            padding-top: 60px;
            @media screen and (max-width: $breakpoint-md){
              width: 100%;
              text-align: center;
              margin-bottom: 15px;
              padding-top: 0;
            }
          }
          &:last-child{
            overflow: hidden;
            width: 60%;
            @media screen and (max-width: $breakpoint-md){
              width: 100%;
              text-align: center;
              padding-bottom: 25px;
            }
            .sk-landing-page__comments_card {
              width: 90%;
            }
          }
          h1{
            font-family: Roboto Slab;
            font-style: normal;
            font-weight: bold;
            font-size: 40px;
            line-height: 1.25;
            color: #20292F;
            margin-bottom: 35.7px;
          }
          p{
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 1.75;
            color: var(--text-color-landing);
            margin-bottom: 50px;
          }
           .sk-landing-page__comments_button {
            // height: auto !important;
            button {
              height: 58px !important;
            }
            &-mobile{
              display: none;
            }
            @media screen and (max-width: $breakpoint-md){
              display: none;
              &-mobile{
                display: block;
                button {
                  height: 58px !important;
                }
              }
            }
          }
        }
    }
  }
  &_carousel{
    margin-bottom: 20px;
    @media screen and (max-width: $breakpoint-xs){
      width: 100%;
    }
  }
}
