@import 'utils/styles/_variables.scss';

.sk-icon-with-counter {
  &__wrapper {
    position: relative;
  }
  &__count {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: -12px;
    top: -12px;
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background: var(--color-secondary-1);
    color: var(--color-white);
    font-size: 8px;
    font-weight: 600;
    line-height: 1;
    padding: 6px;
    @media screen and (max-width: $breakpoint-xs){
      top: -7px;
      right: -7px;
    }
  }
}
