@import 'utils/styles/_variables.scss';

.sk-lec-article-modal {
  &__inner {
    margin-bottom: 36px;
    &__metadata {
      display: flex;
      justify-content: space-between;
      & > div {
        width: 45%;
        max-width: 45%;
      }
      @media screen and (max-width: $breakpoint-xs) {
        flex-direction: column;
        & > div {
          width: 100%;
          max-width: 100%;
        }
      }
    }
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
  &__time {
    max-width: 200px;
  }
  &__item {
    &:not(:last-of-type) {
      margin-bottom: 8px;
    }
  }
}
