@import 'utils/styles/_variables.scss';
@import 'utils/styles/text.scss';

.sk-courseDetails-hero {
  position: relative;
  z-index: 10;
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: -90px;
  padding: 77px 0 0;
  #public-course-background {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
  }
  &__title {
    margin-bottom: 55px;
  }
  &__wrapper {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    margin: 0 auto;
    width: 100%;
  }
  &__header {
    flex: 0 0 auto;
    margin-bottom: 26px;
  }
  &__inner {
    position: relative;
    overflow: hidden;
  }
  &__image {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
    height: 100%;
  }
  &__goBackBtn {
    position: absolute;
    z-index: 100;
    top: 32px;
    left: 32px;
    display: flex;
    button > span {
      position: relative;
      top: -2px;
    }
  }
  @media screen and (max-width: $breakpoint-md){
    padding: 102px 0 105px;
  }
}


.sk-courseDetails-title {
  margin-top: 45px;
  &__category-subcategory {
    color: #FFFFFF;
    position: relative;
    bottom: -35px;
    > a {
      @extend %p2;
      color: #FFFFFF;
    }
  }
  &__field {
    @extend %h2;
    color: var(--color-white);
    margin-right: 15px;
    text-align: center;

    &:focus {
      outline: none;
    }
  }

  &__btn {
    padding-top: 10px;
  }
  &-h3 {
    @extend %h1;
    width: 60%;
    max-width: calc(100vw - 30px);
    color: var(--color-white);
    margin-right: 15px;
    margin-bottom: 24px;
  }
  @media screen and (max-width: $breakpoint-md) {
    margin-top: 0px;
    text-align: center;
    &__category-subcategory {
      bottom: 0;
    }
    &-h3 {
      font-size: 40px;
      text-align: center;
      margin: 0 auto;
      width: 100%;
    }
  }
  @media screen and (max-width: $breakpoint-xs) {
    &-h3 {
      word-wrap: break-word;
    }
  }
}

.sk-courseDetails-stars {
  display: flex;
  justify-content: flex-start;
  justify-items: center;
  &__row {
    width: 150px;
    margin: 15px 0;
    .sk-star-rating{
      display: flex;
      justify-content: space-between;
    }
  }
  &__procent {
    margin-left: 24px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    color: var(--color-white);
    p {
      font-weight: bold;
      margin: 0;
      span {
        font-weight: normal;
      }
    }
    &_dots {
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: #fff;
      margin: 0 8px;
    }
  }

  @media screen and (max-width: $breakpoint-md)  {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    &__procent {
      margin-top: 8px;
      margin-left: 0;
    }
  }
}
