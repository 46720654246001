@import '../../../../utils/styles/variables.scss';
@import '../../../../utils/styles/text.scss';
@import '../../../../utils/styles/functions.scss';

.input-search-container {
  width: 100%;
  padding: 20px;
  // display: flex;
  // align-items: flex-end;
  border-radius: 5px;
  height: 53px;
  &.extended {
    height: auto;
    background: linear-gradient(45deg, #2a0a34f1 16.8%, #141685f3 161.98%);
    transition: all 300ms ease;
  }
  transition: all 300ms ease;
  .sk-hero-banner_input-search__field {
    width: 60%;
    border: 0;
    @extend %p1;
    line-height: 22px;
    border-bottom: 1px solid var(--color-white);
    color: var(--color-white);
    background: transparent;
    padding-bottom: 5px;

    &::placeholder {
      font-size: inherit;
      color: inherit;
    }

    &:focus {
      outline: none;
    }
  }

  button {
    padding: 17px 47px !important;
    height: auto !important;
    font-weight: bold;
    margin-left: calculateWidth(39);
  }

  @media screen and (max-width: $breakpoint-xs) {
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;

    .sk-hero-banner_input-search__field {
      width: 100%;
      margin-bottom: 30px;
      text-align: center;
    }
  }
}

.input-search-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;

  .input-search-field {
    margin-bottom: 20px;
    padding: 10px;
    width: 80%;
    max-width: 500px;
    border: 1px solid var(--color-white);
    background: var(--color-dark-gray);
    color: var(--color-white);
    @extend %p1;

    &::placeholder {
      color: var(--color-light-gray);
    }

    &:focus {
      outline: none;
      border-color: var(--color-primary);
    }
  }

  button {
    padding: 10px 20px;
    background-color: var(--color-primary);
    color: var(--color-white);
    border: none;
    cursor: pointer;

    &:hover {
      background-color: var(--color-primary-dark);
    }
  }
}

.input-search-extended {
  .search-results {
    margin-top: 20px;
    width: 80%;
    max-width: 500px;
    padding: 20px;
    border-radius: 5px;
    color: var(--color-grey7);
    ul {
      color: var(--color-grey7);
    }
    li {
      margin-bottom: 15px;
      color: var(--color-grey7);
    }
    p {
      color: var(--color-grey10);
    }
    a, a:link, a:visited {
      color: var(--color-white);
      font-weight: 500;
      text-decoration: none;
    }
  }
}
