.sk-webinar-room {
  display: flex;
  height: 100%;
  & > div {
    flex: 1;
    display: flex;
    height: 100%;
  }
  &__main {
    display: flex;
    flex: 1;

    @media screen and (max-width: 768px) {
      flex-direction: column;
    }
  }
  &__video {
    flex: 1 1 calc(100% - 270px);
  }
  &__chat {
    flex: 0 0 270px;
  }

}