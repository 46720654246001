@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-subscription-select-billing-period {
  display: flex;
  padding-bottom: 39px;
  border-bottom: 1px solid #D7B6FF;
  text-align: left;

  > .paragraph-sk {
    @extend %p2;
    min-width: 80px;
  }
  &__switcher {
    width: 100%;
    display: flex;
    &__labels {
      font-weight: 500;
      color: #8F26AF;
      position: relative;
      top: 2px;
    }
  }

  @media screen and (min-width: $breakpoint-sm) {
    &__switcher {
      &__labels {
        &:first-of-type {
          margin-left: auto;
        }
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm) {
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 52px;
    > .paragraph-sk {
      margin-bottom: 15px;
    }
  }

}

.mui-override.sk-switch {
  width: 40px;
  height: 20px;
  margin: 0 30px;
  padding: 0;
  display: flex;
  &__switchBase {
    width: 20px;
    height: 20px;
    padding: 0;
    background: linear-gradient(276.99deg, #8F26AF -8.8%, #141685 101.98%);
    box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.3);
  }
  &__thumb {
    width: 100%;
    height: 100%;
  }
  &__track {
    background: #D7B6FF !important;
    height: 14px;
    opacity: 0.4 !important;
    border-radius: 7px;
    position: relative;
    top: 2.5px;
  }
  &__checked {}
}
