.sk-course-review {
  &__swipe {}
  &__slide {
    padding-bottom: 70px;
  }
}

.nav-controll{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 2;
  top: 0px;
  margin-bottom: 74px;
  .carousel_dot{
    display: block;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    transition: all .3s;
    box-shadow: 0px 20px 40px rgba(67, 0, 104, 0.1) !important;
    border: 1px solid #55d5ca !important;
    cursor: pointer;
    &:not(:first-child),
    &:not(:last-child){
      margin: 0 10px;
    }
    &:hover,
    &.active{
      background: linear-gradient(90deg, #30d5c8 0%, #3ad1c5 100%);
      box-shadow: 0px 20px 40px rgba(67, 0, 104, 0.1);
      transform: scale(1.5);
    }
  }
}
