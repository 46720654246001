.sk-expert-filter-type {
  display: flex;
  width: auto;
  .sk-dropdown-toggler {
    display: flex;

    .sk-filters-icon{
      margin-right: 15px;
    }
  }
}
