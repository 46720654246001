@import 'utils/styles/color.scss';
@import 'utils/styles/text.scss';

.sk-course-webinar-panel {
  z-index: 10;
  position: relative;
  display: flex;
  justify-content: space-between;
  top: -50px;
  width: 100%;

  &__webinar {
    margin-right: 16px;
    width: 54%;
  }
  &__timer {
    width: 45%;
  }
}


@media screen and (max-width: 900px){
  .sk-course-webinar-panel {
    flex-direction: column-reverse;
    top: -20px;
    &__webinar {
      margin-right: 0px;
      width: 100%;
      .mui-override.sk-card{
        padding: 20px 16px;
      }
    }
    &__timer {
      width: 100%;
      margin-bottom: 20px;
      & > div {
        width: 100%;
      }
    }
  }
}

.sk-stud-web-panel-timer {
  background: var(--color-white);
  border-radius: 8.8px;
  box-shadow: var(--box-shadow-paper-lg);
  display: flex;
  flex-direction: column;
  padding: 25px 50px;
  height: calc(100%);

  &-preview{
    // position: absolute;
    width: 100%;
    height: 180px;
    transform: translateY(-90px);
    box-shadow: 0px 11px 37px rgba(103, 151, 255, 0.3);
    border-radius: 5px;
    // img{
    //   object-fit: fill;
    // }
  }

  &__breadcrumbs{
    margin: 12px 0 18px;
    p{
      @extend %p1;
      line-height: 20px;
      color: var(--purple-dark);
    }
  }

  &-description{
    margin-bottom: 10px;
    margin-top: -80px;
  }

  &-title {
    display: flex;
    justify-content: space-between;
    width: 100%;
    &-row{
      display: flex;
      width: 65%;
      svg{
        margin-right: 16px;
      }
      p{
        @extend %p1;
        line-height: 20px;
        color: var(--base-text);
      }
    }
  }

  &-time {
    display: flex;
    justify-content: flex-end;
    width: 35%;
    align-items: flex-end;
    @extend %h3;
    line-height: 30px;
    p{
      font-weight: 500;
      margin: 0;
      font-size: 20px;
    }
    span{
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
    }
  }

  // &-date {
  //   display: flex;
  //   justify-content: space-around;
  //   color: var(--color-grey1);
  //   font-size: var(--text-fontSize-caption);
  //
  //   div {
  //     text-align: center;
  //     width: 33%;
  //
  //     &:after {
  //       content: ' ';
  //       padding-right: 5px;
  //     }
  //   }
  // }
  @media screen and (max-width: 480px){
    padding: 25px 16px;
    &-title{
      &-row{
        width: 100%;
        svg{
          margin-right: 10px;
          width: 17px;
        }
      }
    }
  }
}
