@import 'utils/styles/text.scss';
@import 'utils/styles/color.scss';
@import 'utils/styles/_variables.scss';

$prefix: sk-topping-details;

.#{$prefix}_description{
  &__top{
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 13px;
    h3{
      @extend %h3;
      font-weight: 500;
    }
  }
  &__star-raiting{
    display: flex;
    align-items: center;
    margin-bottom: 23px;
    &_title{
      display: inline-block;
      margin-left: 33px;
      @extend %p2;
      font-weight: 500;
    }
  }
  &__bottom{
    display: flex;
    justify-content: space-between;
    &_col{
      width: 45%;
      .text>div{
        @extend %p1;
        letter-spacing: 0.29px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-md){
    &__top{
      flex-direction: column-reverse;
      margin-bottom: 35px;
      button{
        margin-bottom: 35px;
      }
    }
    &__bottom{
      flex-wrap: wrap;
      &_col{
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }

  @media screen and (max-width: $breakpoint-sm){
    &__top{
      align-items: center;
      margin-bottom: 25px;
      button{
        margin-bottom: 25px;
      }
      h3{
        text-align: center;
      }
    }
    &__star-raiting{
      margin-bottom: 20px;
      &_title{
        width: 80px;
        margin: 0 0 0 auto;
        text-align: right;
      }
    }
    &__bottom{
      flex-wrap: wrap;
      &_col{
        width: 100%;
        margin-bottom: 30px;
      }
    }
  }
}
