@import '../../../utils/styles/variables.scss';
@import 'utils/styles/_variables.scss';

.sk-course-card-action-block {
  display: flex;
  width: 100%;
  flex-direction: column;
  // margin-top: -20px;
  margin-top: 0px;
  &__actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .sk-social-share{
      width: 43px;
      height: 43px;
      margin-right: 5%;
      >svg{
        width: 100%;
        height: 100%;
      }
      .sk-social-share-popover-content{
        bottom: 60px;
        top: 0;
        z-index: $z-index-modal;
      }
    }
    &_icons{
      transform: scale(0.9);
      // max-width: 102px;
      // width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .icon-with-hover{
        width: 100%;
        height: 100%;
      }
      &__price{
        display: flex;
        justify-content: center;
        align-items: center;
        transform: translate(0px, 3px);
        min-width: 86px;
        max-width: 100px;
        font-weight: var(--cyan-solid);
        // border: 1px var(--cyan-solid) solid;
        height: 38px;
        // border-radius: 19px;
        text-align: center;
        margin-right: 5px;
      }  
    }
    .button-sk{
      height: 43px;
      transform: scale(0.9);
      max-width: 140px;
    }
  }

  &__message {
    display: flex;
    justify-content: center;
    margin-top: 7px;
    margin-bottom: 7px;
  }

  &__basket {
    margin-right: 27px;
    &_disabled {
      cursor: initial;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    margin-top: 0px;
    // margin-top: -30px;
  }

}
