.sk-course-prices-conversions {
  width: 100%;
  display: flex;
  & > img {
    min-width: 20px !important;
    height: 20px;
    margin: 1px;
  }
  &__prices {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    margin-left: 30px;
    & > p {
      width: 32%;
      text-align: start;
    }
  }
}
