@import "../../utils/styles/variables";

.sk-courses {
  margin-bottom: 40px;
  height: 100%;

  &__filters {
    margin-top: 31px;
    margin-left: 1rem;
    margin-right: 1rem;
  }

  &__arrow {
    position: relative;
    right: -98%;
    width: 0;
    z-index: 3;

    &-is-show {
      position: fixed;
      bottom: 100px;
      opacity: 0;
      transition: opacity 1s ease-in-out;
    }
  }

  &__arrow-to-top {
    position: fixed;
    bottom: 100px;
    opacity: 1;
    transition: 1s;

    @media screen and (max-width: $breakpoint-lg) {
      // display: none;
    }

    @media screen and (max-width: $breakpoint-xs) {
      right: 0;
    }

    &._fixed {
      top: -95px;
      position: absolute;
      transition: 1s;
    }

    &:hover {
      cursor: pointer;
    }
  }
}
