.sk-editor-control-bar-magic {
  display: inline-block;
  height: 30px;
  cursor: pointer;
  margin: 2px 0.5em;
  opacity: 0.85;
  &:hover {
    opacity: 1;
  }
}
