@import '../../utils/styles/color';
@import 'utils/styles/_variables.scss';

.sk-user-menu {
  width: 260px;
  &-item {
    white-space: pre-line;
    height: auto;
    &__gutters.mui-override{
      padding-left: 0;
      padding-right: 0;
      overflow: visible;
      &:hover,
      &:active{
        background-color: transparent;
        color: var(--purple-solid)
      }
    }
  }
  &-padding.mui-override {
    padding: 20px;
    outline: none;
    .sk-dropdown__group{
      padding: 0;
    }
  }
  button{
    width: 100%;
    margin: 22px 0;
  }

  @media screen and (max-width: $breakpoint-xs) {
    &-padding.mui-override {
      padding: 0 20px;
    }
  }
}
