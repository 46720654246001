:root {
  --rbc-out-of-range-color: lighten(#333, 40%);
  --rbc-out-of-range-bg-color: lighten(#333, 70%);

  --rbc-calendar-border: #ddd;
  --rbc-cell-border: #ddd;

  --rbc-border-color: #ccc;

  --rbc-segment-width: percentage(1 / 7);

  --rbc-time-selection-color: white;
  --rbc-time-selection-bg-color: rgba(0, 0, 0, .50);
  --rbc-date-selection-bg-color: rgba(0, 0, 0, .10);


  --rbc-event-bg: #3174ad;
  --rbc-event-border: darken(#3174ad, 10%);
  --rbc-event-outline: #3b99fc;
  --rbc-event-color: #fff;
  --rbc-event-border-radius: 5px;
  --rbc-event-padding: 2px 5px;
  --rbc-event-zindex: 4;

  --rbc-btn-color: #373a3c;
  --rbc-btn-bg: #fff;
  --rbc-btn-border: #ccc;

  --rbc-current-time-color: #74ad31;

  --rbc-css-prefix: rbc-i;

  --rbc-today-highlight-bg: #eaf6ff;
}
