.sk-about-us-text {
  &__paragraph {
    line-height: 1.5;
  }
  &__preheading {
    margin: 24px 0;
  }
  &__weAre {
    .sk-about-us-text__paragraph {
      text-align: justify;
      margin-bottom: 8px;
      font-size: 16px;
    }
  }
  &__whyList {
    .sk-about-us-text__paragraph {
      text-align: justify;
      margin-bottom: 8px;
      font-size: 16px;
    }
  }
}
