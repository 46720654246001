@import 'utils/styles/_variables.scss';

.sk-lec-material-entity {
  display: flex;
  flex-direction: column;
  color: #6D7C90;
  &__info {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__left {
      display: flex;
      align-items: center;
      &__title {
        width: 188px;
        line-height: 20px;
        max-height: 40px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      width: 77.1%;
      display: flex;

      // Sprint 20 changes line width, with `break-all` line looks ugly.
      // word-break: break-all;

      &>span {
        line-height: 19px;
      }
      &__icon {
        margin-right: 9px;
      }
      &__duration-wrapper {
        margin-left: 35px;
      }
    }
    &__right {
      width: 22.9%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      &__controls {
        display: flex;
      }
      &__burger {
        position: relative;
        top: -2px;
        margin-left: 10px;
      }
    }
  }
  & > div:not(:first-child):last-child {
    margin: 10px 0 0;    
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 20px;
    }
  }
  & > p:last-child {
    font-size: 12px;
    line-height: 16px;
    margin: 10px 0 0;    
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 20px;
    }
  }

  // Customise react-sweet-progress-line for current component
  .react-sweet-progress-line {
    width: calc(77.1% + 120px);

    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 20px;
    }
  }
  .react-sweet-progress-symbol {
    font-family: Roboto, Ubuntu, Helvetica, Arial, sans-serif;
    font-weight: bold;
    font-size: 12px;
    letter-spacing: 1px;

    width: 22.9%;
    color: var(--purple-dark);
    justify-content: flex-end;
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 20px;
      margin-top: 0;
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    position: relative;
    display: block;
    padding: 20px 9px !important;
    box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.15);


    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 20px;
    }
    &__info {
      flex-direction: column;
      align-items: flex-start;
      &__left {
        margin-bottom: 14px;
        &__title {
          @media screen and (max-width: $breakpoint-sm) {
            max-height: none;
            width: 100%;
            display: block;
          }
        }
        &__duration-wrapper {
          position: absolute;
          right: 8px;
          bottom: 20px;
          > .sk-duration-component__duration {
            font-size: 14px;
            line-height: 20px;
            color: var(--icons);
          }
        }
      }
      &__right {
        &__controls {
        }
        &__burger {
          position: absolute;
          top: 11px;
          right: 9px;
        }
      }
    }
  }
}

.inactive {
  color: #B9BDBF;
}

.failed {
  color: #30d5c8;
  padding-left: 28px;
  @media screen and (max-width: $breakpoint-sm) {
    padding-left: 0;
  }
}

.any-margin {
  margin: 0px;
}
