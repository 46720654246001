.mui-override.sk-expandable-sidebar-menu {
  &-item {
    position: relative;
    &-icon {
      margin-right: 0;
    }
    &-text {
      font-size: var(--text-fontSize-small);
      color: var(--text-color-basic);
    }
  }
  &-list {
    padding-left: 8px;
  }
}

.mui-override.sk-fullWidth {
  width: 100%;
}
