.sk-admin-discount-details {
  padding: 30px 7vw;

  &__redumption {
    background: #F5F6FC;
    border-radius: 10px;
    padding: 12px 23px;
    margin: 20px 0;
    &__input {
      width: 45%;
    }
  }
}
