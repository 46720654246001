@import 'utils/styles/text.scss';
@import 'utils/styles/color.scss';
@import 'utils/styles/_variables.scss';

.sk-toppings-carousel_content__area {
  left: 0;
  right: 0;
  height: 100%;
  z-index: 3;
  width: 40%;
  padding: 30px 70px 0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.16);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__title {
    @extend %h3;
    font-weight: 500;
    width: 100%;

  }

  &__features {
    width: 100%;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-row-gap: 15px;
    margin-top: 16px;
  }

  &_feature {
    display: flex;
    align-items: center;
    p, span{
      margin: 0;
      width: auto !important;
    }
    svg{
      width: 23px;
      height: 23px;
      margin-right: 8px;
    }
  }

  &__description {
    font-size: 18px;
    color: #999;
    margin-top: 20px;
    max-width: 500px;
    overflow: hidden;
    >div{
      max-height: 150px;
    }
    >div::-webkit-scrollbar {
        width: 3px;
        background: transparent;
    }
    >div::-webkit-scrollbar-thumb {
      width: 3px;
      background: var(--purple-gradient);
    }
  }
  .mui-override.sk-tabs{
    bottom: 0;
    background: transparent;
    padding: 0;
    box-shadow: none;
    height: auto;
    .mui-override.sk-tabs-tab{
      width: 40%;
      height: auto;
      &_wrapper{
        span{
          padding: 0;
        }
      }
    }
  }
  @media screen and (max-width: $breakpoint-md){
    &__description {
      >div{
        max-height: 100%;
        height: auto;
        overflow-y: visible !important;
      }
    }
  }
}
