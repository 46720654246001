.sk-content-header {
  display: flex;
  align-items: center;
  height: 90px;
  width: 100%;

  &_withHero {
    justify-content: space-between;
    align-items: flex-start;
    padding-top: 33px;
  }

  &_sidebarToogle {
    display: flex;
    p {
      z-index: 1;
      color: var(--color-white);
      margin-left: 20px;
    }
    .black {
      color: var(--color-black);
    }
  }

  &__breadcrumbs {
    display: inline-flex;
    color: var(--text-color-secondary);
    margin-left: 24px;
  }
}
