.sk-suggestions {
  &_popper {
    position: absolute;
    z-index: 1300;
    width: 100%;
    top: 100%;
    left: 0;
    margin-top: 0;
  }
}
