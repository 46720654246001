@charset "UTF-8";
/* headers */
/* paragraphs */
.sk-admin-deleted-course-view__content__reasons > p:first-child, .sk-admin-deleted-course-view__content__reasons__reason > p:first-child, .sk-admin-deleted-course-view__content__reasons__reason > p:last-child, .sk-admin-deleted-course-detail > p:last-child {
  font-family: Roboto, Ubuntu, Helvetica, sans-serif;
  font-size: 16px;
  line-height: 26px;
  color: var(--base-text);
  font-weight: normal;
  font-style: normal; }

.sk-admin-deleted-course-detail > p:first-child {
  font-family: Roboto, Ubuntu, Helvetica, sans-serif;
  font-size: 12px;
  font-weight: normal;
  font-style: normal;
  color: var(--base-text); }

.sk-admin-deleted-course-view__content {
  padding: 48px 75px; }
  .sk-admin-deleted-course-view__content__details {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 17px; }
  .sk-admin-deleted-course-view__content__reasons > p:first-child {
    margin-bottom: 8px; }
  .sk-admin-deleted-course-view__content__reasons__reason {
    padding-left: calc(1em + 24px);
    margin-bottom: 20px;
    text-indent: -.7em; }
    .sk-admin-deleted-course-view__content__reasons__reason > p:first-child {
      margin-bottom: 10px; }
    .sk-admin-deleted-course-view__content__reasons__reason > p:last-child {
      color: #000000; }
    .sk-admin-deleted-course-view__content__reasons__reason::before {
      content: '•';
      position: relative;
      top: 19px;
      right: 30px;
      color: #7145D8; }
  .sk-admin-deleted-course-view__content__button {
    margin-top: 20px; }

.sk-admin-deleted-course-detail {
  margin-bottom: 15px; }
