.sk-editor-controls-bar {
  display: flex;
  margin-bottom: 5px;
  flex-wrap: wrap;
}

.sk-editor-view-switcher {
  & > img {
    cursor: pointer;
  }
}
