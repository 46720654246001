.sk-content-nav {
  display: inline-flex;
  font-size: 15px;
  align-items: center;

  &_button {
    font-weight: inherit;
    font-weight: 300;
    display: inline-flex;
    align-items: center;
    .sk-content-nav_label {
      letter-spacing: 0em;
      transition: text-shadow 0.3s;
    }
    &:hover {
      .sk-content-nav_label {
        text-shadow: 0px 0 0.15px var(--color-primary-1),
          0px 0 0.15px var(--color-primary-1);
      }
    }
  }
  &_button {
    &-next {
      &_withSeparator {
        margin-left: 16px;
      }
    }
    &-prev {
      &_withSeparator {
        margin-right: 16px;
      }
    }
  }

  &_button,
  &_item,
  &_item {
    .sk-content-nav_icon__right {
      // transform: rotate(180deg);
    }
  }
  &_button,
  &_item {
    transition: all 0ms 20ms ease-in-out;
    cursor: pointer;
  }

  &_icon {
    display: inline-flex;
    &__left {
      margin-right: 7px;
    }
    &__right {
      margin-left: 7px;
    }

    &_withoutSeparator {
      margin: 0;
    }
  }

  &_separator {
    position: relative;
    margin: 0 1px;
    color: var(--color-primary-1);
    height: 24px;
    width: 1px;
    display: flex;
    &:after {
      display: inline-flex;
      content: "";
      position: absolute;
      top: 50%;
      right: 50%;
      height: 24px;
      width: 1px;
      background: var(--color-primary-1);
      transform: translate(-50%, -50%);
    }
  }

  &_label {
    & span {
      letter-spacing: 0.01em;
    }
  }
}
