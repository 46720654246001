@import 'utils/styles/text.scss';
@import 'utils/styles/color.scss';

$prefix: sk-topping-details;

.#{$prefix}{
  padding-top: 25px;
  &_breadcrumbs{
    margin-bottom: 30px;
    a, span {
      @extend %p2;
      line-height: 20px;
      color: var(--base-text);
    }
  }
}
