@import 'utils/styles/_variables.scss';

.sk-image-with-loader {
  width: 100%;
  height: 100%;
  position: relative;

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    opacity: 0.5;
    object-fit: contain;
  }

  &__loader {
    position: absolute;
    top: calc(50% - 66px);
    left: calc(50% - 66px);
  };

  @media screen and (max-width: $breakpoint-xs) {
    & > img {
      width: 206px;
      height: calc(206px*0.74);
    }
  }
}

img.sk-image-with-loader__visible {
  opacity: 1;
}
