@import '../../../../utils/styles/variables';
@import 'utils/styles/text.scss';

.sk-buy-course-block-info-course-details {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  > div {
    margin-bottom: 15px;
    display: flex;
    align-items: center;
    > svg {
      margin-right: 10px;
    }
    > .paragraph-sk {
      @extend %p2;
    }
    > .sk-duration-component__duration {
      width: auto !important;
      @extend %p2;
    }
  }
  @media screen and (max-width: $breakpoint-md) {
  }
  @media screen and (max-width: $breakpoint-xs) {
  }
}
