@import 'utils/styles/text.scss';

.sk-social-share {
    &__title {
      @extend %p1;
      align-items: center;
      text-align: center;
    }

    &__list {
        display: flex;
        justify-content: space-between;
        width: 100%;
        > li {
            width: 41px;
        }
    }
}
