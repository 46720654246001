@import 'utils/styles/color.scss';
@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';
@import 'utils/styles/functions.scss';

.sk-student-course_card{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #FFFFFF;
  border: 1px solid #F1F6FC;
  box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.15);
  border-radius: 6px;
  margin-bottom: 20px;
  padding: 15px 25px;
  transition: box-shadow .3s;

  &__row{
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: calc(100% - 105px - 32px - 180px);
    margin-left: 32px;
  }

  &__picture{
    width: 105px;
    height: 62px;
    overflow: hidden;
    border-radius: 5px;
    grid-area: picture;
    background: #fff;
    img{
      min-height: 70px;
      object-fit: cover;
      width: 100%;
      height: 100%;
    }
  }

  &__title{
    width: calc(100% - 110px);
    @extend %p1-medium;
    line-height: 1.2;
    letter-spacing: 0.3px;
    transition: color .3s;
    display: inline-block;
    grid-area: title;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
    margin-top: 0;
    word-break: break-all;
  }

  &__status{
    grid-area: status;
    display: flex;
    justify-content: flex-end;
    min-width: 176px;
  }

  &__status_label {
    @extend %p2-bold;
    color: var(--dark-stroke);
    padding: 8px 20px;
    background: #E8E7FF;
    border-radius: 25.5px;
    font-weight: normal;
    line-height: 1.1;
    text-align: center;
    margin: 0;
    width: 75%;
  }

  &__active{
    grid-area: active;
    display: flex;
    justify-content: space-between;
  }

  &__action{
    display: flex;
    width: 85px;
    align-items: center;
    grid-area: action;
    justify-content: flex-start;
    .sk-link:first-child{
      margin-right: 45px;
    }
    .sk-link{
      position: relative;
      .tooltip{
        position: absolute;
        z-index: 15;
        top: calc(100% - 10px);
        left: 100%;
        background: #FFFFFF;
        box-shadow: 0px 5px 30px rgba(103, 151, 255, 0.3);
        border-radius: 5px;
        padding: 10px 20px;
        display: none;
        white-space: nowrap;
      }
      &:hover{
        .tooltip{
          display: inline;
        }
      }
    }
  }

  &:hover {
    box-shadow: 0px 40px 120px rgba(103, 151, 255, 0.4);
  }
  @media screen and (max-width: $breakpoint-md){
    align-items: flex-start;
    &__title {
      width: 100%;
    }
    &__picture{
      max-width: 150px;
      max-height: 90px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__row {
      width: calc(100% - 105px - 32px - 140px);
      display: block;
    }
    &__action{
      width: 15%;
      justify-content: space-between;
      .sk-link:first-child{
        margin-right: 30px;
      }
    }
    &__status{
      height: auto;
      width: 136px;
      min-width: 136px;
      &_label{
        width: 100%;
      }
    }
  }
  @media screen and (max-width: $breakpoint-sm){
    flex-direction: column;
    position: relative;
    padding: 20px;
    &__picture{
      max-width: 100%;
      max-height: 100%;
      width: 100%;
      height: 100%;
      margin: 0 auto 12px;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__row,
    &__action,
    &__title{
      width: 100%;
      margin: 0;
    }
    &__title{
      margin: 0;
      margin-bottom: 12px;
    }
    &__action{
      display: block;
      .sk-link{
        width: 100%;
        height: 100%;
        display: inline-block;
        &:first-child{
          margin-bottom: 16px;
        }
        .mui-override.button-sk{
          padding: 8px 20px;
          width: 100%;
        }
      }
    }
    &__status{
      position: absolute;
      top: 8px;
      right: 8px;
      width: auto;
    }
  }
}
