@import 'utils/styles/text.scss';

.sk-read-more {
  &__button {
    margin-top: 10px;
    @extend %p1;
    font-weight: bold;
    color: var(--purple-dark);
  }
}
