@import '../../../../utils/styles/variables';

.sk-del-confirmation-modal {
  &_header {
    margin-bottom: 24px;
    text-align: center;
    @media screen and (max-width: $breakpoint-sm) {
      margin-bottom: 43px;
     }
  }
  &_heading {
    color: var(--color-night-rider);
    letter-spacing: 0.12px;
    text-align: center;
    @media screen and (max-width: $breakpoint-sm) {
     font-size: var(--text-fontSize-body)
    }
  }
  &_content {
    display: flex;
    justify-content: space-between;
    @media screen and (max-width: $breakpoint-sm) {
      padding: 0 28px;
     }
  }
 
}
