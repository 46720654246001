@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-topping-status-bar {
  margin-top: 30px;
  &__label {
    margin-top: 30px;
    display: flex;
    justify-content: space-between;
    > p {
      @extend %p2-bold;
      color: var(--dark-text);
      &:last-child {
        width: 169px;
        height: 26px;
        background: #E8E7FF;
        border-radius: 25.5px;
        font-weight: normal;
        line-height: 26px;
        text-align: center;
      }
    }
  }

  > span {
    display: inline-block;
    margin-top: 25px;
    @extend %p1;
  }

  @media (min-width: $breakpoint-md) {
    width: 50%;
    margin-right: 22px;
    margin-top: 0;
  }
}
