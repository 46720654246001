.sk-purchasedCourses {
  &-complete {
    width: 100%;
    display: flex;
    box-shadow: var(--box-shadow-paper-lg);
    border-radius: 8.8px;
    background: var(--color-white);
    margin-top: 20px;
    padding: 25px 50px;
    margin-bottom: 20px;

    &-text {
      padding-right: 50px;
    }
  }

  &-wrapper {
    max-width: 1110px;
    width: 80.7%;
    margin: 0 auto;

    &-lectures {
      margin-top: 40px;
      box-shadow: var(--box-shadow-paper-lg);
      border-bottom-right-radius: 8.8px;
      border-bottom-left-radius: 8.8px;
    }
  }
  &-empty-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    &__item {
      &:not(:last-of-type) {
        margin-bottom: 24px;
      }
    }
  }

  &__empty {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
  }
}
