.sk-action-status-card {
  padding: 45px 65px;
  margin: auto;
  max-width: 400px;
  &__header {
    padding-top: 10px;
    margin-bottom: 35px;
    text-align: center;
  }
  &__heading {
    line-height: 1.46;
    margin-bottom: 14px;
    text-align: center;
  }
  &__subheading {
    line-height: 1.5;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
  &__icon {
    display: flex;
    justify-content: center;
    margin-bottom: 16px;
  }
}
