@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-topping-edit-info {
  display: flex;
  &__vertical-line {
    position: absolute;
    left: 50%;
    top: 0;
    height: 100%;
    width: 2px;
    background: #E8E7FF;
  }

  @media (max-width: $breakpoint-md) {
    flex-direction: column-reverse;
    margin-top: 140px;
    &__vertical-line {
      display: none;
    }
  }
  @media (max-width: $breakpoint-sm) {
    margin-top: 66px;
  }
}
