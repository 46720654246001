.sk-web-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__action {
    span {
      padding: 0 3px;
    }
  }
}
