.sk-course-create-faq {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 90px 0;
  height: 100%;
  background-size: 95% 95%;
  &_wrapper {
    max-width: 1110px;
    width: 80.2%;
    align-self: stretch;
    display: flex;
  }
  &_card {
    align-self: stretch;
    padding: 58px 95px 61px 95px;
  }

  &_contetnt {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  &_header {
    margin-bottom: 28px;
  }
  &_heading {
    text-align: center;
  }

}

