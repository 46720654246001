.sk-sidebar-menu-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  flex: 1;
  padding: 16px 0px 16px 0px;
  border-bottom: 1.2px dashed var(--color-grey9);
  &_withoutBorder {
    border-bottom: 0;
  }
  &__label {
    display: flex;
    align-items: center;
    text-align: left;
  }
  &__icon {
    margin-right: 10px;
  }

  &__name {
    display: flex;
    justify-content: flex-start;
    align-items: center;
  }

  &__btn {
    position: relative;
    flex: 1;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    &_expandable {
      margin-bottom: 14px;
    }
  }

  &__icon {
    margin-right: 15px;
  }
}
