.sk-send-msg-form {
  &-wrapper {
    &__heading {
      margin-bottom: 20px;
      text-align: center;
    }
  }
  &__header {
    position: relative;
    display: flex;
  }
 
  &__inner {
    margin-bottom: 32px;
  }
}
