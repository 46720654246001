.sk-editor-inline-toolbar-link-content {
  display: flex;
  padding: 15px 10px 10px;
  flex-direction: column;
  align-items: center;
  & > button {
    max-width: 60px;
    margin-top: 15px;
  }
}
