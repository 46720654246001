@import 'utils/styles/_variables.scss';
@import 'utils/styles/text.scss';

.sk-cur-details {
  position: relative;
  display: flex;
  padding: 12px 25px;
  clear: both;
  border-top: 1px solid var(--purple-light);
  &__icon {
    position: relative;
    height: 25px;
    margin-right: 25px;
  }
  &__title {
    width: -webkit-fill-available;
    @extend %p2;
    color: var(--dark-text);

    &.is-active * {
      color: var(--purple-dark);
    }
  }
  &__duration {
    @extend %p2;
    color: var(--medium-stroke);
    min-width: 80px;
    display: flex;
    margin-right: 59px;
  }

  &__gift {
    position: relative;
    right: 2px;
    top: 3px;
    color: var(--medium-stroke);
    min-width: 19px;
    display: flex;
  }

  @media screen and (max-width: $breakpoint-xs) {
    &__icon {
      top: 0;
      margin-right: 13px;
    }
    &__duration {
      position: relative;
      margin-right: 0;
      margin-left: 12px;
      min-width: fit-content;
      height: fit-content;
      line-height: 18px;
      > .sk-duration-component__duration {
        text-align: end;
      }
    }
    &__gift {
      right: -8px;
    }
  }
}
