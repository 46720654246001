.sk-ai-suggestions {
    &_inner {
      display: flex;
      flex-flow: column;
      margin-bottom: 31px;
    }
    &_row {
      width: 100%;
      display: flex;
      justify-content: space-between;
      margin-bottom: 10px;
  
      &.withMargin {
        margin-bottom: 28px;
      }
      input[type=email] {
        width: 50%;
      }
    }
    &_footer {
      display: flex;
      justify-content: center;
    }
  }
  