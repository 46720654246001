@import 'utils/styles/text.scss';
@import 'utils/styles/color.scss';
@import 'utils/styles/_variables.scss';

$prefix: sk-topping-details;

.#{$prefix}__courses{
  width: 100%;
  margin-top: 63px;
  &_title{
    @extend %h3;
    font-weight: 500;
    color: var(--dark-text);
  }
  .sk-course-carousel__swipe-row.grid.landing {
    justify-content: space-around;
    .sk-course-carousel__swipe-item {
      display: flex;
      justify-content: center;
      flex-basis: calc(33% - 20px);
      @media screen and (max-width: 1200px) {
        flex-basis: calc(50% - 20px);
      }
    }
  }
  .sk-course-carousel__nav-btn_prev{
    left: -5% !important;
  }
  .sk-course-carousel__nav-btn_next{
    right: -5% !important;
  }
  &_carousel{
    margin-top: 33px;
    .grid.landing{
      padding: 20px 0;
    }
    .sk-course-carousel__swipe-row.grid.landing 
    .sk-course-carousel__nav-btn_prev{
      left: -5%;
    }
    .sk-course-carousel__nav-btn_next{
      right: -5%;
    }
    .sk-course-card__background.mui-override{
      box-shadow: 0px 10px 40px rgba(103, 151, 255, 0.2)
    }
  }

  @media screen and (max-width: $breakpoint-md){
    margin-top: 10px;
  }
}
