@import 'utils/styles/_variables.scss';

.slider {
  display: flex;
  position: relative;
  margin-top: 25px;

  &__container {
    display: flex;
    padding: 0 40px;
    transition: transform 300ms;
    z-index: 3;
    width: 100%;
    &:hover .item {
       transform: translateX(-8%);
    }
  }

  &:not(.slider__open) .item:hover .show-details-button {
    opacity: 1;
  }

  .item:hover ~ .item {
     transform: translateX(8%);
  }

  .item:hover {
    transform: scale(1.15) !important;
  }

  &:not(.slider__open) .item:hover {
    box-shadow: 0px 15px 55px rgba(103, 151, 255, 0.4);
  }

  &.slider__open{
    margin-top: -20px;
    .item:hover ~ .item,
    .item:hover,
    .item{
      transform: none !important;
    }
    .item{
      height: calc(100% + 20px);
    }
  }

  @media screen and (max-width: $breakpoint-sm){
    margin-top: 0;
    &.slider__open{
      margin-top: -10px;
      .item{
        margin-top: -40px;
        height: calc(100% + 60px);
      }
    }
    &__container {
      padding: 10px 10px 0;
    }
    .item:hover {
      transform: none !important;
    }
  }
}
