@import "../../../../utils/styles/text";
@import "../../../../utils/styles/color";
@import "../../../../utils/styles/variables";
@import "../../../../utils/styles/functions";

$catalogBackground: #610fb6;

.mui-override.sk-h-catalog-expandable-menu {
  &-item {
    position: relative;
    cursor: pointer;
    &-icon {
      margin-right: 0;
    }
    &_link {
      color: var(--text-color-basic) !important;
    }
  }

  &-content {
    position: absolute;
    left: 100%;
    top: 0;
    z-index: 1000;
    width: 100%;
    min-width: 200px;
    max-width: 300px;
    max-height: 85vh;
    overflow: auto;
  }
  &__link {
    display: flex;
  }
}

.sk-category-overlay {
  width: 100vw;
  height: 100vh;
  z-index: 2000;
  position: fixed;
  left: 0;
  top: 0;
  background: transparentize($catalogBackground, 0.05);
  display: none;
  &.show {
    display: block;
    top: 0;
    bottom: 0;
    position: fixed;
    overflow-y: scroll;
    overflow-x: hidden;
  }
  .button-close {
    position: absolute;
    right: calculateWidth(125);
    top: 20px;
    cursor: pointer;
  }
  &__input-search {
    display: none;
  }
  @media screen and (max-width: $breakpoint-md) {
    padding-top: 20%;
  }
  @media screen and (max-width: $breakpoint-sm) {
    padding-top: 15%;
    &__input-search {
      display: block;
    }
    .button-close {
      right: 20px;
    }
  }
  .list {
    display: flex;
    width: 60%;
    height: 100%;
    justify-content: center;
    align-items: center;
    margin: 0 auto;
    @media screen and (max-width: $breakpoint-md) {
      align-items: flex-start;
      width: 90%;
    }
    > div {
      display: flex;
      justify-content: center;
      width: 100%;
      .subitems-list,
      .items-list {
        width: 50%;
      }
      .subcategory {
        display: block;
        width: 100%;
        padding: 0;
        .sk-h-catalog-expandable-menu-item {
          background-color: transparent !important;
          &:before {
            position: absolute;
            left: -2px;
            content: "";
            top: -20%;
            transform: translateY(20%);
            background: var(--cyan-gradient);
            width: 5px;
            height: 100%;
            border-radius: 10px;
            display: none;
          }
          &:hover {
            &:before {
              display: block;
            }
            .paragraph-sk {
              color: var(--cyan-solid) !important;
            }
          }
          .paragraph-sk {
            color: var(--color-white) !important;
            @extend %h3;
          }
        }
      }
      .items-list {
        .sk-h-catalog-list-categories_title {
          display: flex;
          justify-content: space-between;
          align-items: center;
          &__arrow {
            width: 25%;
            cursor: pointer;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding-right: 20px;
          }
          &.open,
          &:hover {
            .sk-h-catalog-list-categories_title__arrow svg path {
              stroke: var(--cyan-solid);
            }
            .sk-h-catalog-expandable-menu__link {
              .paragraph-sk {
                color: var(--cyan-solid) !important;
                span {
                  color: inherit;
                }
              }
            }
          }
          .mui-override.sk-dropdown-item__gutters {
            padding: 4px 16px;
          }
          @media screen and (max-width: $breakpoint-sm) {
            &:hover {
              .sk-h-catalog-list-categories_title__arrow svg path {
                stroke: var(--cyan-solid);
              }
              .sk-h-catalog-list-categories_title__arrow svg {
                transform: none;
              }
              &.open {
                .sk-h-catalog-list-categories_title__arrow svg {
                  transform: rotate(90deg);
                }
              }
            }
          }

          @media screen and (max-width: $breakpoint-xs) {
            .mui-override.sk-dropdown-item__gutters {
              padding-left: 0 !important;
            }
            .sk-h-catalog-list-categories_title__arrow {
              padding-right: 0;
            }
            &:hover {
              .sk-h-catalog-expandable-menu__link {
                .paragraph-sk {
                  color: var(--cyan-solid) !important;
                }
              }
            }
          }
        }
        .sk-h-catalog-list-categories_web {
          display: block;
        }
        .sk-h-catalog-list-categories_mobile {
          display: none;
          .sk-h-catalog-expandable-menu__link {
            .sk-h-catalog-expandable-menu-item:before {
              display: none !important;
            }
          }
        }
        .sk-h-catalog-expandable-menu__link {
          position: relative;
          display: inline-block;
          width: 100%;
          .sk-h-catalog-expandable-menu-item:before {
            position: absolute;
            left: -15px;
            content: "";
            top: 50%;
            transform: translateY(-50%);
            background: var(--cyan-gradient);
            width: 10px;
            height: 10px;
            border-radius: 50%;
            display: none;
          }
          &.category-all:hover {
            .sk-h-catalog-expandable-menu-item:before {
              display: block;
            }
          }
          &.open {
            .sk-h-catalog-expandable-menu-item:before {
              display: block;
            }
          }
        }

        @media screen and (max-width: $breakpoint-sm) {
          width: 100%;
          .sk-h-catalog-list-categories_mobile {
            display: block;
            &__sub {
              display: none;
              padding-left: 15px;
              &.open {
                display: block;
              }
              .paragraph-sk {
                color: var(--color-white) !important;
                font-family: Roboto, Ubuntu, Helvetica, sans-serif;
                font-size: 16px;
                line-height: 26px;
                color: var(--base-text);
                font-weight: normal;
                font-style: normal;
              }
            }
          }
          .sk-h-catalog-list-categories_web {
            display: none;
          }
          .sk-h-catalog-expandable-menu__link {
            width: 80%;
          }
        }
        .sk-h-catalog-expandable-menu-item {
          background-color: transparent !important;
          .paragraph-sk {
            color: var(--color-white) !important;
            @extend %h3;
          }
        }
      }
      .subitems-list {
        height: auto;
        overflow-y: scroll;
        border-left: 1px solid rgba(232, 231, 255, 0.5);
        &__subitem {
          height: 100%;
          display: none;
          &.show {
            display: block;
          }
          .subcategory {
            height: 100%;
          }
        }
        &::-webkit-scrollbar {
          width: 3px;
        }
        &::-webkit-scrollbar-track {
          background: $catalogBackground;
        }
        &::-webkit-scrollbar-thumb {
          background: var(--cyan-gradient);
        }
        @media screen and (max-width: $breakpoint-md) {
          height: 70vh;
        }
        @media screen and (max-width: $breakpoint-sm) {
          display: none;
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .sk-category-overlay__input-search {
    display: block;
    width: 95%;
    margin: 0 auto 14px;
  }
}
