@import "../../../utils/styles/variables.scss";
@import "../../../utils/styles/functions.scss";

.sk-landing-page-hero {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: calc(4.5% + 77px) 0 0%;
  position: relative;
  height: 65vh;
  top: -77px;
  #continue-header-background {
    width: 100vw;
    height: auto;
    position: absolute;
    z-index: -1;
    top: -30%;
  }
  &_grid {
    width: 100%;
    position: relative;

    &__col {
      width: auto;

      &--image {
        position: relative;
        bottom: 140px;
        right: 252px;

        @media screen and (max-width: 1690px) {
          bottom: 41px;
          right: 17%;
        }

        @media screen and (max-width: 1480px) {
          bottom: -16px;
          right: 15%;
        }

        @media screen and (max-width: 1366px) {
          bottom: -60px;
        }

        @media screen and (max-width: 1260px) {
          bottom: -19px;
        }

        @media screen and (max-width: 1245px) {
          bottom: 20px;
        }

        @media screen and (max-width: 1190px) {
          bottom: 0;
        }

        @media screen and (max-width: 564px) {
          bottom: 20px;
        }

        @media screen and (max-width: $breakpoint-xs) {
          right: 0;
          bottom: -20px;
        }
      }

      @media screen and (max-width: 1190px) {
        width: 100%;
      }
    }
  }

  @media screen and (max-width: 1690px) {
    #continue-header-background {
      top: -15%;
    }
  }

  @media screen and (max-width: 1480px) {
    #continue-header-background {
      top: 0;
    }
  }

  @media screen and (max-width: $breakpoint-lg) {
    padding: 100px 0 0%;
  }

  @media screen and (max-width: 1190px) {
    padding: 100px 0 0;
    height: 600px;
    #continue-header-background {
      height: 600px;
      top: -90px;
    }
    &_grid {
      flex-direction: column;
      grid-template-columns: 1fr !important;
      .sk-landing-page-cta-image {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }

  @media screen and (max-width: 1024px) {
    padding: 100px 0 0;
    height: 520px;
    #continue-header-background {
      height: 520px;
      top: 0;
    }
    .sk-landing-page-cta-image {
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: 87px 0 120px;
    height: auto;
    background-image: linear-gradient(
      -0deg,
      var(--purple-gradient-from) -14.75%,
      var(--landing-header) 97.53%
    );
    #continue-header-background {
      display: none;
    }
    &_grid {
      justify-content: center;
      align-items: center;
      text-align: center;
      .sk-landing-page-cta-image {
        position: absolute;
      }
    }
  }
}
