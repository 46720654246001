@import 'utils/styles/text.scss';

// .sk-admin-plan-page {
//   &__header {
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 50px;
//     &__sidebar-button {
//       width: 5%;
//     }
//     &__content {
//       display: flex;
//       flex-direction: column;
//       width: 93%;
//       margin-top: 27px;
//       padding-left: 23px;
//       &__buttons {
//         display: flex;
//         align-items: baseline;
//         justify-content: space-between;
//
//         @extend %p1;
//       }
//     }
//   }
//   &__content {
//
//   }
// }

.sk-admin-plan-page-section {
  padding: 35px 0 20px;
  @extend %p1;
  font-weight: 500;
  color: var(--dark-text);
}
