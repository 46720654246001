.sk-course-status-info {
  &__title {
    display: inline-flex;
    margin-bottom: 3px;
    color: var( --color-grey1);
  }
  &__desc {
    line-height: 1.5;
  }
  &__row {
    &:not(:last-of-type) {
      margin-bottom: 16px;
    }
  }
}