@import 'utils/styles/text.scss';
@import 'utils/styles/_variables.scss';

.sk-experts-cabinet-fee-subscription-select {
  width: 440px;
  border-radius: 6px;

  > .paragraph-sk {
    height: 62px;
    display: flex;
    justify-content: center;
    align-items: center;
    @extend %h3;
    color: var(--white-text);
    background: linear-gradient(330.33deg, #8F26AF -8.8%, #141685 101.98%);
    border-radius: 6px 6px 0 0;
  }
  > .sk-card {
    text-align: center;
    padding: 41px 26px 35px;
  }

  @media screen and (max-width: $breakpoint-lg) {
    width: 660px;
    margin: 20px 0;
  }
  @media screen and (max-width: $breakpoint-sm) {
    width: 100%;
  }
}
