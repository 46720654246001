@import 'utils/styles/text.scss';

.sk-admin-user-view {
  display: flex;
  flex-direction: column;
  .summary {
    padding-bottom: 20px;
  }
  &__content-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &__left {
      display: flex;
      >div:first-child {
        width: 55px;
      }
      &__panel-name {
        align-self: center;
      }
    }
    &__right {
      &__action-buttons {
        display: flex;
        &__button:last-child {
          padding-left: 10px;
        }
      }
    }
  }
  &__content-body {
    display: flex;
    flex-direction: column;
    &__user-info {
      display: flex;
      padding-left: 63px;
      padding-bottom: 20px;
      >div:first-child {
        width: 69px;
        height: 69px;
      }
      &__text-group {
        display: flex;
        flex-direction: column;
        padding-left: 14px;
        justify-content: center;
        &__text {
          @extend %h3;
        }
      }
    }
    &__card {
      padding-bottom: 64px;
    }
  }
}
