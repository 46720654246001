@import 'utils/styles/text.scss';

.sk-courseDetails-author {
  border-bottom: 1px solid var(--purple-light);
  padding-bottom: 15px;
  margin-bottom: 15px;

  &__useravatar {
    width: 71px;
    height: 71px;
  }

  
  &__avatar {
    display: flex;
    margin-bottom: 22px;
    align-items: center;
    justify-content: space-between;
  }

  &__avatar_name {
    display: flex;
    @media screen and (max-width: 480px){
      display: flex;
      flex-direction: column;
    }
  }

  &__name {
    margin-left: 20px;
    display: flex;
    flex-direction: column;
    flex-grow: 4;

    &-title {
      margin: 0;
      color: var(--dark-text);
    }
    span {
      margin-top: 5px;
      color: var(--color-grey3);
      font-size: 10px;
    }
  }
  &-desc {
    &__text {
      @extend %p1;
    }
  }


  &-button {
    margin-top: 20px;
    color: var(--color-primary-1);
    font-weight: bolder;
    font-size: var(--text-fontSize-body);
  }


}
