@import 'utils/styles/text.scss';

.sk-purchased-course-messenger {
  > .sk-card {
    padding: 24px 39px;
    display: flex;
    align-items: center;
    > svg {
      margin-right: 24px;
    }
    > .paragraph-sk {
      @extend %p1;
    }
    > a {
      text-decoration: none;
      margin-left: auto;
    }
  }
  @media screen and (max-width: 400px){
    > .sk-card {
      display: flex;
      align-items: center;
      flex-direction: column;
      > a {
        margin: auto;
      }
      > svg {
        margin: auto;
      }
      > p {
        margin: 12px auto 28px;
      }
    }
  }
}
