@charset "UTF-8";
/* headers */
.mui-override.sk-сurriculum-title {
  font-family: RobotoSlab, Ubuntu, Helvetica, sans-serif;
  font-size: 40px;
  line-height: 50px;
  font-weight: bold;
  font-style: normal;
  color: var(--dark-text); }

/* paragraphs */
.mui-override.sk-сurriculum {
  padding: 44px 0 50px;
  border-bottom: 1px solid var(--purple-light); }
  .mui-override.sk-сurriculum__lecture {
    padding: 0;
    box-shadow: 0 27px 60px 0 var(--box-shadow-color-1);
    border-bottom-left-radius: 8.8px;
    border-bottom-right-radius: 8.8px;
    display: flex;
    flex-direction: column;
    align-items: normal; }
    .mui-override.sk-сurriculum__lecture-icon {
      width: 30px;
      height: 30px;
      background: '#FFFFFF';
      border-radius: 50%;
      line-height: 22px;
      box-shadow: 0px 4px 20px rgba(97, 15, 182, 0.2); }
      .mui-override.sk-сurriculum__lecture-icon .active-icon {
        background: var(--purple-dark); }
    .mui-override.sk-сurriculum__lecture > div {
      margin: 0;
      box-shadow: none; }
      .mui-override.sk-сurriculum__lecture > div::before {
        opacity: 1 !important; }
    .mui-override.sk-сurriculum__lecture__articles {
      width: 100%;
      background-image: linear-gradient(to top, var(--button-gradient1), var(--button-gradient2) 86%, var(--button-gradient3)); }
    .mui-override.sk-сurriculum__lecture:last-child .sk-cur-details:last-child {
      border-bottom: 1px solid var(--purple-light); }
  .mui-override.sk-сurriculum-title {
    margin-top: 0;
    margin-bottom: 53px;
    color: var(--dark-text); }

.mui-override.second-overwrite.sk-сurriculum-lecture-description {
  padding: 20px 26px 20px; }

.mui-override.sk-lc-сurriculum-panel__details {
  padding: 0; }
