@import "utils/styles/text.scss";
@import "utils/styles/_variables.scss";

.sk-topic {
  position: relative;
  margin-top: -60px;

  &-podcasts {
    margin-top: -20px;

    @media screen and (max-width: $breakpoint-md) {
      margin-top: -15px;
    }
  }

  @media screen and (max-width: 1480px) {
    margin-top: 0px;
  }

  h2 {
    @extend %h2;
    text-align: center;
    @media screen and (max-width: $breakpoint-xs) {
      width: 100%;
    }
  }
  @media screen and (max-width: $breakpoint-sm) {
    margin-bottom: -26px;
  }
}
