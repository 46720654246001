@import "utils/styles/_variables.scss";
@import "utils/styles/text.scss";

.sk-landing-page__comments_card {
  position: relative;
  background: #ffffff;
  box-shadow: 0px 15px 30px rgba(103, 151, 255, 0.2);
  border-radius: 10px;
  padding: 40px 38px 30px 98px !important;
  width: 85%;
  margin: 0 auto 48px !important;

  & img {
    width: 277px !important;
    height: 277px !important;
    top: 4px !important;
    position: relative !important;
  }

  &.secondStory {
    & img {
      right: 19px;
    }
  }

  &.thirdStory {
    & img {
      right: 32px;
    }
  }

  &__photo {
    position: absolute;
    z-index: 2;
    top: 27px;
    left: -25px;
    width: 97px;
    height: 97px;
    > .sk-avatar-user_wrapper {
      &:before {
        content: "";
        position: absolute;
        z-index: -1;
        left: -13px;
        top: -6px;
        border-radius: 50%;
        width: 94%;
        height: 94%;
        background: linear-gradient(90deg, #30d5c8 0%, #1ebbae 100%);
        box-shadow: 0px 30px 30px rgba(103, 151, 255, 0.2);
      }
      width: 100%;
      height: 100%;
      border: solid 5px white;
      border-radius: 50%;
      box-shadow: 4px 9px 46px rgba(103, 151, 255, 0.2);
      > .mui-override.sk-avatar-user {
        border: none;
        background-color: white;
      }
    }
  }
  &__content {
    p {
      text-align: left;
    }
    > p {
      @extend %p1;
      color: var(--text-color-landing);
      margin-bottom: 17px !important;
    }
    &__user-block {
      position: relative;
      svg {
        position: absolute;
        left: -40px;
      }
      &__user {
        margin-bottom: 20px;
        > p:first-child {
          margin: 0px !important;
          @extend %p1;
          font-weight: bold !important;
          color: var(--text-color-landing) !important;
        }
        > p:nth-child(2),
        > p:nth-child(3) {
          margin: 0px !important;
          @extend %p2;
          font-weight: 300 !important;
          color: var(--text-color-landing) !important;
        }
      }
    }
    &__social-links {
      display: flex;
      align-items: baseline;
      > a {
        display: block;
        padding: 0 16px !important;
      }
    }
  }

  @media screen and (max-width: $breakpoint-xs) {
    padding: 75px 16px 29px !important;
    &__photo {
      top: -45px;
      left: calc(50% - 48.5px);
    }
    &__content {
      p {
        text-align: center;
      }
      &__user-block {
        display: flex;
        flex-direction: column;
        align-items: center;
        svg {
          position: relative;
          left: 0;
        }
      }
      &__social-links {
        justify-content: center;
        > a {
          margin: 0 !important;
        }
      }
    }
  }
}
