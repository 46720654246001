.mui-override {
  &.sk-materialized-input {
    margin-top:20px;
    &-underline_disable {
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }

    &_borderLess {
      &:before {
        border-color: transparent !important;
      }
    }
    &_lightText {
      font-weight: 300;
    }
    & textarea {
      overflow: hidden;
    }
    &_focused {
      &:after {
        border-color: var(--color-primary-1);
      }
    }
    &_error {
      &.labelWithError {
        color: var(--color-error);
      }
      &.withError {
        &:after {
          border-color: var(--color-error);
        }
        & input::placeholder {
          color: var(--color-error);
          font-size: var(--text-color-basic);
          font-weight: 600;
        }
      }
    }
    &_hover {
      &:hover {
        &:before {
          border-color: var(--color-primary-1) !important;
        }
      }
    }

    &_label {
      &.sk-materialized-input_focused {
        color: var(--color-primary-1);
        &.sk-materialized-input_error {
          color: var(--color-error);
        }
      }
    }

    &_helperErrorText {
      position: absolute;
      top: 100%;
      left: 0;
      color: var(--color-error);
      font-size: 0.65rem;
    }
    &_caption {
      font-size: var(--text-fontSize-caption);
      color: var(--color-dim-grey);
      padding-bottom: 4px;
      & + .sk-input-adornment {
        margin-left: 0px;
      }
    }
    &_isCleaned.disabled {
      opacity: 0;
    }
    &_left {
      text-align: left;
    }

    &_center {
      text-align: center;
    }
    &_picker {
      min-height: 40px;
    }
    &-control {
      &_withoutValidation {
        margin-top: 8px;
        margin-bottom: 2px;
      }
    }
  }

  .sk-input-adornment {
    padding-bottom: 2px;
    white-space: nowrap;
    &_caption p {
      font-size: var(--text-fontSize-caption);
    }
  }
}
