@import "../../../utils/styles/variables";
@import 'utils/styles/text.scss';

.sk-video-module-controls {
  position: absolute;
  bottom: 0;
  width: 100%;
  opacity: 1;
  box-sizing: border-box;
  &.video{
    border-radius: inherit;
    background: linear-gradient(180deg, rgba(51, 51, 51, 0) 0%, #333333 79.47%);
    transition: transform .6s;
    padding: 25px 20px 9px;
    .playbackSpeed-speeds{
      bottom: 32px;
    }
    .volume-setting{
      height: 30px;
      margin-right: 30px;
      .volume-bar{
        bottom: 32px;
      }
    }
    .loadedProgressBar{
      background: #D0D0D0;
    }
  }
  &.audio{
    background: linear-gradient(102.77deg, rgba(113, 69, 216, 0.6) 15.42%, rgba(106, 69, 192, 0.6) 72.53%);
    box-shadow: 0px 10px 10px rgba(91, 80, 126, 0.1);
    border-radius: 5px;
    padding: 16px 25px 35px;
    .volume-settings{
      margin-right: 0;
      height: 100px;
      display: flex;
      justify-content: center;
      flex-direction: column;
      position: absolute;
      right: 25px;
      .volume-bar{
        top: 85px;
      }
    }
    .playbackSpeed-speeds{
      top: 47px;
    }
    .loadedProgressBar{
      background: rgba(255, 255, 255, 0.4);
    }
  }
  .playControllButton{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .timestamp{
    margin: 0 30px;
    span{
      @extend %p3;
      color: var(--color-white);
    }
  }
  .playbackSpeed-container{
    position: relative;
    .label{
      cursor: pointer;
      span{
        @extend %p3;
        color: var(--color-white);
      }
      svg{
        margin-left: 7px;
      }
      &.open{
        span{
          font-weight: 800;
        }
      }
      &.close{
        span{
          font-weight: 600;
        }
      }
    }
    .playbackSpeed-speeds{
      display: none;
      position: absolute;
      width: 77px;
      background: var(--color-overlay);
      border-radius: 5px;
      box-sizing: border-box;
      padding: 9px 0 0 15px;
      &.open{
        display: block;
      }
      span{
        @extend %p3;
        color: var(--color-white);
        font-weight: bold;
        display: inline-block;
      }
      ul{
        li{
          display: block;
          margin-bottom: 9px;
          cursor: pointer;
          span{
            @extend %p3;
            color: var(--color-white);
            font-weight: 600;
          }
          &.selected span{
            font-weight: 800;
          }
        }
      }
    }
  }
  .volume-settings{
    position: relative;
    margin-right: 20px;
    &:hover{
      .volume-bar{
        opacity: 1;
      }
    }
    .icon{
      display: flex;
      justify-content: center;
      align-items: center;
      height: 30px;
      width: 20px;
    }
    .volume-bar{
      position: absolute;
      width: 45px;
      height: 92px;
      justify-content: center;
      align-items: center;
      background: var(--color-overlay);
      border-radius: 5px;
      display: flex;
      left: -10px;
      opacity: 0;
      bottom: 30px;
      input[type="range"]{
        transform: rotate(-90deg);
        width: 72px;
        cursor: pointer;
      }
      &.muted{
        opacity: 0
      }
    }
  }
  .fullscreen{
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .controls-row{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    .row{
      display: flex;
      align-items: center;
    }
  }
  .progressBar{
    width: 100%;
    height: 5px;
    border-radius: 10px;
    margin-top: 14px;
    background: rgba(249, 249, 249, 0.6);
    outline: none;
    cursor: pointer;
    .loadedProgressBar{
      height: 100%;
      border-radius: 10px;
    }
    .timeBar{
      height: 100%;
      position: relative;
      top: 0;
      left: 0;
      border-radius: 10px;
      background: var(--color-white);
      &:after{
        position: absolute;
        content: '';
        display: block;
        width: 18px;
        height: 18px;
        right: -9px;
        top: -7px;
        border-radius: 50%;
      background: var(--color-white);
      }
    }
  }
}

input[type=range] {
  -webkit-appearance: none;
  width: 100%;
  &.volume::-webkit-slider-runnable-track{
    width: 100%;
    height: 5px;
    background: rgba(249, 249, 249, 0.6);
    border-radius: 10px;
  }
  &.volume::-webkit-slider-thumb{
    margin-top: -8.5px;
  }
}
// input[type=range]:focus {
//   outline: none;
// }
// input[type=range]::-webkit-slider-runnable-track {
//   width: 100%;
//   height: 5px;
//   cursor: pointer;
//   background: rgba(249, 249, 249, 0.6);
//   border-radius: 10px;
// }
input[type=range]::-webkit-slider-thumb {
  -webkit-appearance: none;
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(90, 40, 206, 0.28);
  border-radius: 50%;
  cursor: pointer;
}
input[type=range]::-moz-range-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: rgba(249, 249, 249, 0.6);
  border-radius: 10px;
}
input[type=range]::-moz-range-thumb {
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(90, 40, 206, 0.28);
  border-radius: 50%;
  cursor: pointer;
}
input[type=range]::-ms-track {
  width: 100%;
  height: 5px;
  cursor: pointer;
  background: rgba(249, 249, 249, 0.6);
  border-radius: 10px;
}
input[type=range]::-ms-thumb {
  height: 20px;
  width: 20px;
  background: #FFFFFF;
  box-shadow: 0px 5px 10px rgba(90, 40, 206, 0.28);
  border-radius: 50%;
  cursor: pointer;
}

@media screen and (max-width: $breakpoint-sm){
  .sk-video-module-controls .playbackSpeed-container .playbackSpeed-speeds ul li{
    margin-bottom: 0;
    span{
      line-height: 1;
    }
  }
}
