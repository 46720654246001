@import '../../../utils/styles/variables';

.sk-ma-delete {
  display: flex;
  flex-direction: column;
  margin-bottom: 120px;
  flex: 1;
  background-size: 90% 90%;
  &__card {
    padding: 70px 75px;
    max-width: 518px;
    margin: 0 auto;
  }
  &__inner {
    margin-bottom: 41px;
  }
  &__footer {
    display: flex;
    justify-content: center;
  }
  &__text {
    line-height: 1.44;
  }
  &__modal {
    $this:&;
    &.mui-override {
      padding: 43px 30px;
      @media screen and (max-width: $breakpoint-sm) {
        max-width: 450px;
        padding: 68px 49px 48px;
       }
    }
    #{this}_shifted.mui-override {
      transform: translateX(130px);
    }
  }
}