.dashboard {
    display: flex;
    // justify-content: center;
    // flex: 1;
    // box-sizing: border-box;
    // align-items: center;
    &__wrapper {
        box-sizing: border-box;
        display: flex;
    }
    &__break {
        flex-basis: 100%;
        height: 0;
    }
    &__card {
      box-sizing: border-box;
      align-self: center;
    }
    &__bar {
      box-sizing: border-box;
      align-self: center;
    }
  
    &__content {
    //   margin-bottom: 30px;
    }
    &__header {
    //   margin-bottom: 18px;
    }
    &__heading {
      text-align: center;
    }
    &__footer {
      display: flex;
      justify-content: center;
    }
  
    @media screen and (max-width: 414px) {
      &__card {
        padding: 15px;
      }
    }
  }
  