.sk-course-prices-conversions {
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 35px;
  &:first-child {
    margin: 0;
  }

  > img {
    width: 20px !important;
    height: 20px;
    margin: 1px;
  }
  > .paragraph-sk {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-left: 30px;
    text-align: start;
    align-items: center;
  }
}
