@import 'utils/styles/_variables.scss';
.sk-calendar__container .rbc-calendar .rbc-month-view .rbc-row .rbc-row-segment .rbc-event {
    // background: linear-gradient(45deg, #8F26AF 16.8%, #141685 161.98%) !important;
    color: #fff;
    box-shadow: none;
    // padding: 10px;
    font-size: 12px;
    outline: none;
    outline-color: transparent;
    outline-style: none;
}

.sk-event-item {
    background: linear-gradient(45deg, #8F26AF 16.8%, #141685 161.98%);
    color: #fff;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    outline: none;
    outline-color: transparent;
    outline-style: none;
    justify-content: center;
}
