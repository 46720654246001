.sk-search {
  display: grid;
  grid-template-columns: 1fr;
  grid-row-gap: 20px;
  max-width: 240px;

  &__input-block {
    position: relative;

    &__input {
      .sk-materialized-input {
        &_left {
          padding-right: 30px;
        }
      }
    }
    &__icon {
      position: absolute;
      right: 4px;
      bottom: 12px;
      cursor: pointer;
    }
  }

  &__button {
    justify-self: end;
    margin-top: 10px;
  }
}
