@import 'utils/styles/_variables.scss';

.sk-expert-filter-status {
  display: flex;
  z-index: $z-index-drop-down;
  .sk-dropdown-toggler {
    display: flex;

    .sk-filters-icon{
      margin-right: 15px;
    }
  }
}
