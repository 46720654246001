@import 'utils/styles/text.scss';

.mui-override.sk-expert-analytics-earnings-table-row {
  &__cell {
    padding: 0 20px !important;
    &:first-child {
      padding-left: 5% !important;
      border-right: 1px solid rgba(224, 224, 224, 1);
    }
    &:last-child {
      padding-right: 5% !important;
    }
  }
  &:nth-child(2n) {
    background: #F5F6FC;
  }
  &:last-child &__cell {
    border-bottom: none !important;
    & > p {
      font-weight: 700;
    }
  }
  &:last-child {
    background: none;
  }
}

.mui-override.sk-expert-analytics-earnings-table-row__title {
  @extend %p1;
}
