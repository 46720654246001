@import "../../../utils/styles/variables.scss";
@import "../../../utils/styles/text.scss";

.sk-landing-page__courses {
  margin-top: -32px;

  &.all {
    @media screen and (max-width: $breakpoint-sm) {
      margin-top: -60px;
    }
    @media screen and (max-width: $breakpoint-xs) {
      margin-top: -92px;
    }
  }
  position: relative;
  width: 100%;
  &_all_button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 5px;
    margin-bottom: 80px;
    @media screen and (max-width: $breakpoint-sm) {
      margin-top: -32px;
      margin-bottom: 12px;
    }
    .button-sk {
      height: 56px;
      width: 284px;
      font-size: 16px;
    }
  }
  &_list {
    padding: 15px 9vw;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;

    @media screen and (max-width: 1366px) {
      padding: 15px 6vw;
      .sk-course-card:nth-child(n + 16) {
        display: none;
      }
    }
    @media screen and (max-width: 1023px) {
      padding: 15px 4.1vw;
      justify-content: space-around;

      .sk-course-card:nth-child(n + 16) {
        display: block;
      }
    }
    @media screen and (max-width: 767px) {
      padding: 15px 30px;
      justify-content: space-around;
    }
    @media screen and (max-width: $breakpoint-sm) {
      flex-direction: column;
    }
    .sk-course-card {
      flex-basis: calc(25% - 20px);

      &--invisibleAdjunct {
        visibility: collapse;
      }

      @media screen and (max-width: 1366px) {
        flex-basis: calc(33% - 20px);
      }

      @media screen and (max-width: 1023px) {
        flex-basis: calc(50% - 30px);
      }
      @media screen and (max-width: $breakpoint-sm) {
        width: 100%;
        flex-basis: auto;
      }
    }
  }

  .float-element {
    position: absolute;
    top: -14px;
    right: 0;
    @media screen and (max-width: $breakpoint-md) {
      display: none;
    }
  }
  .sk-lp-category-item__heading {
    @extend %h2;
    margin-bottom: 32px;
    @media screen and (max-width: $breakpoint-md) {
      margin-bottom: 22px;
    }
    @media screen and (max-width: $breakpoint-xs) {
      display: block !important;
      text-align: center;
      width: 100%;
    }
  }
}
