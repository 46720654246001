@import '../../../utils/styles/variables';
.sk-filter-group {
  display: flex;
  align-self: center;
  font-size: 16px;
  flex-direction: column;
  &_item {
    position: relative;
    div {
      width: auto !important;
      div {
        &.sk-filter-input_major {
          height: 35px;
        }

        @media screen and (max-width: 1023px) {
          &.sk-filter-input_major {
            height: 16px;
          }
        }
        div {
          p {
            font-size: var(--text-fontSize-h2);
            @media (max-width: $breakpoint-xs) {
              font-size: var(--text-fontSize-caption);
            }
            @media (max-width: 1023px) {
              font-size: var(--text-fontSize-caption);
            }
            @media (max-width: 1023px) {
              font-size: var(--text-fontSize-caption);
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1023px) {
  .sk-filter-group {
    max-width: calc(100vw - 100px);
    &_item {
      div {
        float: right;
      }
    }
  }
  &.sk-filter-input_major {
    padding-top: 16px;
  }
}
