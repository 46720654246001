@import '../../../utils/styles/variables.css';
@import '../../../utils/styles/_variables';
@import 'utils/styles/text.scss';
@import 'utils/styles/color.scss';

.sk-auth-social {
  width: 100%;
  &__breaker {
    margin-top: 27px;
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    & > div:first-child {
      width: 100%;
      height: 2px;
      background: #EBEBEB;
      position: absolute;
      bottom: 7px;
    }
    & > div:last-child {
      text-align: center;
      position: relative;
      top: 2px;
      width: 160px;
      background: #fff;
      z-index: 1;

      @extend %p1;
    }
  }
  &__buttons {
    margin-top: 55px;
    width: 100%;
    display: flex;
    justify-content: center;
    @media screen and (max-width: $breakpoint-xs) {
      flex-direction: column;
      align-items: center;
    }

    & > button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 176px;
      border-radius: 25px;
      height: 43px;
      & > span, p {
        margin-left: 10px;
        @extend %p1;
        color: #FFFFFF;
      }
      &:first-child {
        background: #4267B2;
        margin-right: 30px;
        @media screen and (max-width: $breakpoint-xs) {
          margin-right: 0;
          margin-bottom: 10px;
        }
      }
      &:last-child {
        background: #D95350;
      }
    }
    & > button:hover {
      box-shadow: var(--button-md-secondary-box-shadow);
      &:first-child{
        box-shadow: 0px 10px 20px rgba(66, 103, 178, 0.3);
      }
    }
  }
}
