@import 'utils/styles/text.scss';

.sk-create-topping-modal {
  display: flex;
  flex-direction: column;
  align-items: center;

  > .paragraph-sk {
    @extend %h3;
    font-weight: bold;
    margin: 20px 0 50px;
  }
  > .button-sk {
    margin: 40px 0 10px;
  }
  > fieldset {
    align-self: flex-start;
  }
}
