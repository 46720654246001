.sk-no-courses {
  display: grid;
  grid-template-columns: 1fr;
  justify-items: center;
  margin-top: 58px;
  max-width: 100vw;
  overflow: hidden;

  img {
    margin-top: 36px;
  }
  &__heading {
    text-align: center;
    line-height: 1;
  }
}
