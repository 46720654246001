.sk-web-info {
  display: flex;
  &__webinar {
    display: flex;
    flex-direction: column;
    flex: 0 0 calc(100% - 270px);
  }
  &__header {
    display: flex;
    align-items: center;
    margin-bottom: 17px;
    padding-top: 15px;
  }
  &__course {
    flex: 0 0 270px;
    padding-left: 14px;
    display: flex;
    align-items: flex-start;
  }
  &__author {
    display: flex;
    align-items: center;
  }
  &__avatar {
    width: 50px;
    height: 50px;
    margin-right: 10px;
  }
}


.sk-web-desc-block {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
    &__text {
      line-height: 1.6;
    }


  &__button {
    margin-top: 8px;
    color: var(--color-primary-1);
    font-weight: bolder;
    font-size: var(--text-fontSize-body);
  }

}