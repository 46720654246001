@import 'utils/styles/text.scss';

.sk-expert-subscription-expired {
  padding: 45px 54px 57px;
  width: 100%;
  max-width: 881px;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    > .paragraph-sk {
      @extend %h3;
    }
  }
  > .paragraph-sk {
    &_small {
      text-align: center;
      @extend %p1;
    }
  }
  > .sk-link {
    margin-top: 40px;
  }
}
